import React, { useEffect, useMemo } from 'react'

import {
  BackdropLoader,
  Box,
  Card,
  makeAppStyles,
  Typography,
  ReportRange,
  MenuOption,
  FilterPanel,
  Grid,
  ProviderLogo,
  formatMoney,
  FormLabel,
  DateRangePresetInput,
  DateRangePresetOption,
  CheckboxGroup,
  RadioFormGroup,
  resolveDateRangePresetLabel,
  DateRangeValue,
  Loader,
  dateRangeCalculators,
} from '@percept/mui'

import { PerformanceReportDataTable } from './PerformanceReportTable'

import { currencyOptions } from 'components/CurrencySelect'

import { useActiveOrgEnvironment } from 'components/Organisation'

import { stringifyUrlSearchParams, useDocumentTitle, usePlatformUnitProviderInfo, useUrlState } from '@percept/hooks'

import {
  buildPerformanceReportCSV,
  getPerformanceReportFilename, 
  usePerformanceReport,
} from './lib'

import { every, get, omit } from 'lodash-es'

import { coerceReportProvider, deslugify, isoDate, isVodafonePlatformUnit, produceKeyedMapping } from '@percept/utils'

import { differenceInCalendarDays } from 'date-fns'

import { getPerformanceReportDates, getPerformanceReportProviders, minDatesByProvider } from './dataUtils'

import { providerLabelMap } from '@percept/constants'

import { DerivedAggregationPeriod, PerformanceReportProvider, PerformanceReportSegmentation, PerformanceReportState } from './typings'

import { PlatformUnit, PlatformUnitProviderInfo } from '@percept/types'
import { useSavedQueryStrings } from 'hooks'


const useStyles = makeAppStyles( theme => ({
  filterCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(-2),
  },
  formGroup: {
    backgroundColor: theme.palette.background.paper,
  },
  formControlLabelTopAligned: {
    alignItems: 'start',
  },
  formControlLabelPrimaryText: {
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  formControlLabelSecondaryText: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
    fontSize: 12,
    lineHeight: 1.5,
    maxWidth: '21rem',
    whiteSpace: 'normal',
    marginBottom: theme.spacing(1),
  },
  providerTrigger: {
    marginRight: theme.spacing(1),
    color: 'white',
  },
  card: {
    marginTop: theme.spacing(3),
  },
  inputGroup: {
    margin: theme.spacing(3),
  },
  providerInputGroup: {
    minWidth: '21rem',
  },
  badgeCount: {
    top: 8,
    right: -14,
  },
  inputGroupDivider: {
    marginRight: 0,
    margin: theme.spacing(3),
    marginLeft: 0,
  },
  inputGroupHeader: {
    marginBottom: theme.spacing(1.5),
  },
  helperText: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
  },
  listItem: {
    paddingRight: theme.spacing(2),
  },
  checkboxControl: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
  checkbox: {
    padding: theme.spacing(1),
  },
  dateRangeInput: {
    maxWidth: '4.5rem',
  },
  platformUnitList: {
    maxHeight: '11rem',
    overflowY: 'auto',
  },
  providerLogo: {
    position: 'relative',
    top: 1,
  },
  tableTypography: {
    display: 'flex',
    alignItems: 'center',
  },
  dateRange: {
    marginLeft: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
}))


const getAggregationOptions = ({
  platformUnits,
  startDate,
  endDate,
}: {
  platformUnits: PlatformUnit[]
  startDate: Date
  endDate: Date
}): MenuOption<DerivedAggregationPeriod | null>[] => {
  const hasChunking = platformUnits.length === 1
  const hasWeekly = hasChunking && (
    Math.abs(differenceInCalendarDays(startDate, endDate)) < 6
  )
  const hasMonthly = hasChunking && (
    Math.abs(differenceInCalendarDays(startDate, endDate)) < 30
  )
  return [
    { value: null, label: 'Full Range' },
    {
      value: 'DAILY',
      label: 'Daily',
      disabled: !hasChunking,
    },
    {
      value: '7_DAYS',
      label: '7 Days',
      disabled: !hasWeekly,
    },
    {
      value: 'WEEKLY',
      label: 'Weekly',
      disabled: !hasWeekly,
    },
    {
      value: '30_DAYS',
      label: '30 Days',
      disabled: !hasMonthly,
    },
    {
      value: 'MONTHLY',
      label: 'Monthly',
      disabled: !hasMonthly,
    },
  ]
}

type FilterPanelState = Omit<PerformanceReportState, 'start' | 'end'> & {
  dateRange: {
    preset: DateRangePresetOption
    value: [Date | null, Date | null]
  }
}

const stateToFilterState = (
  state: PerformanceReportState
): FilterPanelState => {
  return ({
    ...state,
    dateRange: {
      preset: state.dateRangePreset || 'custom',
      value: [
        state.start ? new Date(state.start) : null,
        state.end ? new Date(state.end) : null,
      ],
    }
  })
}


const campaignObjectiveSegmentationProviders: PerformanceReportProvider[] = [
  'FACEBOOK',
  'TIKTOK',
]


const canSegmentByCampaignObjective = (providers: PerformanceReportProvider[]): boolean => (
  every(providers, p => campaignObjectiveSegmentationProviders.includes(p))
)

let campaignObjectiveLabelSuffix = campaignObjectiveSegmentationProviders.reduce( (acc, provider, i) => {
  const joinString = (
    i === 0 ?
      '' :
      i === campaignObjectiveSegmentationProviders.length - 1 ?
        ' & ' :
        ','
  )
  return acc + joinString + providerLabelMap[provider]
}, '')
campaignObjectiveLabelSuffix = `(${campaignObjectiveLabelSuffix} only)`


const PerformanceReportView = ({
  platformUnit,
  providerInfo,
}: {
  platformUnit: PlatformUnit
  providerInfo: PlatformUnitProviderInfo
}): JSX.Element => {

  const [state, setState] = useUrlState<PerformanceReportState>({
    organisations: [platformUnit.id],
    providers: [],
    segmentation: undefined,
    aggregationPeriod: undefined,
    start: undefined,
    end: undefined,
    dateRangePreset: 'custom',
    currency: 'EUR',
  })

  const [, setSavedQueryStrings] = useSavedQueryStrings()

  useEffect(() => {
    setSavedQueryStrings({
      customPerformanceReport: stringifyUrlSearchParams(omit(state, 'organisations')),
    })
  }, [state, setSavedQueryStrings])

  const {
    providers,
    organisations,
    segmentation,
    aggregationPeriod,
    start,
    end,
    currency,
  } = state

  useEffect(() => {
    if( !start || !end ){
      const initialProviders = (
        getPerformanceReportProviders(platformUnit)
      ).filter( p => p !== 'AMAZON_ADS' )
      const initialDates = getPerformanceReportDates({
        providerInfo,
        providers: initialProviders,
      })
      setState({
        providers: initialProviders,
        start: isoDate(initialDates.startDate),
        end: isoDate(initialDates.endDate),
      })
    }
  }, [platformUnit, providerInfo, start, end, setState])

  const derivedState = useMemo(() => {
    const reportDates = getPerformanceReportDates({
      providerInfo,
      providers: state.providers,
      dateRange: (
        state.start && state.end ?
          [new Date(state.start), new Date(state.end)] :
          undefined
      ),
    })
    const availablePlatformUnits = [
      platformUnit,
      ...(platformUnit.children || []),
    ]
    const platformUnitsById = produceKeyedMapping(availablePlatformUnits, 'id')
    return {
      ...reportDates,
      platformUnitsById,
      availablePlatformUnits,
      availableProviders: getPerformanceReportProviders(platformUnit),
    }
  }, [platformUnit, state.providers, state.start, state.end, providerInfo])

  const {
    startDate,
    endDate,
    minDate,
    maxDate,
    platformUnitsById,
    availablePlatformUnits,
    availableProviders,
  } = derivedState

  const selectedPlatformUnits = organisations.map( id => platformUnitsById[id] )

  const [performanceReport, loadPerformanceReport] = usePerformanceReport({
    selectedPlatformUnits,
    providers: providers.map(coerceReportProvider),
    segmentation: segmentation || null,
    aggregationPeriod: aggregationPeriod || null,
    ...derivedState,
    currency,
  })

  useEffect(() => {
    if( !performanceReport.data && !performanceReport.error && !performanceReport.loading ){
      loadPerformanceReport()
    }
  }, [performanceReport, loadPerformanceReport])

  const classes = useStyles()

  return (
    <Box
      my={4}
      mx={3}>

      <Box mb={5}>

        <Typography variant='h5'>Performance Report</Typography>

        <Card
          className={classes.filterCard}>
          
          <FilterPanel
            values={stateToFilterState(state)}
            onConfirm={({ dateRange, segmentation, ...values }): void => {
              setState({
                ...values,
                segmentation: segmentation || undefined,
                dateRangePreset: dateRange.preset,
                start: dateRange.value[0] ? isoDate(dateRange.value[0]) : undefined,
                end: dateRange.value[1] ? isoDate(dateRange.value[1]) : undefined,
              })
            }}
            validate={(values) => {
              return Boolean(
                values.organisations.length
                && values.providers.length
                && values.dateRange.value[0]
                && values.dateRange.value[1]
              )
            }}
            valueOrder={
              [
                'organisations',
                'dateRange',
                'providers',
                'segmentation',
                'currency',
              ]
            }
            displayConfig={{
              /* eslint-disable react/display-name, react/prop-types */
              organisations: {
                label: 'Organisations',
                render: (value) => get(platformUnitsById, [value, 'name'], null),
              },
              dateRange: {
                label: 'Date Range',
                nullable: false,
                render: ({ preset, value }) => resolveDateRangePresetLabel(preset, value as DateRangeValue),
              },
              providers: {
                label: 'Providers',
                render: (value): JSX.Element => (
                  <ProviderLogo size={1.25} provider={value} style={{position: 'relative', top: 3}} />
                ),
              },
              segmentation: {
                label: 'Segmentation',
                nullable: true,
                render: deslugify,
              },
              currency: {
                label: 'Currency',
                nullable: false,
                render: (value) => (
                  <span>
                    {formatMoney({ amount: null, currency: value })} {value}
                  </span>
                )
              },
            /* eslint-enable react/display-name, react/prop-types */
            }}>
            { ({ values, updateValues }): JSX.Element => {
              const hasCampaignSegmentation = canSegmentByCampaignObjective(values.providers)
              return (
                <Grid container spacing={5}>

                  <Grid item xs='auto'>
                    <CheckboxGroup
                      name='Organisations'
                      value={values.organisations}
                      scrollable
                      maxHeight='36rem'
                      FormGroupProps={{
                        className: classes.formGroup,
                      }}
                      options={
                        availablePlatformUnits.map( org => ({
                          value: org.id,
                          label: org.name,
                        }))
                      }
                      onChange={(organisations): void => {
                        updateValues({ organisations })
                      }} />
                  </Grid>

                  <Grid item xs='auto'>
                    <FormLabel>Date Range</FormLabel>
                    <DateRangePresetInput
                      BoxProps={{
                        mt: 1,
                      }}
                      variant='static'
                      divided
                      color='secondary'
                      dateRangePreset={values.dateRange.preset}
                      value={values.dateRange.value as DateRangeValue}
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={(value, preset): void => {
                        updateValues({
                          dateRange: {
                            preset,
                            value,
                          }
                        })
                      }} />
                  </Grid>

                  <Grid item xs='auto'>
                    <CheckboxGroup
                      name='Providers'
                      value={values.providers}
                      FormControlLabelProps={{
                        className: classes.formControlLabelTopAligned,
                      }}
                      options={
                        availableProviders.map( provider => ({
                          value: provider,
                          label: (
                            <div>
                              <ProviderLogo
                                className={classes.formControlLabelPrimaryText}
                                size={1.35} provider={provider} style={{position: 'relative', top: 3}} />
                              <ReportRange
                                TypographyProps={{className: classes.formControlLabelSecondaryText}}
                                start={minDatesByProvider[provider]}
                                end={providerInfo[coerceReportProvider(provider)].reference_date} />
                            </div>
                          ),
                        }))
                      }
                      onChange={(providers): void => {
                        const update: Partial<FilterPanelState> = { providers }
                        // Update date range to account for variations in provider
                        // reference dates when using presets
                        if( values.dateRange.preset !== 'custom' && providers.length ){
                          const reportDates = getPerformanceReportDates({
                            providerInfo,
                            providers,
                          })
                          update.dateRange = {
                            preset: values.dateRange.preset,
                            value: (
                              dateRangeCalculators[values.dateRange.preset](reportDates.maxDate)
                            )
                          }
                        }
                        // Ensure segmentation is updated if required to lack of compatibility
                        // with new provider selection
                        if(
                          values.segmentation === 'campaign_objective'
                          && !canSegmentByCampaignObjective(providers)
                        ){
                          update.segmentation = null
                        }
                        updateValues(update)
                      }} />
                  </Grid>

                  <Grid item xs='auto'>
                    <RadioFormGroup
                      name='Segmentation'
                      FormControlLabelProps={{
                        className: classes.formControlLabelTopAligned,
                      }}
                      value={values.segmentation || null}
                      options={[
                        {
                          value: null,
                          label: <div className={classes.formControlLabelPrimaryText}>None</div>
                        },
                        {
                          value: 'network',
                          label: <div className={classes.formControlLabelPrimaryText}>Network</div>
                        },
                        {
                          value: 'sub_network',
                          label: <div className={classes.formControlLabelPrimaryText}>Channel</div>
                        },
                        ...(isVodafonePlatformUnit(platformUnit) && [
                          {
                            value: 'campaign_objective',
                            label: (
                              <div>
                                <div className={classes.formControlLabelPrimaryText}>
                                  Campaign Objective
                                </div>
                                <div className={classes.formControlLabelSecondaryText}>
                                  {campaignObjectiveLabelSuffix}
                                </div>
                              </div>
                            ),
                            disabled: !hasCampaignSegmentation,
                          },
                        ] || []),
                      ] as MenuOption<PerformanceReportSegmentation | null>[]}
                      onChange={(segmentation): void => {
                        updateValues({ segmentation: segmentation || undefined })
                      }} />
                  </Grid>

                  <Grid item xs='auto'>
                    <RadioFormGroup
                      name='Currency'
                      value={values.currency}
                      options={currencyOptions}
                      onChange={(currency): void => {
                        updateValues({ currency })
                      }} />
                  </Grid>
                </Grid>
              )
            }}
          </FilterPanel>


        </Card>

      </Box>

      { performanceReport.loading && (
        <Loader preset='fullsize' minHeight='25rem' />
      )}

      { !!(performanceReport.data && performanceReport.data.length) && (
        <PerformanceReportDataTable
          options={{
            download: true,
            onDownload: (_, __, columns, data): string | false => {
              return buildPerformanceReportCSV(columns, data)
            },
            downloadOptions: {
              filename: getPerformanceReportFilename({
                startDate,
                endDate,
              }),
            },
          }}
          title={
            <Box
              display='flex'
              alignItems='center'
              fontWeight={700}
              fontSize='16px'
              mr={4}>
                Performance Report
              <ReportRange
                TypographyProps={{
                  className: classes.dateRange,
                  variant: 'h6',
                }}
                start={startDate}
                end={endDate} />
            </Box>
          }
          performanceReport={performanceReport.data}
          segmentation={segmentation || null}
          aggregationPeriod={aggregationPeriod || null} />
      )}

    </Box>

  )
}


export const PerformanceReport = (): JSX.Element => {

  const { activePlatformUnit, loading } = useActiveOrgEnvironment()

  useDocumentTitle({
    paths: [
      activePlatformUnit && activePlatformUnit.name,
      'Custom Performance Report',
    ]
  })

  const org_unit_id = activePlatformUnit && activePlatformUnit.id
  
  const [providerInfoApiResponse] = usePlatformUnitProviderInfo({ org_unit_id })

  if( loading || !activePlatformUnit || !providerInfoApiResponse.data ){
    return <BackdropLoader />
  }

  return (
    <PerformanceReportView
      platformUnit={activePlatformUnit}
      providerInfo={providerInfoApiResponse.data} />
  )
}
