import React, { Fragment, useEffect, useMemo } from 'react'

import {
  Alert,
  BackdropLoader,
  BackdropProps,
  Box,
  Divider,
  GridSize,
  Typography,
  useAppTheme,
} from '@percept/mui'

import { PerformanceControls } from 'components/PerformanceControls'

import { PerformanceComparisonRangeSelect } from 'components/PerformanceComparisonRangeSelect'

import { ChannelSummaryCarousel, ChannelSummaryProps } from 'components/ChannelSummary'

import {
  useLayoutGridSpacing,
  useReferenceDate,
  usePerformanceComparisonRange,
  useComparisonsByProvider,
  useDoubleVerifyComparisonsByProvider,
  useDoubleVerifyTimeseriesByProvider,
  useBreakpoint,
  useReferenceDateBehaviour,
  useSavedQueryStrings,
} from 'hooks'

import { useChannelSummaries } from './lib'

import { platformUnitHasDoubleVerify, resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { useProviderDatasets } from './perfUtils'

import {
  stringifyUrlSearchParams,
  useDocumentTitle,
  usePerformanceTimeseriesByProvider,
  usePlatformUnit,
  usePlatformUnitProviderInfo,
  useUrlState,
} from '@percept/hooks'

import { isLoading } from '@percept/utils'

import { format } from 'date-fns'

import { some } from 'lodash-es'

import {
  AnyPerformanceDimension,
  DoubleVerifyProvider,
  NormalizedError,
  PerformanceComparisonRange,
  PlatformUnit,
  PlatformUnitProviderInfo,
  PrimaryPerformanceDataProvider,
} from '@percept/types'

import { DashboardContext, DashboardUrlParams } from 'contexts'
import { useLocation } from 'react-router'



export type CrossChannelPerformanceProps = {
  platformUnit: PlatformUnit | null
  providers: PrimaryPerformanceDataProvider[]
  doubleVerifyProviders: DoubleVerifyProvider[] | null
  providerInfo: PlatformUnitProviderInfo | null
  referenceDate: Date | null
  latestAvailableReferenceDate: Date | null
  activeComparison?: PerformanceComparisonRange
  mainHeader?: React.ReactNode
  secondaryHeaderContent?: React.ReactNode
  spendLabel?: string
  qualityAnalysisHeader?: React.ReactNode
  error?: NormalizedError
  displayLoader?: boolean
  showContent?: boolean
} & Pick<ChannelSummaryProps, 'seriesListing' | 'onSeriesClick'>


function getGridSizing<T>(items: T[]): GridSize {
  switch(items.length){
    case 6: return 2
    case 4: return 3
    case 3: return 4
    case 2: return 6
    default: return 3
  }
}

type ViewConfiguration = {
  viewBatchSize: number
  itemPercentageWidth: string
  totalPercentageWidth: string
  requiresScroll: boolean
}


function useViewConfiguration<T>(items: T[]): ViewConfiguration {
  const breakpoint = useBreakpoint()

  if( ['sm', 'xs'].includes(breakpoint) ){
    return {
      viewBatchSize: 1,
      itemPercentageWidth: '100%',
      totalPercentageWidth: '100%',
      requiresScroll: false,
    }
  }

  if( items.length <= 4 || breakpoint === 'xl' ){
    return {
      viewBatchSize: items.length,
      itemPercentageWidth: `${(100 / items.length)}%`,
      totalPercentageWidth: '100%',
      requiresScroll: false,
    }
  }

  const viewBatchSize = breakpoint === 'md' ? 3 : 4
  const itemWidth = 100 / viewBatchSize
  const totalWidth = Math.ceil(items.length * itemWidth)

  return {
    viewBatchSize,
    itemPercentageWidth: `${itemWidth}%`,
    totalPercentageWidth: `${totalWidth}%`,
    requiresScroll: true,
  }
}


export const CrossChannelPerformance = ({
  mainHeader,
  secondaryHeaderContent,
  error,
  displayLoader = false,
  showContent = true,
  platformUnit,
  providers,
  referenceDate,
  latestAvailableReferenceDate,
  doubleVerifyProviders,
  seriesListing,
  onSeriesClick,
}: CrossChannelPerformanceProps): JSX.Element => {

  const urlStateHookValue = useUrlState<DashboardUrlParams>({
    currency: 'EUR',
    dimension: 'cost',
  })

  const [state, setState] = urlStateHookValue
  
  const [, setSavedQueryStrings] = useSavedQueryStrings()

  const location = useLocation()
  
  useEffect(() => {
    if( location.pathname.includes('performance') ){
      setSavedQueryStrings({ performanceOverview: stringifyUrlSearchParams(state) })
    }
  }, [state, setSavedQueryStrings, location.pathname])

  const { currency, dimension } = state

  const appTheme = useAppTheme()

  const org_unit_id = platformUnit && platformUnit.id

  const [platformUnitDetail] = usePlatformUnit({
    org_unit_id,
  })

  const hasDoubleVerify = !!(platformUnit && platformUnitHasDoubleVerify(platformUnit))

  const [platformUnitProviderInfo] = usePlatformUnitProviderInfo({
    org_unit_id,
  })

  const referenceDateParam = referenceDate && format(referenceDate, 'yyyy-MM-dd')

  const [referenceDateBehaviour] = useReferenceDateBehaviour()

  const comparisonsByProvider = useComparisonsByProvider({
    org_unit_id,
    providers,
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const doubleVerifyComparisonsByProvider = useDoubleVerifyComparisonsByProvider({
    org_unit_id,
    enabledDoubleVerifyProviders: doubleVerifyProviders,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const [timeseriesByProvider] = usePerformanceTimeseriesByProvider({
    org_unit_id,
    period: 'DAYS_365',
    chunking: 'MONTH',
    target_currency: currency,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const doubleVerifyTimeseriesByProvider = useDoubleVerifyTimeseriesByProvider({
    org_unit_id,
    period: 'DAYS_365',
    chunking: 'MONTH',
    enabledDoubleVerifyProviders: doubleVerifyProviders,
    ...(referenceDateParam && {
      reference_date: referenceDateParam,
    })
  })

  const datasets = useProviderDatasets({
    timeseriesByProvider: timeseriesByProvider.data,
    doubleVerifyTimeseriesByProvider: doubleVerifyTimeseriesByProvider.data,
    comparisonsByProvider: comparisonsByProvider.data,
    doubleVerifyComparisonsByProvider: doubleVerifyComparisonsByProvider.data,
    platformUnit: platformUnitDetail.data,
    currency,
  })

  const channelSummaries = useChannelSummaries({
    appTheme,
    datasets,
  })

  const stillLoading = some([
    platformUnitDetail,
    timeseriesByProvider,
    comparisonsByProvider,
    ...(hasDoubleVerify && referenceDateBehaviour === 'LATEST_ALL_INCLUDING_DOUBLEVERIFY' && [
      doubleVerifyComparisonsByProvider,
      doubleVerifyTimeseriesByProvider,
    ] || []),
  ], isLoading)
  
  const backdropProps: Partial<BackdropProps> = {
    open: displayLoader || stillLoading
  }

  const availableDimensions = useMemo(() => {
    if( platformUnitDetail.data ){
      return resolvePerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
      })
    }
    return undefined
  }, [platformUnitDetail.data])

  useEffect(() => {
    if(
      // NOTE - this hook can execute when starting to render structural dashboard overview,
      // so we need to check the location still matches
      location.pathname.includes('performance')
      && availableDimensions
      && dimension !== 'potential_efficiency'
      && !availableDimensions.includes(dimension)
    ){
      setState({ dimension: availableDimensions[0] })
    }
  }, [availableDimensions, dimension, setState, location.pathname])

  useDocumentTitle({
    paths: [
      platformUnitDetail.data && platformUnitDetail.data.name,
      'Performance Overview',
    ]
  })

  const [ , setActiveReferenceDate] = useReferenceDate()

  const [comparisonRange, setComparisonRange] = usePerformanceComparisonRange()

  const gridSpacing = useLayoutGridSpacing()

  const { viewBatchSize, itemPercentageWidth, totalPercentageWidth } = useViewConfiguration(providers)

  const gridSize = getGridSizing(channelSummaries)

  const shouldRender = showContent && !stillLoading

  return (
    <DashboardContext.Provider value={urlStateHookValue}>

      <Box
        my={4}
        mx={3}>

        { error && (
          <Alert
            variant='error'
            maxWidth='30em'
            mx='auto'
            {...error} />
        )}

        { !!(
          shouldRender
          && platformUnit
          && platformUnitDetail.data
          && availableDimensions
          && availableDimensions.includes(dimension as AnyPerformanceDimension)
        ) && (
          <Fragment>
            
            { showContent && (
              <Fragment>
                <Box mt={5} mb={3}>
                  <Typography variant='h5'>
                    <Box display='flex' alignItems='center'>
                      Performance Analysis

                      <Box ml={2}>
                        <PerformanceComparisonRangeSelect
                          size='small'
                          value={comparisonRange}
                          onChange={setComparisonRange}
                          referenceDate={referenceDate} />
                      </Box>
                    </Box>
                  </Typography>
                </Box>

                <Box my={3}>
                  <Divider />
                </Box>

                <PerformanceControls
                  platformUnit={platformUnit}
                  availableDimensions={availableDimensions}
                  potentialEfficiencyEnabled={false}
                  showDimensionSelector={true}
                  referenceDate={referenceDate}
                  maxDate={latestAvailableReferenceDate}
                  doubleVerifyReferenceDate={null}
                  requiresDoubleVerifyTrigger={false} />

                <Box mt={5}>
                  <ChannelSummaryCarousel
                    viewBatchSize={viewBatchSize}
                    GridItemProps={{
                      md: gridSize,
                      style: {
                        width: itemPercentageWidth,
                        maxWidth: itemPercentageWidth,
                        flexBasis: itemPercentageWidth,
                      },
                    }}
                    GridContainerProps={{
                      spacing: gridSpacing,
                      // style: {
                      //   width: totalPercentageWidth,
                      //   overflowX: 'auto',
                      //   flexWrap: 'nowrap',
                      // },
                    }}
                    platformUnit={platformUnitDetail.data}
                    includeDoubleVerify={hasDoubleVerify}
                    providerReferenceDates={platformUnitProviderInfo.data}
                    referenceDate={referenceDate}
                    setReferenceDate={setActiveReferenceDate}
                    performanceComparisonRange={comparisonRange}
                    currency={currency}
                    channelSummaries={channelSummaries}
                    seriesListing={seriesListing}
                    onSeriesClick={onSeriesClick} />
                </Box>
              </Fragment>
            )}

          </Fragment>
        )}

      </Box>

      { backdropProps.open && (
        <BackdropLoader
          BackdropProps={backdropProps} />
      )}

    </DashboardContext.Provider>
  )

}
