import React from 'react'

import { Box, DialogProps, IconButton, Loader, makeAppStyles, PerceptAppBar, Typography, useAppTheme } from '@percept/mui'

import { DefaultBackgroundDialog } from './DefaultBackgroundDialog'

import { ExamplesDataTable } from '../ReportDashboardApp'

import { ArrowBack } from '@percept/mui/icons'

import { DSLRenderer } from './DSLComponents'

import { useDocumentTitle, useFilteredMetricMetadata, useInsightOutputUnitResultExamples } from '@percept/hooks'

import { coerceReportProvider } from '@percept/utils'

import {
  DimensionType,
  InsightOutputUnit,
  MetricExample,
  MetricIdentification,
  MetricMetadataType,
  PlatformUnit,
  ReportEntityPayload,
  ReportProvider,
  ReportProviderV2,
  SeriesParams,
} from '@percept/types'
import { InsightsReportMetric } from './InsightsReportComponents'
import { UserFavouritesPopover } from '../UserFavourites'
import { providerLabelMap } from '@percept/constants'


const useStyles = makeAppStyles( theme => ({
  insightText: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  metricCard: {
    height: 'unset',
    minHeight: '100%',
    alignSelf: 'stretch',
    marginRight: theme.spacing(3),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
}))


export type InsightOutputUnitDetailProps = SeriesParams & {
  insightUnit: InsightOutputUnit
  reportEntity: ReportEntityPayload
  setActiveMetric: (metricIdent: MetricIdentification) => void
  provider: ReportProviderV2
  DialogProps: Partial<Omit<DialogProps, 'onClose'>> & {
    onClose: () => void
  }
  dimension?: DimensionType
  enableFavourites?: boolean
  platformUnit?: PlatformUnit | null
}

export const InsightOutputUnitDetail = ({
  series_id,
  provider,
  insightUnit,
  reportEntity,
  setActiveMetric,
  DialogProps,
  dimension = 'cost',
  enableFavourites = false,
  platformUnit = null,
}: InsightOutputUnitDetailProps): JSX.Element => {

  const appTheme = useAppTheme()

  useDocumentTitle({
    paths: [
      platformUnit && platformUnit.name,
      providerLabelMap[provider],
      insightUnit.title,
    ]
  })

  const [metricMetadata] = useFilteredMetricMetadata({
    provider: coerceReportProvider(provider) as ReportProvider,
  })

  const examplesQuery = useInsightOutputUnitResultExamples({
    series_id,
    insight_output_unit_result_id: insightUnit.id,
    enabled: !!insightUnit.has_entities,
  })

  const classes = useStyles()

  return (
    <DefaultBackgroundDialog
      open
      fullScreen
      maxWidth={false}
      {...DialogProps}>
      <PerceptAppBar
        color='secondary'
        startIcon={
          <IconButton
            color='inherit'
            onClick={DialogProps.onClose}>
            <ArrowBack />
          </IconButton>
        }>
        <Box ml={2}>
          <Typography variant='h5'>
            { insightUnit.title }
          </Typography>
        </Box>
        { enableFavourites && (
          <Box ml='auto'>
            <UserFavouritesPopover />
          </Box>
        )}
      </PerceptAppBar>
      <Box pt={12} px={3}>
        <Typography className={classes.insightText}>
          <DSLRenderer
            appTheme={appTheme}
            text={insightUnit.text}
            currency={insightUnit.currency} />
        </Typography>

        { !!insightUnit.related_metrics.length && metricMetadata.data && (
          <Box my={3} display='flex' alignItems='center'>
            { insightUnit.related_metrics.map( metric_id => {
              return (
                <InsightsReportMetric
                  key={metric_id}
                  metric={{ metric_id }}
                  appTheme={appTheme}
                  metricMetadata={metricMetadata.data}
                  reportEntity={reportEntity}
                  currency={insightUnit.currency}
                  setActiveMetric={setActiveMetric}
                  CardProps={{
                    classes: {
                      root: classes.metricCard,
                    },
                  }}
                  displayType='MINI' />
              )
            })}
          </Box>
        )}

        { !!insightUnit.has_entities && (
          examplesQuery.error ? (
            <Box m={8}>
              Example entities could not be loaded
            </Box>
          ) : !(examplesQuery.data && metricMetadata.data) ? (
            <Loader preset='fullsize' minHeight='12rem' />
          ) : (
            <Box mt={5}>
              <ExamplesDataTable
                examples={examplesQuery.data}
                initialRowsPerPage={10}
                dimension={dimension}
                currency={insightUnit.currency}
                metadata={metricMetadata.data} />
            </Box>
          )
        )}
      </Box>
    </DefaultBackgroundDialog>
  )
}
