import { useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { performanceReporting } from '@percept/redux/bundles'

import { PerformanceReportingState } from '@percept/redux/bundles/performanceReporting'

import {
  apiInitialState,
  getNestedPerformanceTotalsKey,
  getPlatformUnitKey,
} from '@percept/redux'

import {
  MultiOrgUnitPotentialEfficiencyHookProps,
  OrgUnitProviderPotentialEfficiencyScores,
  SingleOrgUnitPotentialEfficiencyHookProps,
  useMultiOrgUnitPotentialEfficiencyScores,
  useOrgUnitPotentialEfficiencyScores,
} from '@percept/hooks'

import { discoverPerformanceProviders } from 'components/Organisation'

import { every, flatten, get, intersection, minBy, noop, some, uniq } from 'lodash-es'

import { addDays, endOfMonth, endOfWeek, format, isAfter, isBefore, startOfMonth, startOfWeek } from 'date-fns'

import { aggregatePotentialEfficiencies, coerceReportProvider, triggerDownload } from '@percept/utils'

import { PerformanceReportReducerState } from './performanceReportReducer'

import {
  dimensionMap,
  networkLabelMap,
  subNetworkLabelMap,
  performanceReportingDimensionOrder,
  providerLabelMap,
  campaignObjectiveLabelMap,
  providersWithCampaignObjectiveSegmentation,
} from '@percept/constants'

import {
  ApiResponse,
  PerformanceDatasetWithCampaignObjective,
  PerformanceDatasetWithNetwork,
  PerformanceDatasetWithSubNetwork,
  PerformanceReportingDimension,
  PerformanceTotals,
  PerformanceTotalsWithCampaignObjective,
  PerformanceTotalsWithNetwork,
  PerformanceTotalsWithSubNetwork,
  PlatformUnit,
  PotentialEfficiencyDatum,
  PrimaryPerformanceDataProvider,
} from '@percept/types'

import {
  DerivedAggregationPeriod,
  DerivedPerformanceTotalsDataset,
  PerformanceReportHookValue,
  PlatformUnitProviderPerformance,
  Request,
  RequestConfig,
} from './typings'

import { StoreState } from 'types'

import { SmartTableColumn } from '@percept/app-components'

const { PREVIEW } = process.env


export const isChildPlatformUnit = (child: PlatformUnit, parent: PlatformUnit): boolean => {

  const walk = (unit: PlatformUnit): void => {
    if( unit.id === child.id ){
      throw new Error('Unit is a child of this parent')
    }
    unit.children && unit.children.forEach(walk)
  }

  try{
    walk(parent)
    return false
  }catch(e){
    noop()
    return true
  }
}


const hasSubnetworkSegmentation = (
  totals: PerformanceTotals | PerformanceTotalsWithNetwork | PerformanceTotalsWithSubNetwork
): totals is PerformanceTotalsWithSubNetwork => (
  !!some(
    (totals as PerformanceTotalsWithSubNetwork).datasets,
    d => !!d.sub_network
  )
)

const hasNetworkSegmentation = (
  totals: PerformanceTotals | PerformanceTotalsWithNetwork | PerformanceTotalsWithSubNetwork
): totals is PerformanceTotalsWithNetwork => (
  !!some(
    (totals as PerformanceTotalsWithNetwork).datasets,
    d => !!d.network
  )
)

const hasCampaignObjectiveSegmentation = (
  totals: PerformanceTotals | PerformanceTotalsWithNetwork | PerformanceTotalsWithCampaignObjective | PerformanceTotalsWithSubNetwork
): totals is PerformanceTotalsWithCampaignObjective => (
  !!some(
    (totals as PerformanceTotalsWithCampaignObjective).datasets,
    d => !!d.campaign_objective
  )
)


const dataHasRequestCoverage = ({
  data,
  requests,
  currency,
}: {
  data: PlatformUnitProviderPerformance[]
  requests: Request[]
  currency: string
}): boolean => {
  return (
    Array.from(
      new Set(
        requests.filter( r => r.response.error === null ).map( ({ config }) => [
          config.org_unit_id,
          config.provider,
          config.start_date,
          config.end_date,
          config.target_currency
        ].join('|'))
      )
    ).length <= (
      Array.from(
        new Set(
          data.map( d => [
            d.org_unit_id,
            d.provider,
            format(d.start, 'yyyy-MM-dd'),
            format(d.end, 'yyyy-MM-dd'),
            currency,
          ].join('|'))
        )
      ).length
    )
  )
}


const resolveAggregationPeriod = (date: Date, aggregationPeriod: DerivedAggregationPeriod): [Date, Date] => {
  switch(aggregationPeriod){
    case 'DAILY':
      return [date, date]
    case 'WEEKLY':
      return [
        startOfWeek(date, { weekStartsOn: 1 }),
        endOfWeek(date, { weekStartsOn: 1 }),
      ]
    case '7_DAYS':
      return [date, addDays(date, 6)]
    case '30_DAYS':
      return [date, addDays(date, 29)]
    case 'MONTHLY':
      return [
        startOfMonth(date),
        endOfMonth(date),
      ]
  }
}

export const isCampaignObjectiveAvailable = (providers: PrimaryPerformanceDataProvider[]): boolean => {
  return !!providers.length && every(providers, p => providersWithCampaignObjectiveSegmentation.includes(p))
}


const resolveChunks = ({
  startDate,
  endDate,
  maxDate,
  aggregationPeriod,
}: {
  startDate: Date
  endDate: Date
  maxDate: Date
  aggregationPeriod: DerivedAggregationPeriod
}): [Date, Date][] => {
  const ranges: [Date, Date][] = []
  if( aggregationPeriod === 'MONTHLY' ){
    startDate = startOfMonth(startDate)
    endDate = endOfMonth(endDate)
  }else if( aggregationPeriod === 'WEEKLY' ){
    startDate = startOfWeek(startDate, { weekStartsOn: 1 })
    endDate = endOfWeek(endDate, { weekStartsOn: 1 })
  }
  let current = startDate
  while( !isAfter(current, endDate) ){
    const range = resolveAggregationPeriod(current, aggregationPeriod)
    const lower = range[0]
    const upper = isBefore(range[1], maxDate) ? range[1] : maxDate
    if( isAfter(range[1], maxDate) ){
      break
    }
    ranges.push([lower, upper])
    current = addDays(upper, 1)
  }
  return ranges
}

type PerformanceReportHookProps = (
  Pick<
    PerformanceReportReducerState,
    'selectedPlatformUnits' | 'providers' | 'segmentation' | 'aggregationPeriod' | 'startDate' | 'endDate' | 'maxDate'
  > & {
    currency: string
  }
)

export const usePerformanceReport = ({
  selectedPlatformUnits,
  providers,
  segmentation,
  aggregationPeriod,
  startDate,
  endDate,
  maxDate,
  currency,
}: PerformanceReportHookProps): PerformanceReportHookValue => {

  const dispatch = useDispatch()

  const org_unit_ids = selectedPlatformUnits.map( u => u.id )
  const isParentUnit = !!(selectedPlatformUnits.length === 1 && selectedPlatformUnits[0].children)

  const canonicalReferenceDate: Date | null = minBy([maxDate, endDate].filter(Boolean), d => d.getTime()) || null

  const start_date = startDate && format(startDate, 'yyyy-MM-dd')

  const reference_date = canonicalReferenceDate && format(canonicalReferenceDate, 'yyyy-MM-dd')

  let usePotentialEfficiencyHook: (
    typeof useOrgUnitPotentialEfficiencyScores
    | typeof useMultiOrgUnitPotentialEfficiencyScores
  ) = useMultiOrgUnitPotentialEfficiencyScores
  let hookParams: (
    SingleOrgUnitPotentialEfficiencyHookProps & { enabled?: boolean } |
    MultiOrgUnitPotentialEfficiencyHookProps & { enabled?: boolean }
  ) = {
    org_unit_ids,
    reference_date,
    start_date,
    currency,
    enabled: false,

  }
  if( isParentUnit ){
    usePotentialEfficiencyHook = useOrgUnitPotentialEfficiencyScores
    hookParams = {
      org_unit_id: org_unit_ids[0],
      reference_date,
      start_date,
      currency,
      enabled: false,
    }
  }

  const potentialEfficiencyHookValue = usePotentialEfficiencyHook(
    hookParams as SingleOrgUnitPotentialEfficiencyHookProps & MultiOrgUnitPotentialEfficiencyHookProps
  )

  const allPerformanceTotals = useSelector<
    StoreState, PerformanceReportingState['totalsByProvider']
  >( state => (
    state.performanceReporting.totalsByProvider
  ))

  const allPerformanceTotalsByNetwork = useSelector<
    StoreState, PerformanceReportingState['totalsByProviderAndNetwork']
  >( state => (
    state.performanceReporting.totalsByProviderAndNetwork
  ))

  const allPerformanceTotalsBySubNetwork = useSelector<
    StoreState, PerformanceReportingState['totalsByProviderAndSubNetwork']
  >( state => (
    state.performanceReporting.totalsByProviderAndSubNetwork
  ))

  const allPerformanceTotalsByCampaignObjective = useSelector<
    StoreState, PerformanceReportingState['totalsByProviderAndCampaignObjective']
  >( state => (
    state.performanceReporting.totalsByProviderAndCampaignObjective
  ))

  const allRelevantTotals = (
    segmentation === 'sub_network' ?
      allPerformanceTotalsBySubNetwork :
      segmentation === 'network' ?
        allPerformanceTotalsByNetwork :
        segmentation === 'campaign_objective' ?
          allPerformanceTotalsByCampaignObjective :
          allPerformanceTotals
  )

  const requestRanges: [Date, Date][] = useMemo(() => {
    if( !aggregationPeriod ){
      return [[startDate, endDate]]
    }
    return resolveChunks({ startDate, endDate, aggregationPeriod, maxDate })
  }, [aggregationPeriod, startDate, endDate, maxDate])

  const requestConfigs: RequestConfig[] = useMemo(() => {

    return flatten(
      flatten(
        requestRanges.map( ([start, end]) => {
          const start_date = format(start, 'yyyy-MM-dd')
          const end_date = format(end, 'yyyy-MM-dd')
          return selectedPlatformUnits.map( unit => {
            const matchingProviders = intersection(providers, discoverPerformanceProviders(unit))
            const org_unit_id = unit.id
            const org_unit_name = unit.name
            return matchingProviders.map( provider => ({
              start,
              end,
              org_unit_id,
              org_unit_name,
              provider,
              start_date,
              end_date,
              target_currency: currency,
            }))
          })
        })
      )
    )
  }, [selectedPlatformUnits, providers, currency, requestRanges])

  const requests = useMemo<Request[]>(() => {
    return requestConfigs.map( config => {
      return {
        config,
        response: get(
          allRelevantTotals,
          [getPlatformUnitKey(config) || '', getNestedPerformanceTotalsKey(config)],
          apiInitialState
        )
      }
    })
  }, [requestConfigs, allRelevantTotals])

  const combinedResponse: ApiResponse<PlatformUnitProviderPerformance[]> = useMemo(() => {
    const loading = (
      some(requests, r => r.response.loading)
      || potentialEfficiencyHookValue.isLoading
      || potentialEfficiencyHookValue.isRefetching
    )
    const data = requests.reduce( (acc, { config, response }) => {
      if( response.data ){
        const containsNetwork = hasNetworkSegmentation(response.data)
        const containsSubNetwork = hasSubnetworkSegmentation(response.data)
        const containsCampaignObjective = hasCampaignObjectiveSegmentation(response.data)
        const datasets = (
          (
            hasNetworkSegmentation(response.data) ||
            hasSubnetworkSegmentation(response.data) || 
            hasCampaignObjectiveSegmentation(response.data)
          ) ?
            response.data.datasets :
            [response.data.dataset]
        )
        datasets.forEach( dataset => {
          // Some datasets may be falsy, where values don't exist for a given set of request parameters.
          // In this case, we derive an empty performance dataset with all potential values explicitly set to `null`
          dataset = dataset || (
            performanceReportingDimensionOrder.reduce( (acc, dimension) => {
              acc[dimension] = null
              return acc
            }, {} as DerivedPerformanceTotalsDataset)
          )

          let potential_efficiency_cost = null
          let potential_efficiency_ratio = null
          if( !containsNetwork && !containsSubNetwork && !containsCampaignObjective && potentialEfficiencyHookValue.data ){
            let matchingEfficiencies: PotentialEfficiencyDatum[] = []
            for( const datum of potentialEfficiencyHookValue.data ){
              if( config.provider === coerceReportProvider(datum.source_provider.slug) ){
                const isMatchedToDataset = (
                  isParentUnit ||
                  (datum as OrgUnitProviderPotentialEfficiencyScores).org_unit_id === config.org_unit_id
                )
                if( isMatchedToDataset ){
                  matchingEfficiencies = matchingEfficiencies.concat(datum.potential_efficiency_scores)
                }
              }
            }
            const aggregatedDatum = aggregatePotentialEfficiencies(
              matchingEfficiencies,
              { start: config.start, end: config.end }
            )
            potential_efficiency_cost = aggregatedDatum.transformed_cost || aggregatedDatum.total_cost
            potential_efficiency_ratio = aggregatedDatum.potential_efficiency_ratio
          }

          acc.push({
            provider: config.provider,
            org_unit_id: config.org_unit_id,
            org_unit_name: config.org_unit_name,
            currency,
            start: config.start,
            end: config.end,
            potential_efficiency_cost,
            potential_efficiency_ratio,
            ...dataset,
            /**
             * NOTE! - Facebook conversions and CPA temporarily coerced to 'N/A' and null
             * for non-preview clients
             */
            ...( (config.provider === 'facebook' && !PREVIEW) ? {
              conversions: 'N/A',
              cpa: null,
            } : {}),
            // NOTE - handle change in API behaviour to emit display labels
            ...(containsNetwork ? {
              network: (
                networkLabelMap[(dataset as PerformanceDatasetWithNetwork).network]
                || (dataset as PerformanceDatasetWithNetwork).network
              ),
            } : {}),
            ...(containsSubNetwork ? {
              sub_network: (
                subNetworkLabelMap[(dataset as PerformanceDatasetWithSubNetwork).sub_network]
                || (dataset as PerformanceDatasetWithSubNetwork).sub_network
              ),
            } : {}),
            ...(containsCampaignObjective ? {
              campaign_objective: (
                campaignObjectiveLabelMap[(dataset as PerformanceDatasetWithCampaignObjective).campaign_objective]
                || (dataset as PerformanceDatasetWithCampaignObjective).campaign_objective
              ),
            } : {}),
          })
        })
      }
      return acc
    }, [] as PlatformUnitProviderPerformance[])

    const hasData = (
      !loading && data.length > 0 && dataHasRequestCoverage({
        data,
        requests,
        currency,
      })
    )

    return {
      loading,
      data: (
        hasData ?
          data :
          !requests.length?
            [] :
            null
      ),
      error: null,
    }
  }, [
    requests,
    currency,
    isParentUnit,
    potentialEfficiencyHookValue.data,
    potentialEfficiencyHookValue.isLoading,
    potentialEfficiencyHookValue.isRefetching,
  ])

  const loader = (): void => {
    potentialEfficiencyHookValue.refetch()
    if( !combinedResponse.loading ){
      const actionCreator = (
        segmentation === 'sub_network' ?
          performanceReporting.actions.loadProviderPerformanceTotalsBySubNetwork :
          segmentation === 'network' ?
            performanceReporting.actions.loadProviderPerformanceTotalsByNetwork :
            segmentation === 'campaign_objective' ?
              performanceReporting.actions.loadProviderPerformanceTotalsByCampaignObjective :
              performanceReporting.actions.loadProviderPerformanceTotals
      )
      requests.forEach( ({ config, response }) => {
        if( !response.loading ){
          dispatch(actionCreator(config))
        }
      })
    }
  }

  return [combinedResponse, loader]
}


const itemToString = <T>(item: T): string => {
  let output = ''
  if( typeof item === 'string' ){
    output = providerLabelMap[item as PrimaryPerformanceDataProvider] || item
  }
  if( typeof item === 'number' ){
    output = String(item)
  }
  if( /[/,]/.test(output) ){
    return `"${output}"`
  }
  return output
}

const columnMapping: Record<keyof PlatformUnitProviderPerformance, string> = {
  org_unit_id: 'ID',
  org_unit_name: 'Organisation',
  provider: 'Provider',
  currency: 'Currency',
  network: 'Network',
  sub_network: 'Channel',
  campaign_objective: 'Campaign Objective',
  start: 'Period Start',
  end: 'Period End',
  potential_efficiency_cost: 'Wastage',
  potential_efficiency_ratio: 'Wastage %',
  ...(performanceReportingDimensionOrder.reduce( (acc, dimension) => {
    acc[dimension] = dimensionMap[dimension].text
    return acc
  }, {} as Record<PerformanceReportingDimension, string>)),
}

const columnOrder: (keyof PlatformUnitProviderPerformance)[] = [
  'org_unit_name',
  'provider',
  'currency',
  ...performanceReportingDimensionOrder,
]


export const getPerformanceReportFilename = ({
  startDate,
  endDate,
}: {
  startDate: Date
  endDate: Date
}): string => {
  const dateString = uniq(
    [startDate, endDate].map( d => format(d, 'dd-MM-yy'))
  ).join(' – ')
  return `Performance Report ${dateString}.csv`
}


export const buildPerformanceReportCSV = <T extends PlatformUnitProviderPerformance>(
  columns: SmartTableColumn<T>[],
  data: {
    index: number,
    data: T[Extract<keyof T, string>][]
  }[],
): string | false => {

  const header: string = columns.map( ({ name }) => name ).join(', ')

  const rows: string[] = data.map( row => (
    row.data.map( value => itemToString(value) ).join(', ')
  ))

  const csv = [
    header,
    ...rows
  ].join('\n')

  return csv
}


export const downloadPerformanceReport = ({
  performanceReport,
  startDate,
  endDate,
}: {
  performanceReport: PlatformUnitProviderPerformance[]
} & Pick<PerformanceReportReducerState, 'startDate' | 'endDate'>): void => {
  const header: string = columnOrder.map( key => columnMapping[key] ).join(', ')
  const rowValues: string[] = performanceReport.map( row => (
    columnOrder.map( key => itemToString(row[key]) ).join(', ')
  ))
  const csv = [
    header,
    ...rowValues
  ].join('\n')

  const filename = getPerformanceReportFilename({
    startDate,
    endDate,
  })

  triggerDownload({
    filename,
    contents: csv,
    mimeType: 'text/csv',
  })

}
