import React, { Fragment, useMemo, useRef, useState } from 'react'

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableFooter,
  TableHead,
  TableProps,
  TableRow,
} from '@material-ui/core'

import { ClassNameMap } from '@material-ui/styles'

import { ArrowDownward, ArrowDropDown, ArrowDropUp, ArrowUpward } from '@material-ui/icons'

import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'

import { makeAppStyles } from '../../themes'

import { SecondaryIconTooltip } from '../Tooltips'

import { get, identity, sortBy } from 'lodash-es'

import {
  CellRenderer,
  CellRenderers,
  Column,
  DefaultCellRenderer,
  TableSortConfig,
} from './typings'


type SimpleTableClassNames = (
  'tableRow' | 'tableRowClickable' | 'tableCell' | 'headerCell' | 'footerRow' |
  'groupedRow' | 'groupedChildRow' | 'groupedTableBody' | 'groupToggle' |
  'groupToggleCellContainer' | 'groupToggleCellContent' |
  'columnHeaderContent' | 'columnHeaderContentCenter' | 'columnHeaderContentLeft' |
  'columnHeaderContentRight' | 'clickable' | 'headerTooltip' | 'sortIcon' | 'stickyHeader' |
  'tableHead' | 'tableFooter'
)

export type SimpleTableProps<T extends object> = (
  Partial<
    TableProps
  > & {
    columns: Column<T>[]
    rows: T[]
    footerRows?: T[] | null
    renderers?: CellRenderers<T>
    defaultRenderer?: DefaultCellRenderer<T>
    classes?: Partial<ClassNameMap<SimpleTableClassNames>>
    pinFirstColumn?: boolean
    sortable?: boolean
    initialSortConfig?: TableSortConfig<T>
    onRowClick?: (event: React.MouseEvent, row: T) => void
    getRowKey?: (row: T) => string | number
    TableContainerProps?: TableContainerProps
    unsetStickyHeaderZIndex?: boolean
    wrapCellText?: boolean
  } & (
    {
      grouped?: never
      expandedByDefault?: never
      getRowGroup?: never
      onRowExpand?: never
      onRowCollapse?: never
      groupColor?: never
    } | {
      grouped: true
      expandedByDefault?: boolean
      getRowGroup: (row: T) => T[]
      onRowExpand?: (row: T) => void
      onRowCollapse?: (row: T) => void
      groupColor?: 'primary' | 'secondary' | 'neutral'
    }
  )
)

const stickyCellShadow: CreateCSSProperties = {
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: 5,
    background: `linear-gradient(to right, ${
      'rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%'
    })`,
  },
}

const useStyles = makeAppStyles( theme => ({
  tableRow: {
    background: theme.palette.background.paper,
    '&:nth-child(odd)': {
      background: theme.palette.action.disabledBackground,
    },
  },
  groupedTableBodyNeutral: {
    '& $groupedRow': {
      color: theme.palette.neutral.contrastText,
      background: theme.palette.neutral.light,
    },
    '&:nth-child(odd) $groupedRow': {
      background: theme.palette.neutral.main,
    },
    '& .MuiTableCell-body': {
      color: 'inherit',
    },
  },
  groupedTableBodyPrimary: {
    '& $groupedRow': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.light,
    },
    '&:nth-child(odd) $groupedRow': {
      background: theme.palette.primary.main,
    },
    '& .MuiTableCell-body': {
      color: 'inherit',
    },
  },
  groupedTableBodySecondary: {
    '& $groupedRow': {
      color: theme.palette.secondary.contrastText,
      background: theme.palette.secondary.light,
    },
    '&:nth-child(odd) $groupedRow': {
      background: theme.palette.secondary.main,
    },
    '& .MuiTableCell-body': {
      color: 'inherit',
    },
  },
  groupedRow: {
    '& .MuiTableCell-body': {
      color: 'inherit',
    },
    '& .MuiTableCell-body:first-child': {
      paddingLeft: theme.spacing(1),
    },
  },
  groupedChildRow: {
    '& .MuiTableCell-body:first-child': {
      paddingLeft: 44,
    },
    '& $tableCell': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  groupToggle: {
    marginTop: -4,
    marginBottom: -4,
    marginLeft: -2,
    zIndex: 'unset',
  },
  groupToggleCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  groupToggleCellContent: {
    display: 'flex',
    marginLeft: 6,
  },
  tableFooter: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: -8,
      left: 0,
      height: 8,
      width: '100%',
      zIndex: theme.zIndex.appBar - 1,
      background: `linear-gradient(to top, ${
        'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%'
      })`,
    },
  },
  footerRow: {
    ...(get(theme.appComponents, ['table', 'footer']) || {}),
    borderTop: '1px solid',
    '&:first-of-type': {
      borderTop: 'none',
    },
    '& .MuiTableCell-footer': {
      color: 'inherit',
    }
  },
  columnHeaderContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  columnHeaderContentCenter: {
    justifyContent: 'center',
  },
  columnHeaderContentLeft: {
    justifyContent: 'flex-start',
  },
  columnHeaderContentRight: {
    justifyContent: 'flex-end',
  },
  clickable: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  tableCell: {
    fontWeight: theme.typography.fontWeightBold,
    transition: theme.transitions.create('color'),
  },
  noTextWrap: {
    textWrap: 'nowrap'
  },
  tableRowClickable: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '& .MuiTableCell-body': {
        color: theme.palette.primary.contrastText,
      }
    },
  },
  headerCell: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
  },
  headerTooltip: {
    marginLeft: theme.spacing(0.5),
  },
  sortIcon: {
    fontSize: 16,
    marginLeft: 4,
    color: theme.palette.text.hint,
  },
  stickyHeaderAlways: {
    left: 'unset',
  },
  stickyHeader: {
    background: theme.palette.background.paper,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -8,
      left: 0,
      width: '100%',
      height: 8,
      background: `linear-gradient(to bottom, ${
        'rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%'
      })`,
      zIndex: 2,
    },
    zIndex: theme.zIndex.appBar - 3,
  },
  zIndexUnset: {
    zIndex: 'unset',
  },
  stickyTableHeaderCell: {
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar - 1,
    paddingRight: 12,
    ...stickyCellShadow,
  },
  stickyTableBodyCell: {
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar - 2,
    background: 'inherit',
    paddingRight: 12,
    ...stickyCellShadow,
  },
}) )


function defaultCellRenderer<T extends object>(item: T, key: keyof T): React.ReactNode {
  return item[key] as React.ReactNode
}


const flipSortOrder = (order: 'ASC' | 'DESC'): 'ASC' | 'DESC' => (
  order === 'ASC' ? 'DESC' : 'ASC'
)


export function SimpleTable<T extends object>({
  columns,
  rows,
  grouped,
  expandedByDefault = false,
  groupColor = 'neutral',
  getRowGroup,
  onRowExpand,
  onRowCollapse,
  footerRows,
  renderers,
  defaultRenderer = defaultCellRenderer,
  classes = {},
  initialSortConfig,
  sortable,
  onRowClick,
  getRowKey,
  TableContainerProps,
  unsetStickyHeaderZIndex = true,
  wrapCellText = true,
  pinFirstColumn = false,
  ...props
}: SimpleTableProps<T>): JSX.Element {

  const mergedClasses = {
    ...useStyles(),
    ...classes,
  }

  const groupedTableBodyClassName = {
    neutral: mergedClasses.groupedTableBodyNeutral,
    secondary: mergedClasses.groupedTableBodySecondary,
    primary: mergedClasses.groupedTableBodyPrimary,
  }[groupColor]

  let stickyHeaderClassName = `${mergedClasses.stickyHeader} ${mergedClasses.stickyHeaderAlways}`
  if( unsetStickyHeaderZIndex ){
    stickyHeaderClassName += (' ' + mergedClasses.zIndexUnset)
  }

  let headerCellClassName = mergedClasses.headerCell
  let tableBodyCellClassName = mergedClasses.tableCell
  if( !wrapCellText ){
    headerCellClassName += (' ' + mergedClasses.noTextWrap)
    tableBodyCellClassName += (' ' + mergedClasses.noTextWrap)
  }

  const [sortConfig, setSortConfig] = useState(initialSortConfig || null)

  const columnsRef = useRef(columns)
  columnsRef.current = columns

  const groupAdapterRef = useRef(getRowGroup)
  groupAdapterRef.current = getRowGroup

  const [expandedGroupState, setExpandedGroupState] = useState<Record<number | string, boolean>>({})

  const rowGroups = useMemo(() => {
    if( !grouped ){
      return [{ parentRow: null, childRows: rows }]
    }
    return rows.map( parentRow => ({
      parentRow,
      childRows: groupAdapterRef.current && groupAdapterRef.current(parentRow) || [],
    }))
  }, [rows, grouped])

  const sortedRowGroups = useMemo(() => {
    if( !sortConfig ){
      return rowGroups
    }
    const { key } = sortConfig
    const column = columnsRef.current.find( c => c.key === key )
    const getSortValue = column && column.getSortValue || identity
    const rowGroupsWithSortedChildRows = rowGroups.map( ({ parentRow, childRows }) => {
      const sortedRows = sortBy(
        childRows,
        row => {
          const value = getSortValue(row[sortConfig.key])
          if( !value ) return Number(value)
          return value
        }
      )
      if( sortConfig.order !== 'ASC' ){
        sortedRows.reverse()
      }
      return {
        parentRow,
        childRows: sortedRows
      }
    })

    const sortedGroups = sortBy(
      rowGroupsWithSortedChildRows,
      row => {
        if( !row.parentRow ){
          return null
        }
        const value = getSortValue(row.parentRow[sortConfig.key])
        if( !value ) return Number(value)
        return value
      }
    )
    if( sortConfig.order !== 'ASC' ){
      sortedGroups.reverse()
    }
    return sortedGroups
  }, [rowGroups, sortConfig])

  return (
    <TableContainer {...TableContainerProps}>
      <Table
        {...props}>

        <TableHead
          className={mergedClasses.tableHead}>

          { columns.map( ({ key, label, tooltip, ...props }, columnIndex) => (
            <TableCell
              classes={{
                stickyHeader: (
                  columnIndex === 0 && pinFirstColumn ?
                    stickyHeaderClassName + ' ' + mergedClasses.stickyTableHeaderCell :
                    stickyHeaderClassName
                ),
              }}
              className={headerCellClassName}
              key={key}
              {...props}>
              <span
                className={
                  [
                    mergedClasses.columnHeaderContent,
                    props.align === 'center' && mergedClasses.columnHeaderContentCenter,
                    props.align === 'right' && mergedClasses.columnHeaderContentRight,
                    props.align === 'left' && mergedClasses.columnHeaderContentLeft,
                    sortable && mergedClasses.clickable
                  ].filter(Boolean).join(' ')
                }
                onClick={(): void => {
                  if( sortable ){
                    if( !sortConfig ){
                      setSortConfig({
                        key,
                        order: 'DESC',
                      })
                    }else{
                      if( sortConfig.key === key ){
                        setSortConfig({
                          key,
                          order: flipSortOrder(sortConfig.order)
                        })
                      }else{
                        setSortConfig({
                          key,
                          order: sortConfig.order,
                        })
                      }
                    }
                  }
                }}>
                <Fragment>
                  { label }
                  { tooltip && (
                    <SecondaryIconTooltip
                      className={mergedClasses.headerTooltip}
                      title={tooltip} />
                  )}
                  { !!(sortable && sortConfig && sortConfig.key === key) && (
                    sortConfig.order === 'ASC' ?
                      <ArrowUpward className={mergedClasses.sortIcon} /> :
                      <ArrowDownward className={mergedClasses.sortIcon} />
                  )}
                </Fragment>
              </span>
            </TableCell>
          ))}

        </TableHead>

        { sortedRowGroups.map( ({ parentRow, childRows }, i) => {
          const groupKey = parentRow && getRowKey ? getRowKey(parentRow) : i
          let className = (
            grouped ?
              mergedClasses.groupedRow || mergedClasses.tableRow :
              mergedClasses.tableRow
          )
          if( onRowClick ){
            className += (' ' + mergedClasses.tableRowClickable)
          }
          return (
            <TableBody
              key={`body-${groupKey}`}
              className={grouped ? groupedTableBodyClassName : undefined}>
              { parentRow && (
                <TableRow
                  className={className}
                  key={`group-${groupKey}`}
                  onClick={onRowClick && ((e: React.MouseEvent): void => onRowClick(e, parentRow))}>

                  { columns.map( ({ key, label, ...props }, columnIndex) => {

                    let cellContent = null

                    if( renderers && renderers[key] ){
                      const Renderer = renderers[key] as CellRenderer<T>
                      cellContent = <Renderer {...parentRow} />
                    }else{
                      cellContent = defaultRenderer(parentRow, key)
                    }

                    let className = tableBodyCellClassName
                    if( columnIndex === 0 && pinFirstColumn ){
                      className += (' ' + mergedClasses.stickyTableBodyCell)
                    }

                    const currentExpandState = Boolean(
                      get(expandedGroupState, groupKey, expandedByDefault)
                    )

                    return (
                      <TableCell
                        className={className}
                        key={`${groupKey}-${key}`}
                        {...props}>
                        { grouped && columnIndex === 0 ? (
                          <span
                            className={mergedClasses.groupToggleCellContainer}>
                            <IconButton
                              color='inherit'
                              size='small'
                              className={mergedClasses.groupToggle}
                              onClick={(e): void => {
                                e.preventDefault()
                                e.stopPropagation()
                                if( currentExpandState === false && onRowExpand ){
                                  onRowExpand(parentRow)
                                }else if( currentExpandState === true && onRowCollapse ){
                                  onRowCollapse(parentRow)
                                }
                                setExpandedGroupState( prev => ({...prev, [groupKey]: !currentExpandState }))
                              }}>
                              { currentExpandState ? <ArrowDropUp /> : <ArrowDropDown /> }
                            </IconButton>
                            <span className={mergedClasses.groupToggleCellContent}>
                              { cellContent }
                            </span>
                          </span>
                        ) : cellContent }
                      </TableCell>
                    )
                  })}

                </TableRow>
              )}

              { (!grouped || get(expandedGroupState, groupKey, expandedByDefault)) && childRows.map( (childRow, j) => {
                const childKey = getRowKey ? getRowKey(childRow) : j
                let className = mergedClasses.tableRow
                if( grouped ){
                  className += (' ' + mergedClasses.groupedChildRow)
                }
                if( onRowClick ){
                  className += (' ' + mergedClasses.tableRowClickable)
                }
                return (
                  <TableRow
                    className={className}
                    key={`group-${groupKey}-child-${childKey}`}
                    onClick={onRowClick && ((e: React.MouseEvent): void => onRowClick(e, childRow))}>

                    { columns.map( ({ key, label, ...props }, columnIndex) => {

                      let cellContent = null

                      if( renderers && renderers[key] ){
                        const Renderer = renderers[key] as CellRenderer<T>
                        cellContent = <Renderer {...childRow} />
                      }else{
                        cellContent = defaultRenderer(childRow, key)
                      }

                      let className = tableBodyCellClassName
                      if( columnIndex === 0 && pinFirstColumn ){
                        className += (' ' + mergedClasses.stickyTableBodyCell)
                      }

                      return (
                        <TableCell
                          className={className}
                          key={`${i}-${key}`}
                          {...props}>
                          { cellContent }
                        </TableCell>
                      )
                    })}

                  </TableRow>
                )
              })}
            </TableBody>
          )
        })}

        { !!(footerRows && footerRows.length) && (
          <TableFooter className={mergedClasses.tableFooter}>
            { footerRows.map( (footerRow, i) => (
              <TableRow
                key={`footer-row-${i}`}
                className={mergedClasses.footerRow}>
                { columns.map( ({ key, label, ...props }, columnIndex) => {

                  let cellContent = null

                  if( renderers && renderers[key] ){
                    const Renderer = renderers[key] as CellRenderer<T>
                    cellContent = <Renderer {...footerRow} />
                  }else{
                    cellContent = defaultRenderer(footerRow, key)
                  }

                  let className = tableBodyCellClassName
                  if( columnIndex === 0 && pinFirstColumn ){
                    className += (' ' + mergedClasses.stickyTableBodyCell)
                  }

                  return (
                    <TableCell
                      className={className}
                      key={`footer-${i}-${key}`}
                      {...props}>
                      { cellContent }
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableFooter>
        )}

      </Table>
    </TableContainer>
  )
}
