import React, { Fragment, useState } from 'react'

import { Button, ButtonProps, Dialog, DialogProps } from '@material-ui/core'


type ButtonComponentType<P extends ButtonProps> = React.ComponentType<P>


export type ButtonDialogProps<P extends ButtonProps = ButtonProps> = Omit<P, 'onClick' | 'children'> & {
  ButtonComponent?: ButtonComponentType<P>
  buttonContent?: React.ReactNode | React.ReactNode[]
  DialogProps?: Partial<Omit<DialogProps, 'open' | 'onClose' | 'children'>>
  children: JSX.Element | JSX.Element[] | React.ReactNode | ((props: { onClose: () => void }) => JSX.Element)
  onClose?: () => void
  onOpen?: () => void
}


export function ButtonDialog<P extends ButtonProps>({
  ButtonComponent = Button,
  DialogProps = {},
  buttonContent,
  children,
  onClose,
  onOpen,
  ...props
}: ButtonDialogProps<P>): JSX.Element {

  const [open, setOpen] = useState(false)

  const handleClick = (): void => {
    onOpen && onOpen()
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  return (
    <Fragment>

      <ButtonComponent
        onClick={handleClick}
        {...props as unknown as P}>
        { buttonContent }
      </ButtonComponent>

      <Dialog
        open={open}
        {...DialogProps}
        onClose={(): void => {
          handleClose()
          onClose && onClose()
        }}>

        { typeof children === 'function' ? children({ onClose: handleClose }) : children }

      </Dialog>

    </Fragment>
  )
}
