import React, { useMemo } from 'react'

import { Redirect } from 'react-router'

import { BackdropLoader } from '@percept/mui'

import { MultiUnitChannel } from './MultiUnitChannel'

import { SingleUnitChannel } from './SingleUnitChannel'

import { useActiveOrgEnvironment } from 'components/Organisation'

import { useDocumentTitle, usePlatformUnitProviderInfo, useUrlState } from '@percept/hooks'

import { useReferenceDate, useReferenceDateBehaviour, useSavedQueryStrings } from 'hooks'

import { isPlatformUnitContainer } from '@percept/utils'

import { deriveCanonicalReferenceDate } from 'components/ReferenceDates'

import { ChannelKey, PrimaryPerformanceDataProvider, ReportProvider  } from '@percept/types'

import { providerChannelMap, providerDoubleVerifyMap, providerLabelMap } from '@percept/constants'
import { DashboardContext, DashboardUrlParams } from 'contexts'


export type ChannelViewManagerProps = {
  channel: ChannelKey | null
  provider?: PrimaryPerformanceDataProvider | null
}


export const ChannelViewManager = ({
  channel,
  provider,
}: ChannelViewManagerProps): JSX.Element => {

  const {
    activePlatformUnit,
    providers,
    doubleVerifyProviders,
    loading,
  } = useActiveOrgEnvironment()

  useDocumentTitle({
    paths: [
      activePlatformUnit && activePlatformUnit.name,
      provider && providerLabelMap[provider],
    ]
  })

  const [providerInfo] = usePlatformUnitProviderInfo({
    org_unit_id: activePlatformUnit && activePlatformUnit.id,
  })

  const [activeReferenceDate] = useReferenceDate()

  const [referenceDateBehaviour] = useReferenceDateBehaviour()

  const urlStateHookValue = useUrlState<DashboardUrlParams>({
    currency: 'EUR',
    dimension: 'cost',
  })

  const [savedQueryStrings] = useSavedQueryStrings()

  const referenceDate = useMemo(() => {
    const matchingProviders = [
      ...providers.filter( p => (
        provider ?
          p === provider :
          channel ?
            providerChannelMap[p] === channel :
            false
      )),
      ...doubleVerifyProviders.filter( p => (
        provider ?
          p === providerDoubleVerifyMap[provider as ReportProvider] :
          channel ?
            providerChannelMap[p] === channel :
            false
      )),
    ]
    return deriveCanonicalReferenceDate({
      providerInfo: providerInfo.data,
      referenceDateBehaviour,
      activeReferenceDate,
      enabledProviders: matchingProviders,
    })
  }, [
    providerInfo.data, referenceDateBehaviour, activeReferenceDate,
    providers, doubleVerifyProviders, provider, channel,
  ])

  if( !activePlatformUnit || !providerInfo.data || loading ){
    return (
      <BackdropLoader />
    )
  }

  if( !isPlatformUnitContainer(activePlatformUnit) ){

    if(
      !activePlatformUnit.performance_reporting ||
      !activePlatformUnit.performance_reporting.enabled
    ){
      return (
        <Redirect to={{
          pathname: `/series/${activePlatformUnit.report_series[0].id}`,
          search: savedQueryStrings.series,
        }} />
      )
    }

    return (
      <DashboardContext.Provider value={urlStateHookValue}>
        <SingleUnitChannel
          platformUnit={activePlatformUnit}
          providerInfo={providerInfo.data}
          referenceDate={referenceDate}
          channel={channel}
          provider={provider} />
      </DashboardContext.Provider>
    )


  }else{

    return (
      <DashboardContext.Provider value={urlStateHookValue}>
        <MultiUnitChannel
          platformUnit={activePlatformUnit}
          providerInfo={providerInfo.data}
          referenceDate={referenceDate}
          channel={channel}
          provider={provider} />
      </DashboardContext.Provider>
    )
  }

}
