import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query'

import { apiClients, makeClientQueryFn } from '@percept/hooks'

import qs from 'query-string'

import { saveAs } from 'file-saver'

import { format } from 'date-fns'

import { Dictionary, TimeseriesGranularity } from '@percept/types'
import { filterTruthyMapping } from '@percept/utils'


export type UserEventType = 'PAGE_VIEW'

export type UserEventSegmentation = (
  | 'DATE'
  | 'MARKET'
  | 'PRIMARY_APPLICATION'
  | 'SECONDARY_APPLICATION'
)

export type PrimaryApplication = (
  | 'MEDIA_INVESTMENT'
  | 'MEDIA_QUALITY_DASHBOARD'
  | 'VODAFONE_MEDIA_WIZARD'
)

export type SecondaryApplication = (
  | 'ANALYTICS'
  | 'BRAND_GROWTH_PLANNER'
  | 'COMPETITIVE_INVESTMENT_SOS'
  | 'COMPETITIVE_INVESTMENT_SOV'
  | 'CUSTOM_PERFORMANCE_REPORT'
  | 'DATA_WAREHOUSE'
  | 'HOME'
  | 'INSIGHTS'
  | 'MEDIA_MIX_MODELLING'
  | 'MEDIA_QUALITY_RATE'
  | 'NEWS'
  | 'OPTIMISATION'
  | 'OVERVIEW'
  | 'PARTNERSHIPS'
  | 'PATHWAYS'
  | 'PERFORMANCE_OVERVIEW'
  | 'PRIMARY_INVESTMENT'
  | 'REPORT_DASHBOARD'
  | 'REPORTING'
  | 'SMART_CAMPAIGN_ASSESSMENT'
  | 'SPONSORSHIPS'
  | 'STRATEGY'
  | 'SUBMISSION_TRACKER'
  | 'USER_MANAGEMENT'
  | 'WASTAGE_TREND_REPORTS'
  | 'WEB_FORM'
  | 'WIZARD_HOME'
  | 'WIZARD_OVERVIEW'
)


export type AggregatedUserEvent = {
  event_type: UserEventType
  primary_application: PrimaryApplication
  secondary_application: SecondaryApplication | null
  date: string
  event_count: number
  user_count: number
}

export type AggregatedMarketUserEvent = AggregatedUserEvent & {
  market: string
}


type UserEventHookPropsBase = {
  start?: Date | null
  end?: Date | null
}


const getUserEventParamsBase = (
  { start, end }: UserEventHookPropsBase
): Partial<Record<'start_date' | 'end_date', string>> => {
  const params: Dictionary = {}
  const dateFormatString = 'yyyy-MM-dd'
  if( start ){
    params.start_date = format(start, dateFormatString) + 'T00:00:00'
  }
  if( end ){
    params.end_date = format(end, dateFormatString) + 'T23:59:59'
  }
  return params
}

type TenantMarketUserEventHookProps = (
  UserEventHookPropsBase & {
    tenant_id: string | null
    segments?: UserEventSegmentation[]
    granularity?: TimeseriesGranularity
    market_slugs?: string[] | null
    primary_applications?: PrimaryApplication[] | null
    secondary_applications?: SecondaryApplication[] | null
  }
)


export const useTenantMarketUserEvents = ({
  tenant_id,
  start,
  end,
  segments,
  granularity,
  market_slugs,
  primary_applications,
  secondary_applications,
}: TenantMarketUserEventHookProps): UseQueryResult<AggregatedMarketUserEvent[]> => {
  const { start_date, end_date } = getUserEventParamsBase({ start, end })
  return useQuery({
    queryKey: [
      'userEvents',
      'tenant',
      tenant_id,
      'market',
      start_date,
      end_date,
      granularity,
      market_slugs,
      primary_applications,
      secondary_applications,
      ...(segments || []).slice().sort(),
    ],
    queryFn: makeClientQueryFn<AggregatedMarketUserEvent[]>(
      apiClients.core,
      {
        url: `/user-events/tenants/${tenant_id}/market`,
        params: {
          start_date,
          end_date,
          segments,
          ...filterTruthyMapping({
            granularity,
            market_slugs,
            primary_applications,
            secondary_applications,
          }),
        },
        paramsSerializer: qs.stringify,
      }
    ),
    enabled: !!tenant_id,
    retry: false,
    refetchOnWindowFocus: false,
  })
}


type TenantMarketUserEventExportHookProps = (
  Omit<TenantMarketUserEventHookProps, 'segments'> & {
    cardinality_type: 'VIEWS' | 'USERS'
  }
)


export const useTenantMarketUserEventsCSVExport = (): UseMutationResult<
  void, unknown, TenantMarketUserEventExportHookProps
> => {
  return useMutation({
    mutationFn: async ({
      tenant_id,
      cardinality_type,
      start,
      end,
      market_slugs,
      primary_applications,
      secondary_applications,
    }: TenantMarketUserEventExportHookProps) => {
      const response = await apiClients.core.get<Blob>(
        `/user-events/tenants/${tenant_id}/market/export`,
        {
          params: {
            cardinality_type: cardinality_type.toLowerCase(),
            ...getUserEventParamsBase({ start, end }),
            ...filterTruthyMapping({
              market_slugs,
              primary_applications,
              secondary_applications,
            })
          },
          paramsSerializer: qs.stringify,
          responseType: 'blob',
          headers: {
            'Content-Type': 'text/csv',
          }
        }
      )

      // Get filename from content disposition header if available
      const contentDisposition: string = response.headers && response.headers['content-disposition']
      // Set default filename if not found in response headers
      let filename = 'user_events.csv'
      if( contentDisposition ){
        const headerValues = contentDisposition.split(';').map( s => s.trim())
        const filenameValue = headerValues.find( v => v.startsWith('filename='))
        if( filenameValue ){
          filename = filenameValue.replace('filename=', '')
        }
      }
      saveAs(response.data, filename)
    }
  })
}
