import { actionCreator } from '@percept/redux'

import qs from 'query-string'

import {
  AssociateSoftwareTokenParams,
  AsyncReduxAction,
  BaseReduxAction,
  CodeChallengeParams,
  InitialPasswordParams,
  LoginParams,
  MFADeliveryMedium,
  OrgPrivilege,
  ReduxAction,
  SoftwareMFAStateParams,
  TokenRefreshParams,
  VerifySoftwareTokenParams,
} from '@percept/types'

import { addNotification } from '../notifications/actions'


const { CORE_API_ROOT } = process.env


export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'


export const userLogin = actionCreator(USER_LOGIN)
export const userLogout = actionCreator(USER_LOGOUT)


export const TOKEN_AUTOLOGIN = 'TOKEN_AUTOLOGIN'

export const tokenAutologin = (payload: TokenRefreshParams): ReduxAction => ({
  type: TOKEN_AUTOLOGIN,
  payload
})


export const CHECK_REFRESH_TOKEN = 'CHECK_REFRESH_TOKEN'

export const checkRefreshToken = actionCreator(CHECK_REFRESH_TOKEN)


export const STORE_MFA_DELIVERY_MEDIUM = 'STORE_MFA_DELIVERY_MEDIUM'

export const storeMfaDeliveryMedium = (payload: MFADeliveryMedium | null): ReduxAction => ({
  type: STORE_MFA_DELIVERY_MEDIUM,
  payload,
})


const createAsyncUserAction = (action: AsyncReduxAction): AsyncReduxAction => ({
  ...action,
  options: {
    baseURL: `${CORE_API_ROOT}/v1`,
    paramsSerializer: (params): string => {
      return (
        qs.stringify(params, {
          arrayFormat: 'none',
        })
      )
    },
    ...action.options || {},
  }
})


export const LOAD_SINGLE_SIGN_ON_METADATA = 'LOAD_SINGLE_SIGN_ON_METADATA'

export const loadSingleSignOnMetadata = (): AsyncReduxAction => createAsyncUserAction({
  type: LOAD_SINGLE_SIGN_ON_METADATA,
  resource: '/auth/sso/login-metadata',
  notifyError: false,
})


export const EXCHANGE_SINGLE_SIGN_ON_CODE = 'EXCHANGE_SINGLE_SIGN_ON_CODE'

export const exchangeSingleSignOnCode = (data: { code: string }): AsyncReduxAction => createAsyncUserAction({
  type: EXCHANGE_SINGLE_SIGN_ON_CODE,
  resource: '/auth/sso/code-exchange',
  options: {
    method: 'POST',
    data,
  },
})

export const RESET_EXCHANGE_SINGLE_SIGN_ON_CODE = 'RESET_EXCHANGE_SINGLE_SIGN_ON_CODE'

export const resetExchangeSingleSignOnCode = (): BaseReduxAction => ({
  type: RESET_EXCHANGE_SINGLE_SIGN_ON_CODE,
})


export const SIGN_IN = 'SIGN_IN'

export const signIn = (params: LoginParams): AsyncReduxAction => createAsyncUserAction({
  type: SIGN_IN,
  resource: '/auth/login',
  options: {
    method: 'POST',
    data: params,
  },
  meta: { username: params.username },
  notifyError: false,
})


export const SIGN_OUT = 'SIGN_OUT'

export const signOut = (): BaseReduxAction => ({
  type: SIGN_OUT
})


export const SET_INITIAL_PASSWORD = 'SET_INITIAL_PASSWORD'

export const setInitialPassword = (params: InitialPasswordParams): AsyncReduxAction => createAsyncUserAction({
  type: SET_INITIAL_PASSWORD,
  resource: '/auth/set-initial-password',
  options: {
    method: 'POST',
    data: params,
  },
  meta: { username: params.username },
})


export const OAUTH_REFRESH = 'OAUTH_REFRESH'

export const oAuthRefresh = (params: TokenRefreshParams): AsyncReduxAction => createAsyncUserAction({
  type: OAUTH_REFRESH,
  resource: '/auth/refresh',
  options: {
    method: 'POST',
    data: params,
  },
  meta: params,
  notifyError: false,
})


export const OAUTH_REFRESH_SSO = 'OAUTH_REFRESH_SSO'

export const oAuthRefreshSso = (
  params: Pick<TokenRefreshParams, 'refresh_token'>
): AsyncReduxAction => createAsyncUserAction({
  type: OAUTH_REFRESH_SSO,
  resource: '/auth/sso/refresh',
  options: {
    method: 'POST',
    data: params,
  },
  meta: params,
  notifyError: false,
})


export const LOAD_MY_USER = 'LOAD_MY_USER'

export const loadMyUser = (): AsyncReduxAction => createAsyncUserAction({
  type: LOAD_MY_USER,
  resource: '/users/me',
  options: {
    params: {
      include_descendent_orgs: true,
      // NOTE - for now we include any of MQD or MI privileges as a filter.
      // These are the only apps where we use the orgs returned in this
      // response to derive options to show to the user. Really this needs
      // to be done by each view, and also these should be independent apps.
      system_privilege_filter: [
        'structuralReporting.report.view',
        'performanceOverview.performance.view',
        'mediaInvestment.competitive.export',
        'mediaInvestment.competitive.view',
        'mediaInvestment.investment.atl.export',
        'mediaInvestment.investment.atl.view',
        'mediaInvestment.investment.digital.export',
        'mediaInvestment.investment.digital.view',
        'mediaInvestment.manageData.atl.edit',
        'mediaInvestment.manageData.atl.view',
        'mediaInvestment.manageData.competitive.edit',
        'mediaInvestment.manageData.competitive.view',
        'mediaInvestment.manageData.digital.edit',
        'mediaInvestment.manageData.digital.view',
        'mediaInvestment.manageData.submit',
        'mediaInvestment.submissionTracker.view',
      ] as OrgPrivilege[]
    },
  },
})


export const LOAD_MY_USER_DETAIL = 'LOAD_MY_USER_DETAIL'

export const loadMyUserDetail = (): AsyncReduxAction => createAsyncUserAction({
  type: LOAD_MY_USER_DETAIL,
  resource: '/users/me/detail',
})


export const LOAD_MY_USER_PRIVILEGES = 'LOAD_MY_USER_PRIVILEGES'

export const loadMyUserPrivileges = (): AsyncReduxAction => createAsyncUserAction({
  type: LOAD_MY_USER_PRIVILEGES,
  resource: '/users/me/privileges',
})


export const BEGIN_SOFTWARE_MFA_SETUP = 'BEGIN_SOFTWARE_MFA_SETUP'

export const beginSoftwareMfaSetup = (data: AssociateSoftwareTokenParams): AsyncReduxAction => createAsyncUserAction({
  type: BEGIN_SOFTWARE_MFA_SETUP,
  resource: '/auth/associate-software-token',
  options: {
    method: 'POST',
    data,
  },
  meta: data,
})

export const RESET_BEGIN_SOFTWARE_MFA_SETUP = 'RESET_BEGIN_SOFTWARE_MFA_SETUP'

export const resetBeginSoftwareMfaSetup = (): ReduxAction => ({ type: RESET_BEGIN_SOFTWARE_MFA_SETUP })


export const COMPLETE_SOFTWARE_MFA_SETUP = 'COMPLETE_SOFTWARE_MFA_SETUP'

export const completeSoftwareMfaSetup = (data: VerifySoftwareTokenParams): AsyncReduxAction => createAsyncUserAction({
  type: COMPLETE_SOFTWARE_MFA_SETUP,
  resource: '/auth/verify-software-token',
  options: {
    method: 'POST',
    data,
  },
  meta: data,
  successActions: [
    loadMyUserDetail(),
  ],
})

export const RESET_COMPLETE_SOFTWARE_MFA_SETUP = 'RESET_COMPLETE_SOFTWARE_MFA_SETUP'

export const resetCompleteSoftwareMfaSetup = (): ReduxAction => ({ type: RESET_COMPLETE_SOFTWARE_MFA_SETUP })


export const TOGGLE_SOFTWARE_MFA_STATE = 'TOGGLE_SOFTWARE_MFA_STATE'

export const toggleSoftwareMfaState = (data: SoftwareMFAStateParams): AsyncReduxAction => createAsyncUserAction({
  type: TOGGLE_SOFTWARE_MFA_STATE,
  resource: '/auth/software-mfa-state',
  options: {
    method: 'POST',
    data,
  },
  meta: data,
  successActions: [
    loadMyUserDetail(),
    addNotification({
      type: 'success',
      message: `Two Factor Authentication ${data.enabled ? 'enabled' : 'disabled'}`,
    }),
  ],
})

export const RESET_TOGGLE_SOFTWARE_MFA_STATE = 'RESET_TOGGLE_SOFTWARE_MFA_STATE'

export const resetToggleSoftwareMfaState = (): ReduxAction => ({ type: RESET_TOGGLE_SOFTWARE_MFA_STATE })


export const RESET_MFA_SETUP = 'RESET_MFA_SETUP'

export const resetMfaSetup = (): ReduxAction => ({ type: RESET_MFA_SETUP })


export const RESPOND_TO_CODE_CHALLENGE = 'RESPOND_TO_CODE_CHALLENGE'

export const respondToCodeChallenge = (data: CodeChallengeParams): AsyncReduxAction => createAsyncUserAction({
  type: RESPOND_TO_CODE_CHALLENGE,
  resource: '/auth/code-challenge-response',
  options: {
    method: 'POST',
    data,
  },
  meta: data,
  notifyError: false,
})
