import { Context, createContext, useCallback, useEffect, useMemo, useRef } from 'react'

import { useLocation } from 'react-router'

import qs from 'query-string'

import { intersection, isFunction, pick } from 'lodash-es'

import { useNavigation } from './reduxHooks'

import { Dictionary } from '@percept/types'


type UrlStateAction<T extends object> = (
  Partial<T> | ((prev: T) => T)
)

type UrlStateUpdateOptions = {
  replace?: boolean
  keepExistingParams?: boolean
}

type UrlStateUpdater<T extends object> = (update: UrlStateAction<T>, options?: UrlStateUpdateOptions) => void


const defaultQSParseOptions: qs.ParseOptions = {
  arrayFormat: 'bracket',
  parseBooleans: true,
}

const defaultQSStringifyOptions: qs.StringifyOptions = {
  arrayFormat: 'bracket',
}

export const parseUrlSearchParams = <T extends object>(search: string): T => {
  return qs.parse(search, defaultQSParseOptions) as T
}

export const stringifyUrlSearchParams = (params: Dictionary): string => {
  return qs.stringify(params, defaultQSStringifyOptions)
} 

const defaultStateUpdateOptions: UrlStateUpdateOptions = {
  replace: true,
  keepExistingParams: true,
}

export const useUrlState = <T extends object>(
  defaultValues: T,
  initialReplaceOptions: UrlStateUpdateOptions = defaultStateUpdateOptions,
): [T, UrlStateUpdater<T>] => {

  const { search, pathname} = useLocation()

  const navigate = useNavigation()

  initialReplaceOptions = {
    ...defaultStateUpdateOptions,
    ...(initialReplaceOptions || {}),
  }

  const defaultValuesRef = useRef<T>(defaultValues)
  defaultValuesRef.current = defaultValues

  const currentParams = useMemo(() => {
    const parsedParams = parseUrlSearchParams<T>(search)
    const keyIntersection = intersection(
      Object.keys(defaultValuesRef.current),
      Object.keys(parsedParams),
    )
    return {
      ...defaultValuesRef.current,
      ...pick(parsedParams, keyIntersection),
    } as T
  }, [search])

  const isMountedRef = useRef(false)

  const { replace, keepExistingParams } = initialReplaceOptions

  useEffect(() => {
    if( !isMountedRef.current ){
      let paramsToMerge = {}
      if( keepExistingParams ){
        paramsToMerge = parseUrlSearchParams<T>(search)
      }
      const updatedSearchParams = { ...paramsToMerge, ...currentParams }
      navigate(
        pathname,
        {
          replace,
          search: '?' + stringifyUrlSearchParams(updatedSearchParams),
        })
      isMountedRef.current = true
    }
  }, [
    navigate,
    replace,
    keepExistingParams,
    pathname,
    search,
    currentParams,
  ])

  const updater: UrlStateUpdater<T> = useCallback(
    (arg, options = defaultStateUpdateOptions): void => {
      let next: Partial<T> | null = null
      if( isFunction(arg) ){
        next = arg(currentParams)
      }else{
        next = arg
      }
      options = {
        ...defaultStateUpdateOptions,
        ...(options || {}),
      }
      if( next !== null ){
        let paramsToMerge = currentParams
        if( options.keepExistingParams ){
          paramsToMerge = parseUrlSearchParams<T>(search)
        }
        const updatedSearchParams = { ...paramsToMerge, ...next }
        navigate(
          pathname,
          {
            replace: options.replace,
            search: '?' + stringifyUrlSearchParams(updatedSearchParams),
          }
        )
      }
    },
    [currentParams, pathname, search, navigate]
  )

  return [currentParams, updater]
}


export const makeUrlStateContext = <T extends object>(): Context<[T, UrlStateUpdater<T>]> => (
  createContext<[T, UrlStateUpdater<T>]>([] as unknown as [T, UrlStateUpdater<T>])
)
