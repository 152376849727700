import React, { useState } from 'react'

import {
  Box,
  Checkbox,
  Divider,
  Grid,
  MonthRangePicker,
  RoundedPlainTextButton,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { isoDate } from '@percept/utils'

import { isNullTimeRange } from './lib'


export type TimeComparisonValue = {
  baseline: [Date | null, Date | null]
  comparison: [Date | null, Date | null]
}


export type TimeComparisonFilterProps = {
  comparisonEnabled?: boolean
  minDate: Date
  maxDate: Date
  value: TimeComparisonValue
  onChange: (value: TimeComparisonValue) => void
}


const areDateListsEqual = (a: (Date | null)[], b: (Date | null)[]): boolean => (
  !a && !b ?
    true :
    !a || !b ?
      false : (
        a.map( d => d ? isoDate(d) : 'NONE').sort().join('')
        === b.map( d => d ? isoDate(d) : 'NONE').sort().join('')
      )
)

const useStyles = makeAppStyles( theme => ({
  grid: {
    width: 520,
    padding: theme.spacing(2),
  },
  dividerContainer: {
    padding: theme.spacing(2, 3),
  },
  slider: {
    margin: theme.spacing(0, 2),
  },
  buttonContainer: {
    textAlign: 'right',
  }
}))


export const TimeComparisonFilterInput = ({
  value,
  onChange,
  minDate,
  maxDate,
  comparisonEnabled = true,
  children,
}: React.PropsWithChildren<TimeComparisonFilterProps>): JSX.Element => {

  const [comparisonActive, setComparisonActive] = useState(
    comparisonEnabled && !isNullTimeRange(value.comparison)
  )
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.grid}>
      <Grid item xs={12}>
        <Typography variant='h5'>
          { comparisonEnabled ? 'Baseline' : 'Time Range' }
        </Typography>
        <MonthRangePicker
          variant='financial'
          minDate={minDate}
          maxDate={maxDate}
          value={value.baseline}
          onChange={(baseline): void => {
            onChange({
              ...value,
              baseline,
            })
          }} />
      </Grid>
      { comparisonEnabled && (
        <>
        <Box maxWidth='100%' flexBasis='100%' mt={1} px={1}>
          <Divider />
        </Box>
          <Grid item xs={12}>
            <Typography style={{cursor: 'pointer'}} variant='h5' onClick={(): void => {
              if( comparisonActive ){
                setComparisonActive(false)
                onChange({
                  ...value,
                  comparison: [null, null],
                })
              }else{
                setComparisonActive(true)
              }
            }}>
              Comparison
              <Checkbox
                size='small'
                checked={comparisonActive} />
            </Typography>
            { comparisonActive && (
              <MonthRangePicker
                variant='financial'
                minDate={minDate}
                maxDate={maxDate}
                value={value.comparison}
                onChange={(comparison): void => {
                  onChange({
                    ...value,
                    comparison,
                  })
                }} />
            )}
          </Grid>
        </>
      )}
      { children }
    </Grid>
  )
}


export const TimeComparisonFilter = ({
  value,
  onChange,
  comparisonEnabled = true,
  ...props
}: TimeComparisonFilterProps): JSX.Element => {

  const [localValue, setLocalValue] = useState(value)

  const areFiltersApplied = (
    areDateListsEqual(value.baseline, localValue.baseline)
    && (
      !comparisonEnabled ? true : areDateListsEqual(value.comparison, localValue.comparison)
    )
  )

  const classes = useStyles()

  return (
    <TimeComparisonFilterInput
      value={localValue}
      onChange={setLocalValue}
      {...props}>
      <Box maxWidth='100%' flexBasis='100%' mt={1} px={1}>
        <Divider />
      </Box>
      <Grid item xs={12} className={classes.buttonContainer}>
        <RoundedPlainTextButton
          variant='contained'
          color='secondary'
          size='small'
          disabled={areFiltersApplied}
          onClick={(): void => {
            onChange(localValue)
          }}>
          { areFiltersApplied ? 'Filters Applied' : 'Apply Filters'}
        </RoundedPlainTextButton>
      </Grid>
    </TimeComparisonFilterInput>
  )
}
