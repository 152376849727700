import React, { useContext, useEffect, useMemo, useRef } from 'react'

import {
  Box,
  Card,
  CheckboxGroup,
  FilterPanel,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Loader,
  MonthRangePicker,
  PercentageDonut,
  ProviderLogo,
  Radio,
  RadioGroup,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown, CloudDownload, ErrorOutline } from '@percept/mui/icons'

import { UserPrivilegeContext } from '@percept/app-components'

import {
  WastageComparisonType,
  WastageParams,
  WastageProvider,
  WastageSegmentation,
  WastageVariant,
  useCombinedWastage,
  useCreativeWastage,
  useDigitalWastage,
  useTotalCreativeWastage,
  useUrlState,
  useWastageReferenceDates,
  useWastageTrendExport,
} from '@percept/hooks'

import {
  wastageSegmentationDisplayLabels,
  wastageSegmentationValueOrder,
  WastageTrendTabs,
} from './WastageTrendComponents'

import { MarketDisplayLabel } from 'components/MarketDisplay'

import { ParentSize } from '@visx/responsive'

import { WastageTrendChart } from './WastageTrendChart'

import { WastageTrendTable } from './WastageTrendTable'

import {
  ComparisonMethod,
  deslugify,
  downloadSVG,
  getFinancialYearStart,
  getPercentage,
  isoDate,
  userHasOrgPrivileges,
} from '@percept/utils'

import { getDatasetTotal } from '@percept/mui/charts/lib'

import {
  datasetColorSchemesMapping,
  deriveRequiredComparisonPeriod,
  formatMonth,
  getAllowableDateRange,
  getCreativeQualityScoreDataset,
  getDerivedTotalDataset,
  getSpendDatasets,
  getWastagePercentageDataset,
  providerOrder,
  supportedComparisonTypeMapping,
  vodafoneMarketsById,
} from './dataUtils'

import { VODAFONE_GLOBAL_ID, VodafoneMarket, vodafoneMarkets } from 'vodafoneMarkets'

import { get, intersection, pick, some } from 'lodash-es'

import { endOfMonth, startOfMonth } from 'date-fns'

import { UseQueryResult } from 'react-query'

import { SpendDataset } from './typings'

const { WORKLOAD_TYPE } = process.env

const useStyles = makeAppStyles( theme => ({
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: 16,
    lineHeight: 1.35,
    maxWidth: '71em',
  },
  tabs: {
    margin: theme.spacing(4,0),
    borderBottom: `1px solid ${theme.palette.text.hint}`,
  },
  tab: {
    textTransform: 'none',
  },
  spacedRight: {
    marginRight: theme.spacing(2),
  },
  derivedTotalDatasetText: {
    transition: theme.transitions.create('color'),
  },
  disabledText: {
    color: theme.palette.text.disabled,
  },
  filterCard: {
    margin: theme.spacing(3, 1, 0, 1),
    padding: theme.spacing(2),
  },
  filterGroup: {
    minWidth: 'max-content',
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  monthRangeInput: {
    marginRight: theme.spacing(2),
  },
  filterDivider: {
    margin: theme.spacing(0, 4),
  },
  separator: {
    margin: theme.spacing(0, 0.75),
    color: theme.palette.text.disabled,
  },
  filterItem: {
    marginRight: theme.spacing(1.5),
    ':last-child': {
      marginRight: 0,
    },
  },
}))


const providerOptionsByWastageVariant: Record<WastageVariant, WastageProvider[]> = {
  COMBINED: [
    'GOOGLE_ADS', 'FACEBOOK', 'DV360', 'ADFORM'
  ],
  DIGITAL: [
    'GOOGLE_ADS', 'FACEBOOK', 'DV360', 'ADFORM'
  ],
  CREATIVE: [
    'GOOGLE_ADS', 'FACEBOOK', 'DV360', 'ADFORM', 'TIKTOK'
  ]
}


const variantDescriptions: Record<WastageVariant, React.ReactNode> = {
  COMBINED: <>
    Ad investment lost due to not following media and creative best practices. It's taken from the
    Media Quality Dashboard and CreativeX, which identify where media and creative best practices
    are not followed on Meta, GoogleAds, Youtube, DV360, TikTok and Adform, leading to wastage.
    Platform algorithms will deprioritise ads that don't follow best practices, which has a direct impact on cost.
  </>,
  DIGITAL: <>
    Ad investment lost by not following media-specific best practices. Platform algorithms will deprioritise ads
    that don't follow best practices in structure, formats, audience and brand safety, which has a direct impact on cost.
    This is calculated via the Media Quality Dashboard (Percept), assessing each best practice's efficiency impact separately.
  </>,
  CREATIVE: <>
    Ad investment lost by not following creative-specific best practices. Platform algorithms will deprioritise ads that
    don't follow best practices such as simple message, correct length, aspect ratio, sound on/off, amongst others which
    has a direct impact on cost. This is calculated via CreativeX, whereby for each 10 pp Creative Quality Score (CQS) below
    our Vodafone goal of 80%, CPM increases 2%, resulting in wastage from increased costs. This is backed by robust research
    from CreativeX across their advertisers and confirmed by a regression analysis with Vodafone market data.
  </>,
}


const isQueryHookLoading = (hookValue: UseQueryResult): boolean => (
  hookValue.isLoading || (!hookValue.data && !hookValue.isError)
)

const isQueryHookError = (hookValue: UseQueryResult): boolean => (
  !hookValue.data && hookValue.isError
)


type WastageTrendReportState = {
  variant: WastageVariant
  segmentation: WastageSegmentation
  providers: WastageProvider[]
  cqs: boolean
  percentage: boolean
  spend: boolean
  orgUnitIds: string[]
  start: string | null
  end: string | null
  comparison: WastageComparisonType | null
}

type WastageTrendReportFilters = (
  Pick<
    WastageTrendReportState,
    'segmentation' | 'providers' | 'comparison' | 'orgUnitIds'
  > & {
    timeRange: {
      start: Date | null
      end: Date | null 
    }
    viewConfig: {
      cqs: boolean
      percentage: boolean
      spend: boolean
    }
  }
)

const viewConfigDisplayLabelMap: Record<keyof WastageTrendReportFilters['viewConfig'], string> = {
  cqs: 'Creative Quality Score',
  percentage: 'Percentage',
  spend: 'Spend',
}

export const WastageTrendReports = (): JSX.Element => {

  const privileges = useContext(UserPrivilegeContext)

  const org_unit_id = VODAFONE_GLOBAL_ID

  const defaultOrgUnitId = privileges.user && privileges.user.default_org_unit_id

  const wastageReferenceDates = useWastageReferenceDates({
    org_unit_id,
  })

  const { minDate, maxDate } = getAllowableDateRange({
    minDate: get(wastageReferenceDates.data, 'start', null),
    maxDate: get(wastageReferenceDates.data, 'end', null),
  })

  const [state, setState] = useUrlState<WastageTrendReportState>({
    variant: 'COMBINED',
    segmentation: 'PROVIDER',
    comparison: null,
    providers: providerOptionsByWastageVariant.COMBINED,
    cqs: true,
    percentage: true,
    spend: true,
    orgUnitIds: (
      defaultOrgUnitId === VODAFONE_GLOBAL_ID ?
        vodafoneMarkets.map( m => m.id ) :
        [defaultOrgUnitId]
    ),
    start: (
      WORKLOAD_TYPE === 'DEV' ?
        '2023-04-01' :
        wastageReferenceDates.data ?
          isoDate(getFinancialYearStart(maxDate)) :
          null
    ),
    end: (
      WORKLOAD_TYPE === 'DEV' ?
        '2023-06-30' :
        wastageReferenceDates.data ?
          isoDate(maxDate) :
          null
    ),
  })

  const {
    variant,
    segmentation,
    comparison,
    providers,
    percentage,
    cqs,
    spend,
    orgUnitIds,
  } = state

  useEffect(() => {
    if( state.start === null && state.end === null && wastageReferenceDates.data ){
      const { maxDate } = getAllowableDateRange({
        minDate: wastageReferenceDates.data.start,
        maxDate: wastageReferenceDates.data.end,
      })
      setState({
        start: isoDate(getFinancialYearStart(maxDate)),
        end: isoDate(maxDate),
      })
    }
  }, [wastageReferenceDates.data, state.start, state.end, setState])

  useEffect(() => {
    const options = providerOptionsByWastageVariant[variant]
    if( intersection(providers, options).length < providers.length ){
      console.warn('Invalid options')
      setState({ providers: options })
    }
  }, [variant, providers, setState])

  const userCanExport = userHasOrgPrivileges(
    ['vfWastageTrendReports.export'],
    VODAFONE_GLOBAL_ID,
    privileges.org_privileges,
  )

  const csvExportHook = useWastageTrendExport()

  const marketOptions: VodafoneMarket[] = vodafoneMarkets.filter( m => (
    userHasOrgPrivileges(['structuralReporting.report.view'], m.id, privileges.org_privileges)
  ))

  const dateRange: [Date | null, Date | null] = [
    state.start ? new Date(state.start) : null,
    state.end ? new Date(state.end) : null,
  ]

  const filterState: WastageTrendReportFilters = {
    ...(pick(state, ['segmentation', 'providers'])),
    timeRange: {
      start: dateRange[0],
      end: dateRange[1],
    },
    providers,
    comparison,
    orgUnitIds,
    viewConfig: {
      cqs,
      percentage,
      spend,
    }
  }

  const comparisonDateRange = deriveRequiredComparisonPeriod(
    dateRange,
    state.comparison,
    segmentation,
  )

  const baseWastageHookProps: Omit<WastageParams, 'start' | 'end'> = {
    org_unit_id,
    segment_type: segmentation,
    org_unit_ids: orgUnitIds,
    providers: providers,
  }

  const wastageHookProps: WastageParams = {
    ...baseWastageHookProps,
    start: dateRange[0],
    end: dateRange[1],
  }

  const digitalWastageHook = useDigitalWastage({
    ...wastageHookProps,
    enabled: variant === 'DIGITAL',
  })

  const combinedWastageHook = useCombinedWastage({
    ...wastageHookProps,
    enabled: variant === 'COMBINED',
  })

  const creativeWastageHook = useCreativeWastage({
    ...wastageHookProps,
    enabled: true,
  })

  const totalCreativeWastageHook = useTotalCreativeWastage({
    ...wastageHookProps,
    enabled: true,
  })

  const wastageComparisonHookProps: WastageParams = {
    ...baseWastageHookProps,
    start: comparisonDateRange[0],
    end: comparisonDateRange[1],
  }

  const digitalWastageComparisonHook = useDigitalWastage({
    ...wastageComparisonHookProps,
    enabled: variant === 'DIGITAL',
  })

  const combinedWastageComparisonHook = useCombinedWastage({
    ...wastageComparisonHookProps,
    enabled: variant === 'COMBINED',
  })

  const creativeWastageComparisonHook = useCreativeWastage({
    ...wastageComparisonHookProps,
    enabled: true,
  })

  const totalCreativeWastageComparisonHook = useTotalCreativeWastage({
    ...wastageComparisonHookProps,
    enabled: true,
  })

  // useEffect(() => {
  //   const supportedComparisonTypes = supportedComparisonTypeMapping[segmentation]
  //   if( state.comparison && supportedComparisonTypes && !supportedComparisonTypes.includes(state.comparison) ){
  //     setComparisonType(supportedComparisonTypes[0])
  //   }
  // }, [state.comparison, segmentation])

  const supportedComparisonTypes = supportedComparisonTypeMapping[segmentation] || []

  const supportsComparison = !!supportedComparisonTypes.length

  const {
    spendDatasets,
    // derivedTotalDataset,
    creativeQualityScoreDataset,
    wastagePercentageDataset,
    derivedPercentageSplit,
    ...comparisonDatasets
  } = useMemo(() => {
    const spendDatasets = getSpendDatasets({
      variant: variant,
      segmentation: segmentation,
      combinedWastage: combinedWastageHook.data,
      digitalWastage: digitalWastageHook.data,
      creativeWastage: creativeWastageHook.data,
    })

    const efficientSpendMultiDataset = spendDatasets.find( d => d.key === 'efficientSpend') || null
    const wastedSpendMultiDataset = spendDatasets.find( d => d.key === 'wastedSpend') || null

    const efficientSpendDataset = get(efficientSpendMultiDataset, 'data', null)
    const wastedSpendDataset = get(wastedSpendMultiDataset, 'data', null)

    const derivedTotalDataset = getDerivedTotalDataset({
      efficientSpendDataset: efficientSpendMultiDataset && efficientSpendMultiDataset.data,
      wastedSpendDataset: wastedSpendMultiDataset && wastedSpendMultiDataset.data,
      variant: variant,
    })

    const totalValues: Record<SpendDataset, number | null> = {
      efficientSpend: (
        efficientSpendDataset && getDatasetTotal(efficientSpendDataset)
      ),
      wastedSpend: (
        wastedSpendDataset && getDatasetTotal(wastedSpendDataset)
      ),
    }

    let derivedPercentageSplit: Record<SpendDataset, number> | null = null
    if( totalValues.efficientSpend !== null && totalValues.wastedSpend !== null ){
      const overallTotal = totalValues.efficientSpend + totalValues.wastedSpend
      derivedPercentageSplit = {
        efficientSpend: getPercentage(totalValues.efficientSpend, overallTotal),
        wastedSpend: getPercentage(totalValues.wastedSpend, overallTotal)
      }
    }

    const creativeQualityScoreDataset = getCreativeQualityScoreDataset(
      creativeWastageHook.data,
      segmentation,
    )

    const wastagePercentageDataset = getWastagePercentageDataset({
      variant: variant,
      segmentation: segmentation,
      combinedWastage: combinedWastageHook.data,
      digitalWastage: digitalWastageHook.data,
      creativeWastage: creativeWastageHook.data,
    })

    const spendComparisonDatasets = getSpendDatasets({
      variant: variant,
      segmentation: segmentation,
      combinedWastage: combinedWastageComparisonHook.data,
      digitalWastage: digitalWastageComparisonHook.data,
      creativeWastage: creativeWastageComparisonHook.data,
    })

    const creativeQualityScoreComparisonDataset = getCreativeQualityScoreDataset(
      creativeWastageComparisonHook.data,
      segmentation,
    )

    const wastagePercentageComparisonDataset = getWastagePercentageDataset({
      variant: variant,
      segmentation: segmentation,
      combinedWastage: combinedWastageComparisonHook.data,
      digitalWastage: digitalWastageComparisonHook.data,
      creativeWastage: creativeWastageComparisonHook.data,
    })

    return {
      spendDatasets,
      derivedTotalDataset,
      creativeQualityScoreDataset,
      wastagePercentageDataset,
      // Comparisons
      spendComparisonDatasets,
      creativeQualityScoreComparisonDataset,
      wastagePercentageComparisonDataset,
      // Derived split
      derivedPercentageSplit,
    }
  }, [
    variant, segmentation,
    combinedWastageHook.data, digitalWastageHook.data, creativeWastageHook.data,
    combinedWastageComparisonHook.data, digitalWastageComparisonHook.data,
    creativeWastageComparisonHook.data,
  ])

  const variantHookValues: Record<WastageVariant, UseQueryResult> = {
    COMBINED: combinedWastageHook,
    DIGITAL: digitalWastageHook,
    CREATIVE: creativeWastageHook,
  }

  const variantComparisonHookValues: Record<WastageVariant, UseQueryResult> = {
    COMBINED: combinedWastageComparisonHook,
    DIGITAL: digitalWastageComparisonHook,
    CREATIVE: creativeWastageComparisonHook,
  }

  const enabledHookConfigs: [boolean, UseQueryResult][] = [
    [cqs, creativeWastageHook],
    [spend, variantHookValues[variant]],
    [percentage, variantHookValues[variant]]
  ]

  const enabledComparisonHookConfigs: [boolean, UseQueryResult][] = [
    [cqs, creativeWastageComparisonHook],
    [spend, variantComparisonHookValues[variant]],
    [percentage, variantComparisonHookValues[variant]]
  ]

  const isLoading = some(enabledHookConfigs, ([required, hookValue]) => (
    required && isQueryHookLoading(hookValue)
  ))

  const isLoadingComparisons = supportsComparison && some(enabledComparisonHookConfigs, ([required, hookValue]) => (
    required && isQueryHookLoading(hookValue)
  ))

  const isError = some(enabledHookConfigs, ([required, hookValue]) => (
    required && isQueryHookError(hookValue)
  ))

  const isComparisonError = some(enabledComparisonHookConfigs, ([required, hookValue]) => (
    required && isQueryHookError(hookValue)
  ))

  const isLoadingTable = isLoading || totalCreativeWastageHook.isLoading

  const isLoadingTableComparisons = supportsComparison && (
    isLoadingComparisons || totalCreativeWastageComparisonHook.isLoading
  )

  const classes = useStyles()

  const datasetColorScheme = datasetColorSchemesMapping[variant]

  const svgRef = useRef<SVGSVGElement | null>(null)

  return (
    <Box p={3}>
      <Typography variant='h3' className={classes.title}>
        Wastage Trend Reports
      </Typography>

      <WastageTrendTabs
        value={variant}
        onChange={(variant): void => setState({ variant })} />

      <Typography className={classes.text}>
        { variantDescriptions[variant] }
      </Typography>

      <Card className={classes.filterCard}>
        <FilterPanel
          name='Filters'
          values={filterState}
          onConfirm={(filterValues): void => {
            const update: Partial<WastageTrendReportState> = {
              start: filterValues.timeRange.start && isoDate(filterValues.timeRange.start),
              end: filterValues.timeRange.end && isoDate(filterValues.timeRange.end),
              providers: intersection(providerOrder, filterValues.providers),
              segmentation: filterValues.segmentation,
              orgUnitIds: filterValues.orgUnitIds,
              cqs: filterValues.viewConfig.cqs,
              percentage: filterValues.viewConfig.percentage,
              spend: filterValues.viewConfig.spend,
            }
            const supportedComparisonTypes = supportedComparisonTypeMapping[segmentation]
            if( state.comparison && supportedComparisonTypes && !supportedComparisonTypes.includes(state.comparison) ){
              update.comparison = supportedComparisonTypes[0]
            }else if( !supportedComparisonTypes ){
              update.comparison = null
            }
            setState(update)
          }}
          valueOrder={
            [
              'timeRange',
              'segmentation',
              'providers',
              'orgUnitIds',
              'viewConfig',
            ]
          }
          displayConfig={{
          /* eslint-disable react/display-name, react/prop-types */
            timeRange: {
              label: 'Time Range',
              nullable: false,
              render: (value): string | null => (
                value.start && value.end ?
                  `${formatMonth(value.start)} to ${formatMonth(value.end)}` :
                  null
              ),
            },
            segmentation: {
              label: 'Slice By',
              nullable: false,
              render: (value): string => wastageSegmentationDisplayLabels[value],
            },
            providers: {
              label: 'Vendor',
              render: (value): JSX.Element => (
                <ProviderLogo size={1.25} provider={value} style={{position: 'relative', top: 3}} />
              ),
            },
            orgUnitIds: {
              label: 'Markets',
              render: (value): JSX.Element => (
                <MarketDisplayLabel {...vodafoneMarketsById[value]} />
              ),
            },
            viewConfig: {
              label: 'Overlay',
              nullable: false,
              render: (value): JSX.Element => {
                const shownValues = (
                Object.keys(value) as (keyof WastageTrendReportFilters['viewConfig'])[]
                ).filter( k => !!value[k] ).sort()
                return (
                <>
                  { shownValues.map( (v, i) => (
                    <>
                      {viewConfigDisplayLabelMap[v]}
                      { i < (shownValues.length - 1) && (
                        <span className={classes.separator}>|</span>
                      )}
                    </>
                  ))}
                </>
                )
              },
            }
          /* eslint-enable react/display-name, react/prop-types */
          }}>
          { ({ values, updateValues }): JSX.Element => (
            <Grid container spacing={4}>
              <Grid item xs='auto' className={classes.monthRangeInput}>
                <FormLabel>
                Time Range
                </FormLabel>
                <MonthRangePicker
                  minDate={minDate}
                  maxDate={maxDate}
                  value={[values.timeRange.start, values.timeRange.end]}
                  onChange={(value): void => {
                    updateValues({
                      timeRange: {
                        start: value[0] && startOfMonth(value[0]),
                        end: value[1] && endOfMonth(value[1]),
                      }
                    })
                  }} />
              </Grid>

              <Grid item xs='auto'>
                <FormControl component='fieldset'>
                  <FormLabel color='secondary' htmlFor='segmentation' component='legend'>Slice By</FormLabel>
                  <RadioGroup
                    name='segmentation'
                    value={values.segmentation}
                    onChange={(_, value): void => {
                      updateValues({ segmentation: value as WastageSegmentation })
                    }}>
                    { wastageSegmentationValueOrder.map( value => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<Radio />}
                        label={wastageSegmentationDisplayLabels[value]} />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs='auto'>
                <CheckboxGroup
                  name='Markets'
                  value={values.orgUnitIds}
                  options={
                    marketOptions.map( option => ({
                      value: option.id,
                      label: <MarketDisplayLabel {...option} />,
                    }))
                  }
                  onChange={(orgUnitIds): void => updateValues({ orgUnitIds })} />
              </Grid>

              <Grid item xs='auto'>
                <CheckboxGroup
                  name='Vendors'
                  value={values.providers}
                  options={
                    providerOptionsByWastageVariant[variant].map( provider => ({
                      value: provider,
                      label: <ProviderLogo size={1.35} provider={provider} style={{position: 'relative', top: 3}} />,
                    }))
                  }
                  onChange={(providers): void => updateValues({ providers })} />
              </Grid>

              <Grid item xs='auto'>
                <CheckboxGroup
                  name='Overlay'
                  value={
                    Object.entries(values.viewConfig).reduce( (acc, [k, v]) => {
                      if( v ){
                        acc.push(k)
                      }
                      return acc
                    }, [] as string[])
                  }
                  options={
                    (['cqs', 'percentage', 'spend'] as (keyof WastageTrendReportFilters['viewConfig'])[]).map( value => ({
                      value,
                      label: `Show ${viewConfigDisplayLabelMap[value]}`,
                    }))
                  }
                  onChange={(value): void => {
                    updateValues({
                      viewConfig: {
                        cqs: value.includes('cqs'),
                        percentage: value.includes('percentage'),
                        spend: value.includes('spend'),
                      },
                    })
                  }} />
              </Grid>
            </Grid>
          )}
        </FilterPanel>
      </Card>

      <Box display='flex' alignItems='flex-end' mt={4} px={1}>

        { derivedPercentageSplit && (
          <Box display='flex' alignItems='center' mr={1.5}>
            <Typography className={classes.spacedRight} variant='h5'>
              <span
                className={classes.derivedTotalDatasetText}
                style={{
                  color: datasetColorScheme.wastedSpend,
                  marginRight: 4,
                }}>
                Wastage
              </span>
              <span
                className={classes.disabledText}>
                vs.
              </span>
              <span
                className={classes.derivedTotalDatasetText}
                style={{
                  color: datasetColorScheme.efficientSpend,
                  marginLeft: 4,
                }}>
                Efficient Spend
              </span>
            </Typography>
            <PercentageDonut
              width={72}
              height={72}
              donutThickness={0.4}
              padAngle={0.035}
              fontSize={14}
              value={derivedPercentageSplit.wastedSpend}
              color={datasetColorScheme.wastedSpend}
              textColor={datasetColorScheme.wastedSpend}
              trackColor={datasetColorScheme.efficientSpend}
            />
          </Box>
        )}

        { !isError && !isLoading && userCanExport && (
          <Box marginLeft='auto'>
            <RoundedPlainTextButton
              size='small'
              color='secondary'
              variant='contained'
              startIcon={<CloudDownload />}
              onClick={(): void => {
                if( svgRef.current ){
                  downloadSVG(
                    svgRef.current,
                    'wastage-trend-reports.svg',
                    {
                      margin: {
                        top: 30,
                        bottom: 10,
                        left: 10,
                        right: 20,
                      },
                      attributes: {
                        overflow: 'visible',
                      }
                    }
                  ).then(console.log)
                }
              }}>
              Export Chart
            </RoundedPlainTextButton>
          </Box>
        )}
      </Box>

      <Box mt={2} p={1}>
        <Card elevation={4}>
          <Box mt={5} height={600}>
            { isError ? (
              <Box
                display='flex'
                height='100%'
                alignItems='center'
                alignSelf='center'
                justifyContent='center'
                fontSize={22}
                fontWeight={600}>
                <ErrorOutline
                  color='error'
                  style={{marginRight: 8}} />
                An error occurred and wastage trend reports could not be loaded
              </Box>
            ) : isLoading ? (
              <Loader preset='fullsize' />
            ) : (
              <ParentSize>
                { ({ width, height }): JSX.Element => (
                  <WastageTrendChart
                    svgRef={svgRef}
                    width={width}
                    height={height}
                    variant={variant}
                    segmentation={segmentation}
                    spendDatasets={spendDatasets}
                    wastagePercentageDataset={wastagePercentageDataset}
                    creativeQualityScoreDataset={creativeQualityScoreDataset}
                    showCreativeQualityScore={cqs}
                    showWastagePercentage={percentage}
                    showWastageSpend={spend} />
                )}
              </ParentSize>
            )}
          </Box>
        </Card>
      </Box>

      { !isError && !isLoading && (
        <>
          <Box mt={6} px={1} display='flex' alignItems='center'>
            <Typography variant='h5' className={classes.spacedRight}>
              Comparison Type
            </Typography>

            <RoundedPlainTextButtonMenu
              TriggerProps={{
                variant: 'contained',
                size: 'small',
                color: 'secondary',
                endIcon: supportsComparison && <ArrowDropDown />,
              }}
              value={state.comparison}
              label={
                supportsComparison && state.comparison && deslugify(state.comparison) || (
                  !supportsComparison ?
                    'N / A' :
                    'Select Type'
                )
              }
              disabled={!supportsComparison}
              options={
                supportedComparisonTypes.map( value => ({
                  value,
                  label: deslugify(value)
                }))
              }
              onChange={(e, comparison: WastageComparisonType): void => {
                setState({ comparison })
              }} />

            { isComparisonError && (
              <Box
                display='flex'
                alignItems='center'
                alignSelf='center'
                justifyContent='center'
                ml={3}
                fontSize={14}
                fontWeight={600}>
                <ErrorOutline
                  color='error'
                  style={{marginRight: 4}} />
                An error occurred and comparisons could not be loaded
              </Box>
            )}

            { userCanExport && (
              <Box marginLeft='auto'>
                <RoundedPlainTextButton
                  size='small'
                  color='secondary'
                  variant='contained'
                  disabled={csvExportHook.isLoading}
                  startIcon={
                    csvExportHook.isLoading ?
                      <Loader size='1em' color='inherit' /> :
                      <CloudDownload />
                  }
                  onClick={(): void => {
                    csvExportHook.mutate({
                      org_unit_id,
                      variant,
                      segment_type: segmentation,
                      start: dateRange[0],
                      end: dateRange[1],
                      comparison_type: state.comparison,
                      org_unit_ids: orgUnitIds,
                      providers,
                    })
                  }}>
                  Export CSV
                </RoundedPlainTextButton>
              </Box>
            )}
          </Box>

          <Box mt={2} p={1}>
            <Card elevation={4}>
              { isLoadingTable ? (
                <Loader minHeight='10rem' preset='fullsize' />
              ) : (
                <WastageTrendTable
                  variant={variant}
                  segmentation={segmentation}
                  spendDatasets={spendDatasets}
                  wastagePercentageDataset={wastagePercentageDataset}
                  creativeQualityScoreDataset={creativeQualityScoreDataset}
                  supportsComparison={supportsComparison}
                  comparisonType={state.comparison}
                  isLoadingComparisons={isLoadingTableComparisons}
                  totalCreativeWastageResponse={totalCreativeWastageHook.data}
                  totalCreativeWastageComparisonResponse={totalCreativeWastageComparisonHook.data}
                  {...comparisonDatasets} />
              )}
            </Card>
          </Box>
        </>
      )}

    </Box>
  )
}
