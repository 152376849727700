import { discoverPerformanceProviders } from 'components/Organisation'

import { reportProviderV2Map } from '@percept/constants'

import { PlatformUnit, PlatformUnitProviderInfo } from '@percept/types'
import { PerformanceReportProvider, PerformanceReportState } from './typings'
import { coerceReportProvider, isoDate } from '@percept/utils'
import { maxBy, minBy } from 'lodash-es'
import { isAfter, isBefore } from 'date-fns'


export const minDatesByProvider: Record<PerformanceReportProvider, Date> = {
  GOOGLE_ADS: new Date('2019-01-01'),
  FACEBOOK: new Date('2019-01-01'),
  ADFORM: new Date('2018-01-01'),
  DV360: new Date('2021-10-06'),
  AMAZON_ADS: new Date('2023-08-20'),
  TIKTOK: new Date('2021-01-01'),
}


const getDateCompareValue = (d: Date): number => d.getTime()

type PerformanceReportDates = {
  startDate: Date
  endDate: Date
  minDate: Date
  maxDate: Date
}

export const getPerformanceReportDates = ({
  providerInfo,
  providers,
  dateRange,
}: {
  providerInfo: PlatformUnitProviderInfo
  providers: PerformanceReportProvider[]
  dateRange?: [Date, Date]
}): PerformanceReportDates => {
  dateRange = dateRange || [new Date(), new Date()]
  const [start, end] = dateRange
  const providerMaxDates = providers.map( provider => providerInfo[coerceReportProvider(provider)].reference_date )
  const providerMinDates = providers.map( provider => minDatesByProvider[provider] )

  let maxDate = minBy(providerMaxDates, getDateCompareValue) as Date
  const minDate = maxBy(providerMinDates, getDateCompareValue) as Date
  maxDate = maxBy([maxDate, minDate]) as Date

  const endDate = (
    isBefore(end, minDate) ?
      minDate :
      isAfter(end, maxDate) ?
        maxDate :
        end
  )

  const startDate = (
    isAfter(start, endDate) ?
      endDate :
      isBefore(start, minDate) ?
        minDate :
        start
  )

  return {
    startDate,
    endDate,
    minDate,
    maxDate,
  }
}

export const getPerformanceReportProviders = (
  platformUnit: PlatformUnit
): PerformanceReportProvider[] => (
  discoverPerformanceProviders(platformUnit).map(
    p => reportProviderV2Map[p]
  ) as PerformanceReportProvider[]
)


// export const getInitialPerformanceReportState = (
//   platformUnit: PlatformUnit,
//   providerInfo: PlatformUnitProviderInfo,
// ): PerformanceReportState => {
//   const providers = getPerformanceReportProviders(platformUnit)

//   const [start, end] = getAvailableDateRange({ providerInfo, providers })

//   return {
//     organisations: [platformUnit.id],
//     providers,
//     segmentation: undefined,
//     aggregationPeriod: undefined,
//     start: isoDate(start),
//     end: isoDate(end),
//     currency: 'EUR',
//   }
// }
