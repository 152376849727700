import { AxiosError } from 'axios'

import { UseMutationOptions, UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query'

import { makeClientQueryFn } from '@percept/hooks'

import { sponsorshipsApiClient } from './client'

import {
  CreateSponsorshipMarketingCPMsPayload,
  CreateSponsorshipsEstimatePayload,
  CreateSponsorshipsPrefillsPayload,
  SponsorshipsEstimate,
  SponsorshipsEstimateWithCalculation,
  SponsorshipsMarketingCPMs,
  SponsorshipsMarket,
  SponsorshipsPrefills,
} from '../typings'


export const useSponsorshipsMarkets = (): UseQueryResult<SponsorshipsMarket[]> => (
  useQuery({
    queryKey: ['sponsorshipsMarkets'],
    queryFn: makeClientQueryFn<{ data: SponsorshipsMarket[] }, SponsorshipsMarket[]>(
      sponsorshipsApiClient,
      { url: '/markets' },
      (response) => response.data.data
    ),
    refetchOnWindowFocus: false,
    retry: false,
  })
)


export const useSponsorshipsEstimate = (
  market_id: string, estimate_id: string | null
): UseQueryResult<SponsorshipsEstimateWithCalculation> => (
  useQuery({
    queryKey: ['sponsorshipsEstimate', estimate_id],
    queryFn: makeClientQueryFn<SponsorshipsEstimateWithCalculation>(
      sponsorshipsApiClient,
      {
        url: `/estimates/${estimate_id}`,
        params: { market_id },
      }
    ),
    enabled: !!estimate_id,
    refetchOnWindowFocus: false,
    retry: false,
  })
)


export const useSponsorshipsMarketEstimates = (
  market_id: string
): UseQueryResult<SponsorshipsEstimate[]> => (
  useQuery({
    queryKey: ['sponsorshipsMarketEstimates', market_id],
    queryFn: makeClientQueryFn<{ data: SponsorshipsEstimate[] }, SponsorshipsEstimate[]>(
      sponsorshipsApiClient,
      {
        url: '/estimates',
        params: { market_id },
      },
      response => response.data.data
    ),
    refetchOnWindowFocus: false,
    retry: false,
  })
)


export const useSponsorshipsMarketPrefills = (
  market_id: string
): UseQueryResult<SponsorshipsPrefills> => (
  useQuery({
    queryKey: ['sponsorshipsMarketPrefills', market_id],
    queryFn: makeClientQueryFn<SponsorshipsPrefills>(
      sponsorshipsApiClient,
      {
        url: '/prefills',
        params: { market_id },
      },
    ),
    refetchOnWindowFocus: false,
    retry: false,
  })
)


export const useSponsorshipsMarketingCPMs = (
  market_id: string,
  estimate_id?: string | null
): UseQueryResult<SponsorshipsMarketingCPMs> => (
  useQuery({
    queryKey: ['sponsorshipsMarketingCPMs'],
    queryFn: makeClientQueryFn<SponsorshipsMarketingCPMs>(
      sponsorshipsApiClient,
      {
        url: '/marketing-cpms',
        params: {
          market_id,
          ...(estimate_id && { estimate_id } || {}),
        },
      }
    ),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  })
)


export const useAddSponsorshipsEstimate = (
  market_id: string,
  options: UseMutationOptions<SponsorshipsEstimate, AxiosError, CreateSponsorshipsEstimatePayload> = {}
): UseMutationResult<SponsorshipsEstimate, AxiosError, CreateSponsorshipsEstimatePayload> => (
  useMutation({
    mutationKey: ['addSponsorshipsEstimate', market_id],
    mutationFn: async (payload: CreateSponsorshipsEstimatePayload) => {
      const response = await sponsorshipsApiClient.post<SponsorshipsEstimate>(
        '/estimates',
        payload,
        {
          params: { market_id }
        }
      )
      return response.data
    },
    ...options,
  })
)


export const useAddSponsorshipsPrefills = (
  market_id: string | null,
  options: UseMutationOptions<SponsorshipsPrefills, AxiosError, CreateSponsorshipsPrefillsPayload> = {}
): UseMutationResult<SponsorshipsPrefills, AxiosError, CreateSponsorshipsPrefillsPayload> => (
  useMutation({
    mutationKey: ['addSponsorshipsPrefills', market_id],
    mutationFn: async (payload: CreateSponsorshipsPrefillsPayload) => {
      const response = await sponsorshipsApiClient.post<SponsorshipsPrefills>(
        '/prefills',
        payload,
        {
          params: { market_id }
        }
      )
      return response.data
    },
    ...options,
  })
)


export const useAddSponsorshipsMarketingCPMs = (
  market_id: string | null,
  options: UseMutationOptions<SponsorshipsMarketingCPMs, AxiosError, CreateSponsorshipMarketingCPMsPayload> = {}
): UseMutationResult<SponsorshipsMarketingCPMs, AxiosError, CreateSponsorshipMarketingCPMsPayload> => (
  useMutation({
    mutationKey: ['addSponsorshipMarketingCPMs', market_id],
    mutationFn: async (payload: CreateSponsorshipMarketingCPMsPayload) => {
      const response = await sponsorshipsApiClient.post<SponsorshipsMarketingCPMs>(
        '/marketing-cpms',
        payload,
        {
          params: { market_id }
        }
      )
      return response.data
    },
    ...options,
  })
)
