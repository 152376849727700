import React from 'react'
import { useLocation, useParams } from 'react-router'
import { Box, Typography, Tab, Tabs, IconButton, Loader, makeAppStyles, PerceptAppBar, LinearProgress } from '@percept/mui'
import { Close } from '@percept/mui/icons'
import Api, { SpendingType } from 'api/services/Api'
import { useDocumentTitle, useNavigation } from '@percept/hooks'
import { SubmissionTrackerTable } from './SubmissionTrackerTable'
import { subMonths } from 'date-fns'
import { get, range } from 'lodash-es'
import { transformData } from './transformData'
import { UserFavouritesPopover } from '@percept/app-components'

// Show results for how many previous months
const NumberOfMonths = 6

// Create list of last N months for which we want to see reports 
const startOfThisMonth = new Date().setDate(1)
const validMonths = range(1, NumberOfMonths + 1).map( i => (
  subMonths(startOfThisMonth, i)
)).reverse()

const useClasses = makeAppStyles((theme) => ({
  tableContainer: {
    marginTop: '90px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  refetchIndicator: {
    position: 'absolute',
    top: 12,
    left: 0,
    width: '100%',
    height: 4
  },
  tabs:{
    marginTop: '10px',
    marginBottom: '-60px',
    backgroundColor: 'white',
  },
  field:{
    backgroundColor: 'red',
  },
  appBarInsert: {
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
  },
  exit:{
    marginLeft: 'auto',
  },
  header:{
    fontSize: 18,
  }
}))

const useTabClasses = makeAppStyles( theme => ({
  root: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  tab: {
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 700,
    minHeight: theme.spacing(6),
    color: 'white',
  },
  indicator: (
    theme.appComponents.appBarTabIndicator ?
      theme.appComponents.appBarTabIndicator.colorSecondary :
      {
        backgroundColor: theme.palette.secondary.main,
      }
  ),
}))


export type SubmissionTrackerSpendingTypePath = (
  'media-investment' | 'competitive-sos' | 'competitive-sov'
)

const submissionTrackerTabs: { value: SubmissionTrackerSpendingTypePath, label: string }[] = [
  {
    value: 'media-investment',
    label: 'Media Investment',
  },
  {
    value: 'competitive-sos',
    label: 'Competitive SOS',
  },
  {
    value: 'competitive-sov',
    label: 'Competitive SOV',
  },
]


const submissionTrackerSpendingTypeMap: Record<SubmissionTrackerSpendingTypePath, SpendingType> = {
  'media-investment': 'primary_spend',
  'competitive-sos': 'sos_spend',
  'competitive-sov': 'sov_spend',
}


const redirectPaths: Record<SubmissionTrackerSpendingTypePath, string> = {
  'media-investment': 'media-investment',
  'competitive-sos': 'competitive-investment/share-of-spend',
  'competitive-sov': 'competitive-investment/tv-share-of-voice',
}


export const SubmissionTracker = (): JSX.Element => {
  const classes = useClasses()
  const tabClasses = useTabClasses()
  const navigate = useNavigation()
  const { pathname } = useLocation()

  const routeParams = useParams<{ submission_tracker_type: SubmissionTrackerSpendingTypePath }>()

  const currentSubmissionTrackerTypePath = routeParams.submission_tracker_type as SubmissionTrackerSpendingTypePath

  const spendingType = submissionTrackerSpendingTypeMap[currentSubmissionTrackerTypePath] || 'primary_spend'

  useDocumentTitle({
    paths: [
      'Media Investment Platform',
      'Submission Tracker',
      get(
        submissionTrackerTabs.find( t => t.value === currentSubmissionTrackerTypePath ),
        'label',
      ),
    ]
  })

  const { data: submissionTracker, isLoading, isRefetching } = Api.useSubmissionTracker({
    spendingType,
  })
  
  const rowData = transformData(submissionTracker, validMonths)

  return (
    <Box>
      <PerceptAppBar
        color='primary'
        appBarInserts={[
          <div key='form' className={classes.appBarInsert}>
            <Tabs
              key='form'
              classes={tabClasses}
              value={currentSubmissionTrackerTypePath}
              indicatorColor='secondary'
              onChange={(e, value: SubmissionTrackerSpendingTypePath): void => {
                navigate(pathname.replace(currentSubmissionTrackerTypePath, value))
              }}>
              { submissionTrackerTabs.map( ({ value, label }) => (
                <Tab key={value} value={value} label={label} className={tabClasses.tab} />
              ))}
            </Tabs>
          </div>
        ]}>
        <Typography className={classes.header} variant='h4'>Submission Tracker</Typography>
        <Box ml='auto' display='flex' alignItems='center'>
          <Box mr={1.5}>
            <UserFavouritesPopover />
          </Box>
          <IconButton
            className={classes.exit}
            color='inherit'
            onClick={(): void => {
              const redirectPath = redirectPaths[currentSubmissionTrackerTypePath]
              const basePath = pathname.split('submission-tracker')[0]
              navigate(basePath + redirectPath)
            }}>
            <Close />
          </IconButton>
        </Box>
      </PerceptAppBar>
      { isLoading ? (
        <Loader preset='fullscreen' />
      ) : rowData ? (
        <Box className={classes.tableContainer}>
          { isRefetching && (
            <LinearProgress color='secondary' variant='indeterminate' className={classes.refetchIndicator} />
          )}
          <SubmissionTrackerTable rows={rowData} validMonths={validMonths}/>
        </Box>
      ) : (
        <Box
          height='30vh'
          display='flex'
          justifyContent='center'
          alignItems='center'>
          <Typography variant='h6'>No data</Typography>
        </Box>
      )}
    </Box>
  )
}
