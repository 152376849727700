/* eslint-disable indent */
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { ParentSize } from '@visx/responsive'
import React, { useEffect, useMemo, useState, useContext } from 'react'
import { useLocation, useParams } from 'react-router'
import { AggregationToggles, SelectType } from '../AggregationToggles'
import { BarGroupChart, CHART_HEIGHT, ChartComponentOverrideProps, InvestmentReportChartConfiguration, MultiLineChart, StackedBarChart } from '../Charts'
import { IncompleteDataMatchFunction, ReportTable } from '../ReportTable'
import { TitleComponent } from '../TitleComponent'
import { useInvestmentReportData } from './useInvestmentReportData'
import { useCSVExport } from './useCSVExport'
import { StoreState } from 'types'
import { Filters } from 'enums/Filters'
import { Loader, MenuOption, PlainTextButton, RoundedPlainTextButtonMenu, makeAppStyles } from '@percept/mui'
import { CloudDownload, AsteriskIcon, ArrowDropDown } from '@percept/mui/icons'
import { find, flatten, get, uniq } from 'lodash-es'
import Api, { SpendingType } from 'api/services/Api'
import { DashboardTypeContext } from './contexts'
import { CompetitiveReportKey, MediaSpendExportType } from './typings'
import { OrgPrivilege, PlatformUnitParams, UserPrivileges } from '@percept/types'
import { UserPrivilegeContext } from '@percept/app-components'

import { userHasOrgPrivileges } from '@percept/utils'
import { CurrencyType, InvestmentReportDataFormat, InvestmentReportViewType } from '../types'
import { makeSynchronousHook } from '@percept/hooks/libv2'
import { applyDerivedCompetitiveFilter } from 'redux/ReportingDashboard/actions'
import CompetitiveReports from 'api/services/CompetitiveReports'
import { DerivedCompetitiveFilterPayload, FiltersReduxState } from 'redux/ReportingDashboard/reducers/filtersReducer'
import { parseUrlSearchParams } from '@percept/hooks'
import { FilterPanelState } from '../Filters'


const useClasses = makeAppStyles((theme) => ({
  incomplete: {
    color: theme.palette.error.main,
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

  },
  text: {
    fontWeight: 500,
    fontSize: 13,
  },
}))


type DerivedReportSelectConfiguration = {
  label: string
  defaultIndex?: number
  reverseSort?: boolean
}

export type Report = {
  title: string
  subTitle?: string
  reportKey?: CompetitiveReportKey
  selectOptions?: { value: string; label: string; chart?: string }[]
  chart?: string
  label?: string
  selectType?: SelectType
  marketSort?: boolean
  possibleNegativeValues?: boolean
  singleFormat?: InvestmentReportDataFormat
  onlyYear?: boolean
  defaultViewType?: InvestmentReportViewType
  defaultDataFormat?: InvestmentReportDataFormat
  chartRowSelect?: DerivedReportSelectConfiguration
  chartColumnSelect?: DerivedReportSelectConfiguration
  Chart?: {
    Component: (props: ChartComponentOverrideProps) => JSX.Element
  } & InvestmentReportChartConfiguration
}

export type PossibleFiltersOptions = {
  [key in Filters]?: string[]
}

export enum Summary {
  AllTotal = 'All total',
}

const resolveFilterList = (listOfFilters: string[] = []): string[] => {
  // If no filters were selected return every Financial Year from 2023 up until now
  if(listOfFilters.length == 0){
    const finYears: string[] = []
    let year = 2023 // We start calculating Incomplete data from 2023, everything before that we count as complete
    const current_date = new Date()
    // Create array of strings for every year from 2023 up until now, taking in considuration that new Financial year starts from April
    for (year; (year < current_date.getFullYear() || (year === current_date.getFullYear() && current_date.getMonth() >= 3)); year++){
      let str = year % 100
      finYears.push('FY'+ str + ++str)
    }
    return finYears
  }
  return listOfFilters
}


const primarySpendExportPrivileges: OrgPrivilege[] = [
  'mediaInvestment.investment.atl.export',
  'mediaInvestment.investment.digital.export',
]

const competitiveExportPrivileges: OrgPrivilege[] = [
  'mediaInvestment.competitive.export',
]


const userCanExportData = (
  privileges: UserPrivileges,
  activeOrgUnitId: string,
  spendingType: SpendingType,
): boolean => {
  const requiredPrivileges = (
    spendingType === 'primary_spend' ?
      primarySpendExportPrivileges :
      competitiveExportPrivileges
  )
  return userHasOrgPrivileges(
    requiredPrivileges,
    activeOrgUnitId,
    privileges.org_privileges,
  )
}


const orgUnitMarketLookup: Record<string, string> = {
  'Vodafone AL': 'Albania',
  'Vodafone CZ': 'Czech Republic',
  'Vodafone DE': 'Germany',
  'Vodafone EG': 'Egypt',
  'Vodafone ES': 'Spain',
  'Vodafone GR': 'Greece',
  'Vodafone IE': 'Ireland',
  'Vodafone IT': 'Italy',
  'Vodafone PT': 'Portugal',
  'Vodafone RO': 'Romania',
  'Vodafone TR': 'Turkey',
  'Vodafone UK': 'United Kingdom',
  'Vodacom ZA': 'South Africa',
}


const competitiveIncompleteDataMatchFunction: IncompleteDataMatchFunction = (datum, row) => {
  const marketName = orgUnitMarketLookup[datum.org_unit_name]
  if( !marketName ){
    throw new Error(`Unknown org unit name - ${datum.org_unit_name}`)
  }
  return marketName === row.row_group
}


const incompleteDataMatchFunctionsBySpendingType: Partial<Record<SpendingType, IncompleteDataMatchFunction>> = {
  sos_spend: competitiveIncompleteDataMatchFunction,
  sov_spend: competitiveIncompleteDataMatchFunction,
}


const IDEMPOTENT_EMPTY_DERIVED_COMPETITIVE_FILTERS: FiltersReduxState['derivedCompetitive'] = {
  row: null,
  column: null,
}


const useDerivedCompetitiveFilters = makeSynchronousHook<
  FiltersReduxState['derivedCompetitive'], DerivedCompetitiveFilterPayload, StoreState
>(
  state => state.filters.derivedCompetitive || IDEMPOTENT_EMPTY_DERIVED_COMPETITIVE_FILTERS,
  applyDerivedCompetitiveFilter,
)


export const InvestmentReport = ({
  reportKey,
  title,
  subTitle,
  selectOptions,
  chart = 'stacked-bar-chart',
  label,
  selectType,
  marketSort,
  possibleNegativeValues,
  singleFormat,
  onlyYear,
  defaultViewType = 'table',
  defaultDataFormat = 'currency',
  chartColumnSelect,
  chartRowSelect,
  Chart,
}: Report): JSX.Element => {
  const [view, setView] = useState(defaultViewType)
  const [dataFormat, setDataFormat] = useState(singleFormat || defaultDataFormat)
  const [periodFormat, setPeriodFormat] = useState('year')
  const initialScope = selectOptions && selectOptions[0].value
  const [scopeOfData, setScopeOfData] = useState<string | undefined>(initialScope)
  const classes = useClasses()
  const { pathname } = useLocation()
  const lastPartUrl = pathname.split('/').pop()
  const dashboardType = useContext(DashboardTypeContext)

  const { org_unit_id } = useParams<PlatformUnitParams>()
  const privileges = useContext(UserPrivilegeContext)

  const [derivedCompetitiveFilters, setDerivedCompetitiveFilter] = useDerivedCompetitiveFilters()

  const location = useLocation()
  const filterOptions = useMemo(() => {
    const filters = parseUrlSearchParams<FilterPanelState>(location.search)
    return {
      ...filters,
      'start-month': filters.months ? [filters.months.split('-')[0]] : undefined,
      'end-month': filters.months ? [filters.months.split('-')[1]] : undefined,
    } as unknown as FilterPanelState
  }, [location.search])

  const currencyType = (filterOptions.currency || 'dynamic') as CurrencyType

  const noResultMessage = 'No results found. Maybe you should add some filters.'

  const {
    data,
    isLoading,
    isRefetching,
    exportParams,
    dataHookProps,
    spendingType,
    competitiveReportType,
  } = useInvestmentReportData({
    reportKey,
    url: lastPartUrl,
    title,
    scope: scopeOfData,
    periodFormat,
    filterOptions: filterOptions as PossibleFiltersOptions,
    currencyType,
  })

  const hasExportPrivileges = userCanExportData(privileges, org_unit_id, spendingType)

  const columnFilter = chartColumnSelect ? derivedCompetitiveFilters.column : null
  const rowFilter = chartRowSelect ? derivedCompetitiveFilters.row : null

  useEffect(() => {
    if( chartColumnSelect && data && !columnFilter ){
      const values = data[0].costs.map( c => c.type_value )
      const defaultValue = values.slice(chartColumnSelect.defaultIndex || 0)[0]
      setDerivedCompetitiveFilter({ filterType: 'column', filterValue: defaultValue })
    }

    if( chartRowSelect && data && !rowFilter ){
      const values = data.map( c => c.row_group )
      const defaultValue = values.slice(chartRowSelect.defaultIndex || 0)[0]
      setDerivedCompetitiveFilter({ filterType: 'row', filterValue: defaultValue })
    }
  }, [data, chartColumnSelect, chartRowSelect, columnFilter, rowFilter, setDerivedCompetitiveFilter])

  const exportType = exportParams && exportParams.export_type
  const disabledIncompleteDataExportTypes: MediaSpendExportType[] = [
    'product',
    'funding_source',
    'message',
  ]
  const incompleteDataQueryEnabled = !exportType || !(disabledIncompleteDataExportTypes.includes(exportType))

  const { data: incompleteData, isError}  = Api.useIncompleteData(
    periodFormat as 'month' | 'quarter' | 'year',
    spendingType,
    incompleteDataQueryEnabled,
  )
  const { data: incompleteMarkets, isError: isErrorMarkets}  = Api.useIncompleteMarkets(
    resolveFilterList(),
    spendingType,
    incompleteDataQueryEnabled,
  )

  const {
    download,
    isLoading: isDownloadingMediaInvestment,
  } = useCSVExport()

  const {
    mutate: downloadCompetitive,
    isLoading: isDownloadingCompetitive
  } = CompetitiveReports.useCompetitiveReportExport({
    competitiveType: spendingType === 'sos_spend' ? 'SOS' : 'SOV',
  })

  const marketList = useMemo(() => {
    return (
      data &&
      marketSort &&
      view === 'chart' &&
      data
        .map((el) => {
          return { value: el.row_group, label: el.row_group }
        })
        .filter((el) => el.value !== Summary.AllTotal)
    )
  }, [data, marketSort, view])

  const canonicalSelectOptions = marketList ? marketList : selectOptions
  const defaultScope = canonicalSelectOptions && canonicalSelectOptions[0].value

  useEffect(() => {
    if( canonicalSelectOptions ){
      const match = find(canonicalSelectOptions, o => o.value === scopeOfData )
      if( !match && scopeOfData !== defaultScope ){
        setScopeOfData(defaultScope)
      }
    }
  }, [canonicalSelectOptions, defaultScope, scopeOfData])

  const chartType = selectOptions?.find(el => el.value === scopeOfData)?.chart || chart

  let chartColumnSelectOptions: MenuOption<string>[] = []
  if( data && chartColumnSelect && view === 'chart' ){
    chartColumnSelectOptions = uniq(
      flatten(
        data.map( d => d.costs.map( c => c.type_value ))
      )
    ).sort().map( value => ({
      value,
      label: value,
    }))
    if( chartColumnSelect.reverseSort ){
      chartColumnSelectOptions.reverse()
    }
  }

  const canonicalColumnFilter = (
    chartColumnSelect && derivedCompetitiveFilters.column && (
      chartColumnSelectOptions.find( o => o.value === derivedCompetitiveFilters.column ) ?
        derivedCompetitiveFilters.column :
        get(chartColumnSelectOptions, [0, 'value'])
    ) || null
  )

  let chartRowSelectOptions: MenuOption<string>[] = []
  if( data && chartRowSelect && view === 'chart' ){
    chartRowSelectOptions = data.map( d => ({
      value: d.row_group,
      label: d.row_group,
    }))
  }

  const canonicalRowFilter = (
    chartRowSelect && derivedCompetitiveFilters.row && (
      chartRowSelectOptions.find( o => o.value === derivedCompetitiveFilters.row ) ?
        derivedCompetitiveFilters.row :
        get(chartRowSelectOptions, [0, 'value'])
    ) || null
  )

  const isExportApplicable = spendingType === 'primary_spend' ? !!exportParams : !!competitiveReportType

  const isDownloading = isDownloadingMediaInvestment || isDownloadingCompetitive

  const downloadButton = (hasExportPrivileges || null) && (isExportApplicable || null) && (
    <PlainTextButton
      size='small'
      variant='contained'
      disabled={isDownloading}
      startIcon={
        isDownloading ?
          <CircularProgress size='1em' color='inherit' /> :
          <CloudDownload />
      }
      onClick={(): void => {
        if( spendingType === 'primary_spend' ){
          if( exportParams ){
            download({
              exportParams,
              filters: filterOptions as PossibleFiltersOptions,
              currencyType,
              period: periodFormat as 'month' | 'quarter' | 'year',
            })
          }
        }else{
          if( competitiveReportType ){
            downloadCompetitive({
              report_type: competitiveReportType,
              ...dataHookProps
            })
          }
        }
      }}>
      Download CSV
    </PlainTextButton>
  )

  return (
    <Box mt={5}>
      <TitleComponent
        titleText={title}
        subtitleText={subTitle}
        labelText={label}
        downloadButton={downloadButton}
      />
      <AggregationToggles
        view={view}
        selectOptions={canonicalSelectOptions}
        selectedValueSelect={scopeOfData}
        changeHandlerSelect={(e): void =>
          setScopeOfData(e.target.value as string)
        }
        setView={label ? undefined : setView}
        dataFormat={dataFormat}
        setDataFormat={singleFormat ? undefined : setDataFormat}
        periodFormat={dashboardType === 'filtersMedia' ? periodFormat : null}
        setPeriodFormat={onlyYear ? undefined: setPeriodFormat}
        selectType={selectType}
      />
      <br />
      {view === 'table' ? (
        data && data.length > 0 && data[0].costs.length > 0 ? (
          <div>
            <ReportTable
              data={data}
              isError={isError}
              incompleteData={incompleteData}
              isErrorMarkets={isErrorMarkets}
              incompleteMarkets={
                incompleteDataQueryEnabled ?
                  incompleteMarkets : undefined
              }
              incompleteDataMatchFunction={incompleteDataMatchFunctionsBySpendingType[spendingType]}
              dataFormat={dataFormat}
              showFetchIndicator={isLoading || isRefetching}
              exportType={exportType}
              automaticSort={spendingType === 'primary_spend'}
            />
            { incompleteDataQueryEnabled && (
              <Typography className={classes.helperText}>
                <AsteriskIcon className={classes.incomplete} fontSize='inherit' />
                <Typography className={classes.text} display='inline'>= not all data has been submitted for these markets and dates</Typography>
              </Typography>
            )}
          </div>
        ) : isLoading ? (
          <Loader preset='centered' />
        ) : (
          <Typography>
           {noResultMessage}
          </Typography>
        )
      ) : Chart ? (
        (data && (!chartColumnSelect || !!columnFilter) && (!chartRowSelect || !!rowFilter)) ? (
          <>
            { (chartRowSelect || chartColumnSelect) && (
              <Box display='flex' alignItems='center' my={2}>
                { chartRowSelect && (
                  <RoundedPlainTextButtonMenu
                    TriggerProps={{
                      variant: 'contained',
                      endIcon: <ArrowDropDown />,
                    }}
                    value={canonicalRowFilter}
                    options={
                      data.map( d => ({
                        value: d.row_group,
                        label: d.row_group,
                      }))
                    }
                    onChange={(e, filterValue): void => {
                      setDerivedCompetitiveFilter({ filterType: 'row', filterValue })
                    }} />
                )}
                { chartColumnSelect && (
                  <Box ml={chartRowSelect ? 2 : 0}>
                    <RoundedPlainTextButtonMenu
                      TriggerProps={{
                        variant: 'contained',
                        endIcon: <ArrowDropDown />,
                      }}
                      value={canonicalColumnFilter}
                      options={chartColumnSelectOptions}
                      onChange={(e, filterValue): void => {
                        setDerivedCompetitiveFilter({ filterType: 'column', filterValue })
                      }} />
                  </Box>
                )}
              </Box>
            )}
            <Chart.Component
              data={data}
              dataFormat={dataFormat}
              columnFilter={canonicalColumnFilter}
              rowFilter={canonicalRowFilter}
              includeTotals={Chart.includeTotals}
              adapter={Chart.adapter}
              dataConfiguration={Chart.dataConfiguration}
              componentProps={Chart.componentProps} />
          </>
        ) : isLoading || (chartColumnSelect && !columnFilter) || (chartRowSelect && !rowFilter) ? (
          <Loader preset='centered' />
        ) : (
          <Typography>
           {noResultMessage}
          </Typography>
        )
      ) : (
        {
          'stacked-bar-chart':
            data && data.length > 0 ? (
              <ParentSize>
                {({ width }): JSX.Element => (
                  <StackedBarChart
                    dataFormat={dataFormat}
                    dataObject={
                      marketList
                        ? data.filter((el) => el.row_group === scopeOfData)
                            .length === 0
                          ? data.filter(
                              (el) =>
                                marketList &&
                                el.row_group === marketList[0].value
                            )
                          : data.filter((el) => el.row_group === scopeOfData)
                        : data
                    }
                    width={width}
                  />
                )}
              </ParentSize>
            ) : isLoading ? (
              <Loader preset='centered' height={CHART_HEIGHT} />
            ) : (
              <Typography>
                {noResultMessage}
              </Typography>
            ),
          'multiline-chart':
            data && data.length > 0 ? (
              <MultiLineChart
                dataFormat={dataFormat}
                data={data.filter((el) => el.row_group !== 'Difference')}
              />
            ) : isLoading ? (
              <Loader preset='centered' height={CHART_HEIGHT} />
            ) : (
              <Typography>
                {noResultMessage}
              </Typography>
            ),
          'bar-group-chart':
            data && data.length > 0 ? (
              <ParentSize>
                {({ width }): JSX.Element => (
                  <BarGroupChart
                    dataFormat={dataFormat}
                    dataObject={data}
                    width={width}
                    mirrorYDomain={possibleNegativeValues}
                  />
                )}
              </ParentSize>
            ) : isLoading ? (
              <Loader preset='centered' height={CHART_HEIGHT} />
            ) : (
              <Typography>
                {noResultMessage}
              </Typography>
            ),
        }[chartType]
      )}
    </Box>
  )
}
