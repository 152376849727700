import { LOCATION_CHANGE } from 'connected-react-router'

import {
  REHYDRATE,
  SET_APP_THEME,
  SET_TARGET_CURRENCY,
  SET_PERFORMANCE_REPORTING_DIMENSION,
  SET_REFERENCE_DATE,
  SET_REFERENCE_DATE_BEHAVIOUR,
  SET_PERFORMANCE_COMPARISON_RANGE,
  SET_INSIGHTS_REPORT_VIEW_TYPE,
  SET_SAVED_QUERY_STRINGS,
} from 'redux/actions'

import {
  AnyPerformanceDimension,
  InsightsReportViewType,
  PerformanceComparisonRange,
  ReduxAction,
  ReferenceDateBehaviour,
} from '@percept/types'

import { Reducer } from 'redux'

import { AppTheme, defaultAppTheme } from '@percept/mui'

import { performanceReporting } from '@percept/redux/bundles'

import { get } from 'lodash-es'

import { getFetchTypes } from '@percept/redux'

import { Location } from 'history'

import { DashboardQueryStringKey } from 'redux/types'


export type AppState = {
  initialLocation: Location | null
  theme: {
    active: AppTheme
    options: AppTheme[]
  }
  targetCurrency: string
  performanceReportingDimension: AnyPerformanceDimension
  referenceDate: Date | null
  referenceDateBehaviour: ReferenceDateBehaviour
  latestAvailableReferenceDate: Date | null
  latestAvailableDoubleVerifyReferenceDate: Date | null
  performanceComparisonRange: PerformanceComparisonRange
  insightsReportViewType: InsightsReportViewType
  savedQueryStrings: Partial<Record<DashboardQueryStringKey, string>>
}


const initialState: AppState = {
  initialLocation: null,
  theme: {
    active: defaultAppTheme,
    options: [
      defaultAppTheme,
    ],
  },
  targetCurrency: 'EUR',
  performanceReportingDimension: 'cost',
  referenceDate: null,
  referenceDateBehaviour: 'LATEST_ALL',
  latestAvailableReferenceDate: null,
  latestAvailableDoubleVerifyReferenceDate: null,
  performanceComparisonRange: 'd7',
  insightsReportViewType: 'LAYOUT',
  savedQueryStrings: {},
}



const providerPerformanceComparisonFetchTypes = getFetchTypes(
  performanceReporting.actions.LOAD_PROVIDER_PERFORMANCE_COMPARISONS
)

const doubleVerifyPerformanceComparisonFetchTypes = getFetchTypes(
  performanceReporting.actions.LOAD_DOUBLE_VERIFY_PROVIDER_PERFORMANCE_COMPARISONS
)

const appStateReducer: Reducer<AppState, ReduxAction> = (state = initialState, action) => {

  // Populate latest available dates
  if( action.type === providerPerformanceComparisonFetchTypes.success && !state.latestAvailableReferenceDate ){
    const refDate = get(action.payload, 'reference_date', null)
    return {
      ...state,
      latestAvailableReferenceDate: refDate && new Date(refDate)
    }
  }

  if( action.type === doubleVerifyPerformanceComparisonFetchTypes.success && !state.latestAvailableDoubleVerifyReferenceDate ){
    const refDate = get(action.payload, 'reference_date', null)
    return {
      ...state,
      latestAvailableDoubleVerifyReferenceDate: refDate && new Date(refDate)
    }
  }

  switch( action.type ){

    case LOCATION_CHANGE: {
      if( action.payload.isFirstRendering ){
        return {
          ...state,
          initialLocation: action.payload.location
        }
      }
      return state
    }

    case REHYDRATE:
      return {
        ...initialState,
        ...state || {},
        referenceDateBehaviour: (
          state && state.referenceDateBehaviour !== 'CUSTOM' ?
            state.referenceDateBehaviour :
            'LATEST_ALL'
        ),
      }

    case SET_TARGET_CURRENCY:
      return {
        ...state,
        targetCurrency: action.payload,
      }

    case SET_APP_THEME:
      return {
        ...state,
        theme: {
          ...state.theme,
          active: action.payload,
        }
      }
    
    case SET_PERFORMANCE_REPORTING_DIMENSION:
      return {
        ...state,
        performanceReportingDimension: action.payload,
      }

    case SET_REFERENCE_DATE:
      return {
        ...state,
        referenceDate: action.payload,
        referenceDateBehaviour: 'CUSTOM',
      }

    case SET_REFERENCE_DATE_BEHAVIOUR:
      return {
        ...state,
        referenceDateBehaviour: action.payload,
      }

    case SET_PERFORMANCE_COMPARISON_RANGE:
      return {
        ...state,
        performanceComparisonRange: action.payload,
      }

    case SET_INSIGHTS_REPORT_VIEW_TYPE:
      return {
        ...state,
        insightsReportViewType: action.payload,
      }

    case SET_SAVED_QUERY_STRINGS:
      return {
        ...state,
        savedQueryStrings: {
          ...state.savedQueryStrings,
          ...action.payload,
        }
      }

    default:
      return state
  }
}

export default appStateReducer
