import { useState } from 'react'
import { QueryKey, UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { makeAuthorizedApiClient, makeClientQueryFn } from '@percept/hooks'
import { isoDate } from '@percept/utils'
import { PaginatedResponse, QuadrantType } from '@percept/types'
import qs from 'query-string'


const { VODAFONE_CUSTOM_API_ROOT } = process.env


const smartCampaignAssessmentApiClient = makeAuthorizedApiClient({
  baseURL: `${VODAFONE_CUSTOM_API_ROOT}/smart-campaign/v1`,
})


const smartCampaignAssessmentQueryKey: QueryKey = 'smartCampaignAssessment'


type ProgressState = {
  loaded: number
  total: number
}

export type SmartCampaignUploadErrorType =
  | 'file_error'
  | 'sheet_error'
  | 'row_value_error'


export type SmartCampaignUploadWarningType =
  | 'file_warning'
  | 'sheet_warning'
  | 'row_value_warning'


export type SmartCampaignUploadWarning = {
  message: string
  warning_type: SmartCampaignUploadWarningType
  location_info: string | null
}


export type SmartCampaignUploadError = {
  message: string
  error_type: SmartCampaignUploadErrorType
  location_info: string | null
}

export type SmartCampaignUploadResponse = {
  message: string
  warnings: SmartCampaignUploadWarning[]
  errors: SmartCampaignUploadError[]
}

export const useSmartCampaignAssesmentUpload = (): UseMutationResult<
  SmartCampaignUploadResponse,
  AxiosError<SmartCampaignUploadResponse>,
  Blob
> & { progress: ProgressState } => {
  const queryClient = useQueryClient()
  const [progress, setProgress] = useState<ProgressState>({ loaded: 0, total: 0 })
  const mutation = useMutation<
    SmartCampaignUploadResponse,
    AxiosError<SmartCampaignUploadResponse>,
    Blob
  >({
    mutationFn: async (file) => {
      const formData = new FormData()
      formData.append('upload_file', file)
      setProgress({loaded: 0, total: file.size})
      const response = await smartCampaignAssessmentApiClient({
        url: '/upload/',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (e) => {
          setProgress({
            loaded: e.loaded,
            total: e.total,
          })
        }
      })
      return response.data
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: [smartCampaignAssessmentQueryKey] })
    },
  })
  
  

  return {
    ...mutation,
    progress,
    reset: (): void => {
      mutation.reset()
      setProgress({loaded: 0, total: 0})
    }
  }
}

type MarketRepr = {
  market_id: string
  market_iso_code: string
  market_name: string
}

export type SmartCampaignReportType = 'VODAFONE' | 'COMPETITOR'

const brandGroupMapping: Record<SmartCampaignReportType, string> = {
  VODAFONE: 'vodafone',
  COMPETITOR: 'excluding-vodafone',
}

export type SmartCampaignQuadrant = {
  markets: MarketRepr[]
  campaign_count: number
  campaign_percentage: string
  quadrant_position: QuadrantType
}

export type SmartCampaignQuadrantResponse = {
  total_campaign_count: number
  rows: SmartCampaignQuadrant[]
}

export type SmartCampaignAssessmentHookProps = {
  start: Date | null
  end: Date | null
  markets?: string[] | null
  reportType?: SmartCampaignReportType | null
}


const formatDateParams = ({
  start,
  end,
}: {
  start: Date | null
  end: Date | null
}): Record<'start_date' | 'end_date', string | null> => {
  return {
    start_date: start && isoDate(start),
    end_date: end && isoDate(end),
  }
}

const getSmartCampaignRequestParams = ({
  start,
  end,
  markets,
  reportType,
}: SmartCampaignAssessmentHookProps): Record<string, any> => {
  const params: Record<string, any> = formatDateParams({ start, end })
  if( reportType ){
    params.brands_group = brandGroupMapping[reportType]
  }
  if( markets && markets.length ){
    params.market_ids = markets
  }
  return params
}


export const useSmartCampaignAssesmentQuadrantReport = ({
  start,
  end,
  markets,
  reportType,
  enabled = true,
  ...options
}: (
  SmartCampaignAssessmentHookProps & UseQueryOptions<SmartCampaignQuadrantResponse>
)): UseQueryResult<SmartCampaignQuadrantResponse> => {

  return useQuery<SmartCampaignQuadrantResponse>({
    queryKey: [
      smartCampaignAssessmentQueryKey,
      'quadrantReport',
      reportType,
      markets && markets.slice().sort(), // Consistent markets query key
      start,
      end,
    ],
    queryFn: makeClientQueryFn<SmartCampaignQuadrantResponse>(
      smartCampaignAssessmentApiClient,
      {
        url: '/quadrant-report',
        params: getSmartCampaignRequestParams({
          start, end, markets, reportType
        }),
        paramsSerializer: qs.stringify,
      }
    ),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!(enabled && start && end && markets && markets.length),
    ...options,
  })
}

export type SmartCampaignFiltersResponse = {
  market_ids: string[]
  brands: string[]
  reference_start_date: string | null
  reference_end_date: string | null
}

export const useSmartCampaignFilters = (
  options?: UseQueryOptions<SmartCampaignFiltersResponse>
): UseQueryResult<SmartCampaignFiltersResponse> => {
  return useQuery<SmartCampaignFiltersResponse>({
    queryKey: [
      smartCampaignAssessmentQueryKey,
      'filters',
    ],
    queryFn: makeClientQueryFn<SmartCampaignFiltersResponse>(
      smartCampaignAssessmentApiClient,
      { url: '/filter' }
    ),
    retry: false,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    ...(options || {}),
  })
}


export type SmartCampaignRow = {
  market: MarketRepr
  campaign_date: string
  campaign_length: string | null
  campaign_id: string | null
  campaign_name: string
  campaign_spend: string | null
  campaign_quadrant_position: QuadrantType
  brand: string
  link: string
  normalized_link: string | null
}

export const useSmartCampaigns = ({
  start,
  end,
  markets,
  reportType,
  quadrant,
  page,
  limit,
  queryOptions = {},
}: SmartCampaignAssessmentHookProps & {
  quadrant: QuadrantType
  page: number
  limit: number
  queryOptions?: Partial<UseQueryOptions<PaginatedResponse<SmartCampaignRow[]>>>
}): UseQueryResult<PaginatedResponse<SmartCampaignRow[]>> => (
  useQuery({
    queryKey: [
      smartCampaignAssessmentQueryKey,
      'campaigns',
      reportType,
      markets && markets.slice().sort(), // Consistent markets query key
      quadrant,
      start,
      end,
      limit,
      page,
    ],
    queryFn: makeClientQueryFn<PaginatedResponse<SmartCampaignRow[]>>(
      smartCampaignAssessmentApiClient,
      {
        url: '/campaign',
        params: {
          page_number: page,
          limit,
          ...getSmartCampaignRequestParams({
            start, end, markets, reportType
          }),
          quadrant_positions: [quadrant],
        },
        paramsSerializer: qs.stringify,
      }
    ),
    enabled: !!(start && end),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    retry: false,
    ...queryOptions,
  })
)
