import React from 'react'
import {
  Radio,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormGroup,
  FormGroupProps,
  FormLabel,
  FormControlLabelProps,
} from '@material-ui/core'
import { MenuOption } from '../Menus'
import { isEqual } from 'lodash-es'


export type RadioFormGroupProps<T> = {
  name: React.ReactNode
  value: T
  onChange: (value: T) => void
  options: MenuOption<T>[]
  areValuesEqual?: (a: T, b: T) => boolean
  FormGroupProps?: FormGroupProps
  FormControlLabelProps?: Partial<FormControlLabelProps>
} & Partial<Pick<FormControlProps, 'color' | 'disabled' | 'size'>>

export function RadioFormGroup<T>({
  name,
  value,
  options,
  onChange,
  areValuesEqual = isEqual,
  FormGroupProps = {},
  FormControlLabelProps = {},
  ...props
}: RadioFormGroupProps<T>): JSX.Element {
  return (
    <FormControl color='secondary' component='fieldset' {...props}>
      <FormLabel>{name}</FormLabel>
      <FormGroup {...FormGroupProps}>
        { options.map( (option, i) => {
          const isSelected = areValuesEqual(value, option.value)
          return (
            <FormControlLabel
              key={i}
              label={option.label}
              disabled={option.disabled}
              control={
                <Radio
                  checked={isSelected}
                  onChange={(): void => {
                    onChange(option.value)
                  }} />
              }
              {...FormControlLabelProps} />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}
