import React from 'react'

import { Flag, FlagName, FlagProps } from '@percept/mui/flags'

import { percentageFormatter } from '@percept/mui/charts'

import { QuadrantType } from '@percept/types'

import { QuadrantChart, QuadrantChartProps } from './QuadrantChart'

import { getQuadrantDatumAttributes, quadrantContrastTextFill, quadrantHealth } from './lib'


export type QuadrantDatum = {
  value: number
  isoCodes?: FlagName[]
}

export type QuadrantDataset = Partial<Record<QuadrantType, QuadrantDatum>>


type SizeAttributes = {
  width: number
  height: number
}

export type QuadrantReportProps = (
  {
    primaryQuadrantDataset?: QuadrantDataset | null
    secondaryQuadrantDataset?: QuadrantDataset | null
    flagsEnabled?: boolean
    onQuadrantClick?: (quadrant: QuadrantType) => void
  }
  & Omit<QuadrantChartProps, 'children'>
)


type QuadrantFlagsProps = {
  quadrant: QuadrantType
  isoCodes: FlagName[]
  width: number
  height: number
  midPointX: number
  midPointY: number
  flagMargin: number
  FlagProps: Pick<FlagProps, 'size'> & Partial<Omit<FlagProps, 'children'>>
  xOffset?: number
  yOffset?: number
}

const QuadrantFlags = ({
  quadrant,
  isoCodes,
  width,
  height,
  midPointX,
  midPointY,
  flagMargin,
  FlagProps,
  xOffset = 0,
  yOffset = 0,
}: QuadrantFlagsProps): JSX.Element => {

  let quadrantHeight = midPointY
  if( quadrant === 'bottom_left' || quadrant === 'bottom_right' ){
    quadrantHeight = height - midPointY
  }
  const paddedFlagSize = FlagProps.size + flagMargin
  const maxFlagsPerLine = Math.floor(
    quadrantHeight / (FlagProps.size + (flagMargin * 2))
  )
  const leftAligned = quadrant === 'top_left' || quadrant === 'bottom_left'

  return (
    <g pointerEvents='none'>
      { isoCodes.map( (isoCode, i) => {
        const groupingDelta = Math.ceil((i + 1) / maxFlagsPerLine) - 1
        const rowIndex = i - (groupingDelta * maxFlagsPerLine)
        const flagXOffset = groupingDelta * paddedFlagSize
        let x = 0
        if( leftAligned ){
          x = flagMargin + xOffset + flagXOffset
        }else{
          x = xOffset + width - flagMargin - FlagProps.size - flagXOffset
        }
        let y = yOffset + (flagMargin + (paddedFlagSize * rowIndex))
        if( quadrant === 'bottom_left' || quadrant === 'bottom_right' ){
          y += midPointY
        }
        return (
          <Flag
            key={isoCode}
            isoCode={isoCode}
            x={x}
            y={y}
            {...FlagProps} />
        )
      })}
    </g>
  )
}


export const QuadrantReport = ({
  width,
  height,
  onQuadrantClick,
  flagsEnabled = true,
  primaryQuadrantDataset,
  secondaryQuadrantDataset,
  ...props
}: QuadrantReportProps): JSX.Element => {
  return (
    <QuadrantChart
      width={width}
      height={height}
      {...props}>
      { ({
        x,
        y,
        width,
        height,
        midPointX,
        midPointY,
        healthColourScale,
      }): JSX.Element => {
        return (
          <g>
            { primaryQuadrantDataset && (
              Object.entries(primaryQuadrantDataset).map( ([quadrant, datum]) => {
                if( !datum.value ){
                  return null
                }
                const quadrantType = quadrant as QuadrantType
                const attrs = getQuadrantDatumAttributes({
                  quadrant: quadrantType,
                  value: datum.value,
                  width,
                  height,
                  x,
                  y,
                  midPointX,
                  midPointY,
                })
                return (
                  <g
                    key={`primary-${quadrant}`}
                    onClick={
                      onQuadrantClick && (
                        (): void => onQuadrantClick(quadrantType)
                      )
                    }
                    cursor={onQuadrantClick ? 'pointer' : undefined}>
                    <rect
                      {...attrs}
                      fill={healthColourScale(quadrantHealth[quadrantType])} />
                    <text
                      x={attrs.x + (attrs.width / 2)}
                      y={attrs.y + (attrs.height / 2)}
                      textAnchor='middle'
                      dominantBaseline='central'
                      fontSize={15}
                      fontWeight={600}
                      fill={quadrantContrastTextFill[quadrantType]}>
                      { percentageFormatter(datum.value) }
                    </text>
                  </g>
                )
              })
            )}

            { secondaryQuadrantDataset && (
              Object.entries(secondaryQuadrantDataset).map( ([quadrant, datum]) => {
                if( !datum.value ){
                  return null
                }
                const strokeWidth = 2
                const halfStrokeWidth = strokeWidth / 2
                const attrs = getQuadrantDatumAttributes({
                  quadrant: quadrant as QuadrantType,
                  value: datum.value,
                  width,
                  height,
                  x,
                  y,
                  midPointX,
                  midPointY,
                  offset: halfStrokeWidth,
                })
                return (
                  <rect
                    key={`secondary-${quadrant}`}
                    {...attrs}
                    pointerEvents='none'
                    cursor={onQuadrantClick ? 'pointer' : undefined}
                    stroke='#000'
                    strokeWidth={strokeWidth}
                    strokeDasharray='10 5'
                    fill='none' />
                )
              })
            )}

            {/* We need to render the flags last for correct z-index relative to any secondary quadrants */}
            { flagsEnabled && primaryQuadrantDataset && (
              Object.entries(primaryQuadrantDataset).map( ([quadrant, datum]) => {
                if( !datum.isoCodes ){
                  return null
                }
                return (
                  <QuadrantFlags
                    key={`flags-${quadrant}`}
                    quadrant={quadrant as QuadrantType}
                    width={width}
                    height={height}
                    midPointX={midPointX}
                    midPointY={midPointY}
                    isoCodes={datum.isoCodes}
                    flagMargin={10}
                    xOffset={x}
                    FlagProps={{
                      size: 32,
                      rounded: true,
                      border: '#ccc'
                    }} />
                )
              })
            )}
          </g>
        )
      }}
    </QuadrantChart>
  )
}
