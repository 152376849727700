import React, { useContext } from 'react'

import { MarketUserCalculationView, EstimateSubmissionForm } from './estimateComponents'

import { SubApplicationTabContext, SubApplicationTabs } from 'components/SubApplicationTabs'
import { SponsorshipsStateContext } from './contexts'
import { SponsorshipsView } from './typings'


export const MarketView = (): JSX.Element => {
  const [state, setState] = useContext(SponsorshipsStateContext)

  const setActiveTabValue = (tabValue: SponsorshipsView): void => {
    setState({ view: tabValue as SponsorshipsView, estimateId: null })
  }

  return (
    <SubApplicationTabContext.Provider
      value={{
        activeTabValue: state.view,
        setActiveTabValue: setActiveTabValue as ((value: string) => void),
      }}>
      <SubApplicationTabs
        tabs={[
          {
            label: 'View Calculations',
            value: 'view',
            content: <MarketUserCalculationView />,
          },
          {
            label: 'Submit Data',
            value: 'submit',
            content: <EstimateSubmissionForm onSubmit={(): void => setActiveTabValue('calculations')} />,
          },
        ]} />
    </SubApplicationTabContext.Provider>
  )
}
