import {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query'

import { apiClients, makeClientQueryFn } from './lib'

import qs from 'query-string'

import { get, identity, sortBy } from 'lodash-es'

import { saveAs } from 'file-saver'

import { CreativeWastageResponse } from './creativeX'

import {
  CurrencyParams,
  DateType,
  Nullable,
  PerformanceProvider,
  PlatformUnitParams,
  ProviderPotentialEfficiencyScores,
  ReferenceDateParams,
  ReportProviderV2,
} from '@percept/types'

import { AxiosError } from 'axios'
import { isoDate } from '@percept/utils'


type PotentialEfficiencyHookPropsBase = (
  Partial<Nullable<ReferenceDateParams>>
  & {
    start_date?: string | null
    currency?: string | null
  }
)

export type SingleOrgUnitPotentialEfficiencyHookProps = (
  PotentialEfficiencyHookPropsBase &
  PlatformUnitParams
)

export type MultiOrgUnitPotentialEfficiencyHookProps = (
  PotentialEfficiencyHookPropsBase & {
    org_unit_ids: string[]
  }
)

export type OrgUnitProviderPotentialEfficiencyScores = (
  ProviderPotentialEfficiencyScores & {
    org_unit_id: string
  }
)

export const useOrgUnitPotentialEfficiencyScores = ({
  org_unit_id,
  reference_date,
  start_date,
  currency,
  ...options
}: SingleOrgUnitPotentialEfficiencyHookProps & Partial<
  UseQueryOptions<ProviderPotentialEfficiencyScores[]>
>): UseQueryResult<ProviderPotentialEfficiencyScores[]> => (
  useQuery({
    queryKey: [
      'potentialEfficiencies',
      org_unit_id,
      reference_date,
      start_date,
      currency,
    ],
    queryFn: makeClientQueryFn(
      apiClients.core,
      {
        url: `/org-units/${org_unit_id}/report-series/potential-efficiency-scores/`,
        params: {
          ...(reference_date && {
            reference_date
          }),
          ...(start_date && {
            start_date
          }),
          ...(currency && {
            target_currency: currency
          }),
        }
      }
    ),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  })
)

export const useNestedOrgUnitPotentialEfficiencyScores = ({
  org_unit_id,
  reference_date,
  start_date,
  currency,
  ...options
}: SingleOrgUnitPotentialEfficiencyHookProps & Partial<
UseQueryOptions<OrgUnitProviderPotentialEfficiencyScores[]>
>): UseQueryResult<OrgUnitProviderPotentialEfficiencyScores[]> => (
  useQuery({
    queryKey: [
      'potentialEfficiencies',
      'byOrgUnit',
      org_unit_id,
      reference_date,
      start_date,
      currency,
    ],
    queryFn: makeClientQueryFn(
      apiClients.core,
      {
        url: `/org-units/${org_unit_id}/report-series/potential-efficiency-scores-by-child-org-unit/`,
        params: {
          ...(reference_date && {
            reference_date
          }),
          ...(start_date && {
            start_date
          }),
          ...(currency && {
            target_currency: currency
          }),
        }
      }
    ),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
  })
)


export const useMultiOrgUnitPotentialEfficiencyScores = ({
  org_unit_ids,
  reference_date,
  start_date,
  currency,
  ...options
}: (
  MultiOrgUnitPotentialEfficiencyHookProps
  & Partial<UseQueryOptions<OrgUnitProviderPotentialEfficiencyScores[]>>
  )
): UseQueryResult<OrgUnitProviderPotentialEfficiencyScores[]> => (
  useQuery({
    queryKey: [
      'potentialEfficiencies',
      'multiOrgUnit',
      org_unit_ids.sort(),
      reference_date,
      start_date,
      currency,
    ],
    queryFn: makeClientQueryFn(
      apiClients.core,
      {
        url: '/org-units/report-series/potential-efficiency-scores',
        params: {
          org_unit_ids,
          ...(reference_date && {
            reference_date
          }),
          ...(start_date && {
            start_date
          }),
          ...(currency && {
            target_currency: currency
          }),
        },
        paramsSerializer: (params): string => (
          qs.stringify(params)
        ),
      }
    ),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options,
    enabled: !!org_unit_ids.length && get(options, 'enabled', true),
  })
)


export type WastageProvider = PerformanceProvider | ReportProviderV2

export type WastageSegmentation = (
  | 'ORG_UNIT'
  | 'PROVIDER'
  | 'MONTH'
  | 'QUARTER'
  | 'CALENDAR_YEAR'
  | 'FINANCIAL_YEAR'
)

export type WastageVariant = 'CREATIVE' | 'DIGITAL' | 'COMBINED'

export type WastageComparisonType = 'YEAR' | 'QUARTER' | 'MONTH'


type WastageSegmentationAttributes = {
  segment_type: WastageSegmentation
  segment_value: string
}

export type CreativeWastageWithSegmentation = (
  CreativeWastageResponse & WastageSegmentationAttributes
)

export type DigitalWastage = {
  total_cost: number | null
  wastage_cost: number | null
  wastage_ratio: number | null
}

export type DigitalWastageWithSegmentation = (
  DigitalWastage & WastageSegmentationAttributes
)

export type CombinedWastage = (
  DigitalWastage & Pick<CreativeWastageResponse, 'creative_quality_score'>
)

export type CombinedWastageWithSegmentation = (
  DigitalWastageWithSegmentation & WastageSegmentationAttributes
)

export type WastageParams = (
  Nullable<PlatformUnitParams>
  & Partial<CurrencyParams>
  & {
    start: DateType | null
    end: DateType | null
    segment_type: WastageSegmentation
    providers?: WastageProvider[] | null
    org_unit_ids?: string[] | null
  }
)

export type WastageReferenceParams = Pick<
  WastageParams,
  'org_unit_id' | 'org_unit_ids' | 'providers'
>

type ReferenceDatesResponse = Record<'start_date' | 'end_date', string>
type ReferenceDates = Record<'start' | 'end', Date>

export const useWastageReferenceDates = ({
  org_unit_id,
  org_unit_ids,
  providers,
}: WastageReferenceParams): UseQueryResult<ReferenceDates> => (
  useQuery({
    queryKey: [
      'wastage',
      'referenceDates',
      org_unit_id,
      org_unit_ids && org_unit_ids.sort(),
      providers && providers.sort(),
    ],
    queryFn: makeClientQueryFn<ReferenceDatesResponse, ReferenceDates>(
      apiClients.core,
      {
        url: `/org-units/${org_unit_id}/wastage-reference-dates`,
        method: 'GET',
        params: {
          ...(providers && {
            provider_filter: providers,
          }),
          ...(org_unit_ids && {
            org_unit_filter: org_unit_ids,
          }),
        },
        paramsSerializer: qs.stringify,
      },
      response => {
        const { start_date, end_date } = response.data
        return {
          start: new Date(start_date),
          end: new Date(end_date),
        }
      }
    ),
    enabled: !!org_unit_id,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60,
  })
)


const getDateParams = (
  { start, end }: Record<'start' | 'end', DateType | null>
): Record<'start_date' | 'end_date', string | null> => {
  return {
    start_date: !start ? null : isoDate(new Date(start)),
    end_date: !end ? null : isoDate(new Date(end)),
  }
}


type WastageHook<T> = (
  props: WastageParams & Partial<UseQueryOptions<T[], AxiosError>>
) => UseQueryResult<T[], AxiosError>

const makeWastageHook = <T extends { segment_value: string }, R = T>(
  variant: WastageVariant
): WastageHook<T> => {
  return ({
    org_unit_id,
    segment_type,
    start,
    end,
    target_currency = 'EUR',
    org_unit_ids = null,
    providers = null,
    ...options
  }): UseQueryResult<T[], AxiosError> => {
    const { start_date, end_date } = getDateParams({ start, end })
    return useQuery({
      queryKey: [
        'wastage',
        variant,
        org_unit_id,
        start_date,
        end_date,
        segment_type,
        target_currency,
        providers && providers.sort(),
        org_unit_ids && org_unit_ids.sort(),
      ],
      queryFn: makeClientQueryFn<T[]>(
        apiClients.core,
        {
          url: `/org-units/${org_unit_id}/${variant.toLowerCase()}-wastage-with-segmentation`,
          method: 'GET',
          params: {
            segment_type,
            start_date,
            end_date,
            target_currency,
            ...(providers && {
              provider_filter: providers,
            }),
            ...(org_unit_ids && {
              org_unit_filter: org_unit_ids,
            }),
          },
          paramsSerializer: qs.stringify
        },
        (response) => {
          const sortedData = sortBy(response.data, 'segment_value')
          let labelAdapter: ((value: string) => string) = identity
          if( variant === 'CREATIVE' ){
            if( segment_type === 'PROVIDER' ){
              const creativeLabelMap: Record<string, string> = {
                FACEBOOK_ADS: 'FACEBOOK',
                TIKTOK_ADS: 'TIKTOK',
              }
              labelAdapter = (value): string => creativeLabelMap[value] || value
            }else if( segment_type !== 'ORG_UNIT' ){
              labelAdapter = (value): string => value.split('T')[0]
            }
          }
          return sortedData.map( d => ({
            ...d,
            segment_value: labelAdapter(d.segment_value)
          }))
        },
      ),
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
      ...options,
      enabled: !!(start_date && end_date && org_unit_id) && (options.enabled === undefined || options.enabled),
    })
  }
}

export const useCreativeWastage = makeWastageHook<CreativeWastageWithSegmentation>('CREATIVE')

export const useDigitalWastage = makeWastageHook<DigitalWastageWithSegmentation>('DIGITAL')

export const useCombinedWastage = makeWastageHook<CombinedWastageWithSegmentation>('COMBINED')

export type TotalWastageParams = Omit<WastageParams, 'segment_type'>

export const useTotalCreativeWastage = ({
  org_unit_id,
  start,
  end,
  target_currency = 'EUR',
  org_unit_ids = null,
  providers = null,
  ...options
}: TotalWastageParams & Partial<UseQueryOptions<CreativeWastageResponse>>): UseQueryResult<CreativeWastageResponse> => {
  const { start_date, end_date } = getDateParams({ start, end })

  return useQuery<CreativeWastageResponse>({
    queryKey: [
      'wastage',
      'creativeTotal',
      org_unit_id,
      start_date,
      end_date,
      target_currency,
      providers && providers.sort(),
      org_unit_ids && org_unit_ids.sort(),
    ],
    queryFn: makeClientQueryFn(
      apiClients.core,
      {
        url: `/org-units/${org_unit_id}/creative-wastage`,
        method: 'GET',
        params: {
          start_date,
          end_date,
          target_currency,
          ...(providers && {
            provider_filter: providers,
          }),
          ...(org_unit_ids && {
            org_unit_filter: org_unit_ids,
          }),
        },
        paramsSerializer: qs.stringify
      },
    ),
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
    ...options,
    enabled: !!(start_date && end_date && org_unit_id) && (options.enabled === undefined || options.enabled),
  })
}


export type WastageExportParams = WastageParams & {
  variant: WastageVariant
  comparison_type?: WastageComparisonType | null
}

// Export
export const useWastageTrendExport = (): UseMutationResult<void, unknown, WastageExportParams> => {
  return useMutation({
    mutationFn: async ({
      variant,
      comparison_type,
      start,
      end,
      segment_type,
      org_unit_id,
      org_unit_ids,
      providers,
      target_currency = 'EUR',
    }: WastageExportParams) => {
      const { start_date, end_date } = getDateParams({ start, end })

      const response = await apiClients.core.get<Blob>(
        `/org-units/${org_unit_id}/export-wastage-with-segmentation`,
        {
          params: {
            variant,
            segment_type,
            start_date,
            end_date,
            target_currency,
            ...(comparison_type && {
              comparison_type
            }),
            ...(providers && {
              provider_filter: providers,
            }),
            ...(org_unit_ids && {
              org_unit_filter: org_unit_ids,
            }),
          },
          paramsSerializer: qs.stringify,
          responseType: 'blob',
          headers: {
            'Content-Type': 'text/csv',
          }
        }
      )

      // Get filename from content disposition header if available
      const contentDisposition: string = response.headers && response.headers['content-disposition']
      let filename = `${variant.toLowerCase()}_wastage_trend_reports.csv`
      if( contentDisposition ){
        const headerValues = contentDisposition.split(';').map( s => s.trim())
        const filenameValue = headerValues.find( v => v.startsWith('filename='))
        if( filenameValue ){
          filename = filenameValue.replace('filename=', '')
        }
      }

      saveAs(response.data, filename)
    }
  })
}
