import React, { useMemo } from 'react'
import {
  Box,
  Card,
  Divider,
  Grid,
  PlainTextButton,
  Typography,
  makeAppStyles,
  useAppTheme,
} from '@percept/mui'
import { ArrowRight, CloudDownload } from '@percept/mui/icons'
import { AssetLink } from 'components/AssetLink'
import { useUrlState } from '@percept/hooks'
import { mmmDataByIsoCode, MMMDatasetKey, MMMDataWrapper, mmmMarkets, MMMSegment } from './dataCache'
import { ChartData, Legend, MultiDataset, percentageFormatter, ResponsiveStackedHistogramWithTooltip } from '@percept/mui/charts'
import { MarketSelect } from 'components/MarketDisplay'
import { identity } from 'lodash-es'

const useStyles = makeAppStyles( theme => ({
  graphMiddleHeader: {
    textAlign: 'center',
    backgroundColor: theme.palette.grey[300],
    padding: '5px',
  },
  graphHeader:{
    paddingLeft: '45px',
    paddingRight: '45px'
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  euroSymbol: {
    height: '12px',
    width: '12px'
  },
  infoBox: {
    fontSize: '18px'
  },
  container: {
    padding: '16px',
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  detailTypography: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    marginBottom: theme.spacing(1.5),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}))

export type MMMMarketProps = {
  isGlobalUser: boolean
  defaultMarketId: string
}

type ViewState = {
  marketId: string | null
}

export const MMMMarket = ({
  isGlobalUser,
  defaultMarketId,
}: MMMMarketProps): JSX.Element => {

  const [{ marketId }, setState] = useUrlState<ViewState>({
    marketId: isGlobalUser ? mmmMarkets[0].id : defaultMarketId
  })

  const market = marketId && mmmMarkets.find( m => m.id === marketId ) || null
  const dataWrapper = market && mmmDataByIsoCode[market.iso_code]

  return (
    <Card elevation={2}>
      <Box display='flex' alignItems='center' justifyContent='space-between' m={1.5}>
        <Typography variant='h4'>Directional Recommendation</Typography>
        { isGlobalUser && (
          <MarketSelect
            value={marketId}
            options={mmmMarkets}
            onChange={(selectedMarket): void => {
              setState({ marketId: selectedMarket.id })
            }}
          />
        )}
      </Box>
      <Divider />
      { dataWrapper ? (
        <MMMGraph dataWrapper={dataWrapper} />
      ) : (
        <Box p={8} display='flex' justifyContent='center'>
          <Typography variant='h4'>
            Coming Soon
          </Typography>
        </Box>
      )}
    </Card>
  )
}


const segmentOrder: MMMSegment[] = [
  'BRAND_OFFLINE', 'BRAND_ONLINE', 'PERFORMANCE_OFFLINE', 'PERFORMANCE_ONLINE', 'BUSINESS'
]

const segmentLabels: Record<MMMSegment, string> = {
  BRAND_OFFLINE: 'Brand ATL',
  BRAND_ONLINE: 'Brand Digital',
  PERFORMANCE_OFFLINE: 'Performance ATL',
  PERFORMANCE_ONLINE: 'Performance Digital',
  BUSINESS: 'Business',
}

const datasetLabels: Record<MMMDatasetKey, string> = {
  ANNUAL_BASE: 'Annual Base',
  ANNUAL_RECO: 'Annual Reco',
  ANNUAL_L12M: 'Annual L12M',
}


export type MMMGraphProps = {
  dataWrapper: MMMDataWrapper 
}

export const MMMGraph = ({ dataWrapper }: MMMGraphProps): JSX.Element => {
  const classes = useStyles()

  const appTheme = useAppTheme()

  const { datasets, colourScale } = useMemo(() => {
    const colourScale = appTheme.chart.getOrdinalColourScale<MMMSegment>(segmentOrder)
    const datasetKeys: MMMDatasetKey[] = ['ANNUAL_BASE', 'ANNUAL_RECO', 'ANNUAL_L12M']
    const datasets = segmentOrder.map( segment => {
      const data: ChartData = datasetKeys.map( key => {
        const mmmDataset = dataWrapper.data[key]
        return {
          value: (mmmDataset[segment] || 0) * 100,
          label: datasetLabels[key],
          color: colourScale(segment),
        }
      })
      return {
        key: segment,
        label: segmentLabels[segment],
        data,
        color: colourScale(segment),
      }
    }) as MultiDataset[]
    return {
      datasets,
      colourScale,
    }
  }, [appTheme, dataWrapper])

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box width='100%' pt={3}>
            {/* <Grid container className={classes.graphHeader}> 
              <Grid item xs={5} className={classes.gridItem}>
                <Typography align='center'>
                  { formatMoney({ amount: 156000000, currency: 'EUR' }) }
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box className={classes.graphMiddleHeader}>
                  <Typography>Up to 178K contracts</Typography>
                  <Typography>
                    { formatMoney({ amount: 28000000, currency: 'EUR' }) }{' '}
                    annual revenue
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4} className={classes.gridItem}>
                <Typography align='center'>
                  { formatMoney({ amount: 156000000, currency: 'EUR' }) }
                </Typography>
              </Grid>
            </Grid> */}
            <Grid container> 
              <Grid item xs={12}>
                <ResponsiveStackedHistogramWithTooltip
                  height={400}
                  datasets={datasets}
                  barPaddingRatio={0.2}
                  axisText
                  grid
                  domain={[0, 100]}
                  xTickFormatter={identity}
                  yTickFormatter={percentageFormatter} />
                <Legend
                  filterZero={false}
                  ml={5}
                  mb={2}
                  mt={-2}
                  justifyContent='center'
                  data={
                    segmentOrder.map( segment => ({
                      value: null,
                      label: segmentLabels[segment],
                      color: colourScale(segment),
                    }))
                  } />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box width='100%' height='100%' display='flex' flexDirection='column' justifyContent='space-between' px={5} pt={3}>
            <Box className={classes.infoBox} height='100%'>
              {dataWrapper.recommendations.map( (recommendation, i) => (
                <Typography key={i} className={classes.detailTypography}>
                  <ArrowRight /> {recommendation}
                </Typography>
              ))}
            </Box>
            { !!dataWrapper.downloadAssetIdentifier && (
              <Box mx={1} my={3}>
                <AssetLink
                  assetIdentifier={dataWrapper.downloadAssetIdentifier}
                  showIcon={false}
                  className=''
                  linkContent={
                    <PlainTextButton
                      color='primary'
                      variant='contained'
                      endIcon={<CloudDownload />}>
                      Download Report
                    </PlainTextButton>
                  } />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
