import React, { useContext } from 'react'

import {
  Box,
  Gauge,
  Grid,
  Loader,
  SimpleTable,
  useAppTheme,
} from '@percept/mui'

import { OverviewAnalysis } from './OverviewAnalysis'
import { OverviewCard } from './OverviewCard'
import { OverviewDateControls } from './OverviewDateControls'
import { MarketDatumVisualisation } from './MarketDatumVisualisation'

import { useCreativeWastageByMarket, useNestedOrgUnitPotentialEfficiencyScores } from '@percept/hooks'

import { useMarketBrandInvestmentShareRows, useMarketOverviewDatasets } from './marketDataHooks'

import { get, some } from 'lodash-es'

import { getPreviousPotentialEfficiencyReferenceDate, resolveDateParams } from './utils'

import { getDateLabel, getTooltipContent, makeOverviewTableColumns, overviewDimensionMetadata } from './lib'

import { OverviewContext } from './overviewContext'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

import { BaseOverviewComponentProps, DerivedDimension, MarketDatum, OverviewEntity, OverviewTableRow } from './typings'

import { singleMarketAnalysis } from './singleMarketAnalysis'

import { quarterlyByMarket, vodafoneMarketsByIsoCode } from './fixtureData'
import { useCompetitiveSOSAllCompetitorsByMarket } from './globalDataHooks'
import { ComparisonMethodSelect } from './overviewComponents'
import { isoDate } from '@percept/utils'


export const MarketOverview = ({
  market,
  cellRenderers,
  classes,
  tableClasses,
}: BaseOverviewComponentProps & {
  market: OverviewEntity
}): JSX.Element => {

  const appTheme = useAppTheme()

  const { granularity, dateRanges, referenceDate, comparisonMethod, setComparisonMethod } = useContext(OverviewContext)

  const quarterlyData = quarterlyByMarket[market]

  const marketEntity = vodafoneMarketsByIsoCode[market]

  if( !marketEntity ){
    console.warn(`No market entity found matching ${market}`)
  }

  const primaryCreativeWastageByMarket = useCreativeWastageByMarket(resolveDateParams(dateRanges.primary))

  const comparisonCreativeWastageByMarket = useCreativeWastageByMarket(resolveDateParams(dateRanges.comparison))

  const primaryDigitalWastageByMarket = useNestedOrgUnitPotentialEfficiencyScores({
    org_unit_id: VODAFONE_GLOBAL_ID,
  })

  const secondaryDigitalWastageByMarket = useNestedOrgUnitPotentialEfficiencyScores({
    org_unit_id: VODAFONE_GLOBAL_ID,
    reference_date: isoDate(getPreviousPotentialEfficiencyReferenceDate()),
  })

  const derivedDatasets = useMarketOverviewDatasets({
    vodafoneMarket: marketEntity,
    granularity,
    dateRanges,
    comparisonMethod,
    primaryCreativeWastageByMarket: primaryCreativeWastageByMarket.data,
    comparisonCreativeWastageByMarket: comparisonCreativeWastageByMarket.data,
    potentialEfficiencyRatiosByMarket: primaryDigitalWastageByMarket.data,
    secondaryPotentialEfficiencyRatiosByMarket: secondaryDigitalWastageByMarket.data,
  })

  const brandInvestmentShareRows = useMarketBrandInvestmentShareRows({
    vodafoneMarket: marketEntity,
    referenceDate,
    comparisonMethod,
  })

  const allCompetitorsByMarket = useCompetitiveSOSAllCompetitorsByMarket({
    referenceDate,
    comparisonMethod,
  })

  const isLoading = some(
    [
      primaryCreativeWastageByMarket,
      comparisonCreativeWastageByMarket,
      primaryDigitalWastageByMarket,
      ...(granularity === 'year' && [
        secondaryDigitalWastageByMarket
      ] || []),
      brandInvestmentShareRows,
      allCompetitorsByMarket,
    ],
    hook => !hook.data
  )

  // const shareOfSpend = quarterlyData.breakdowns[0]
  const productionVsMedia = quarterlyData.scores[0]

  const dateControlledDimensions: DerivedDimension[] = [
    'digital_wastage', 'creative_wastage', 'total_wastage', 'creative_quality_score'
  ]

  const dateControlledScores: MarketDatum[] = dateControlledDimensions.reduce( (acc, dimension) => {
    const datum = derivedDatasets[dimension]
    if( datum ){
      acc.push(datum)
    }
    return acc
  }, [] as MarketDatum[])

  const marketAnalysis = singleMarketAnalysis[market]

  const shareOfSpendSegments = allCompetitorsByMarket.data && allCompetitorsByMarket.data[market] || []

  const shareOfSpendColourScale = appTheme.chart.getOrdinalColourScale(
    shareOfSpendSegments.map( s => s.label )
  )

  return (
    <>
      <OverviewAnalysis
        className={classes.fullHeightCard}
        elevation={3}
        items={marketAnalysis || []}
        GridItemProps={{
          xs: 6,
        }} />

      <Box mt={4}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <ComparisonMethodSelect
              value={comparisonMethod}
              onChange={setComparisonMethod} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OverviewCard
              className={classes.fullHeightCard}
              elevation={3}
              classes={classes}
              title={
                overviewDimensionMetadata.share_of_spend.label + ' ' + 
                getDateLabel('year', referenceDate)
              }
              category={overviewDimensionMetadata.share_of_spend.category}
              tooltipContent={overviewDimensionMetadata.share_of_spend.tooltipContent}>
              { !allCompetitorsByMarket.data ? (
                <Loader preset='fullsize' minHeight='20rem' />
              ) : (
                <> 
                <Box p={3}>
                  <Gauge
                    animate={false}
                    width='100%'
                    height='2.5rem'
                    data={
                      shareOfSpendSegments.map( s => ({
                        value: s.current,
                        color: shareOfSpendColourScale(s.label),
                        label: s.label,
                      }))
                    } />
                </Box>
                <SimpleTable
                  classes={tableClasses}
                  columns={ makeOverviewTableColumns({
                    ...overviewDimensionMetadata.share_of_spend,
                    rowLabel: 'Advertiser',
                  })}
                  rows={
                    shareOfSpendSegments.map((s): OverviewTableRow => {
                      return {
                        label: (
                          <span style={{display: 'flex', alignItems: 'center'}}>
                            <div
                              style={{
                                width: '1em',
                                height: '1em',
                                borderRadius: '0.5em',
                                marginRight: 8,
                                backgroundColor: shareOfSpendColourScale(s.label),
                              }} />
                            { s.label }
                          </span>
                        ),
                        score: s.current,
                        previous: s.previous,
                        delta: typeof s.change === 'undefined' ? null : s.change,
                        target: (
                          s.label === 'Vodafone' ?
                            get(vodafoneMarketsByIsoCode[market], 'shareOfSpendTarget', null) :
                            null
                        ),
                      }
                    })
                  }
                  renderers={cellRenderers} />
                </>
              )}
            </OverviewCard>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={5}>
              { brandInvestmentShareRows.data && (
                <Grid item xs={12}>
                  <OverviewCard
                    className={classes.fullHeightCard}
                    elevation={3}
                    classes={classes}
                    title={
                      overviewDimensionMetadata.brand_investment_share.label + ' ' + 
                      getDateLabel('year', referenceDate)
                    }
                    category={overviewDimensionMetadata.brand_investment_share.category}
                    tooltipContent={
                      <>
                       { overviewDimensionMetadata.brand_investment_share.tooltipContent }
                        <br />
                        <br />
                        { getTooltipContent('year', referenceDate) }
                      </>
                    }>
                    <SimpleTable
                      classes={tableClasses}
                      columns={
                        makeOverviewTableColumns({
                          ...overviewDimensionMetadata.brand_investment_share,
                          rowLabel: 'Segment',
                        })
                      }
                      rows={brandInvestmentShareRows.data}
                      renderers={cellRenderers} />
                  </OverviewCard>
                </Grid>
              )}

              <Grid item xs={12}>
                <MarketDatumVisualisation
                  isStaticData
                  datum={productionVsMedia}
                  granularity='year'
                  comparisonMethod={comparisonMethod}
                  appTheme={appTheme}
                  classes={classes} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <OverviewDateControls />
          </Grid>

          { isLoading ? (
            <Loader preset='fullsize' minHeight='20rem' />
          ) : (
            <>
              { dateControlledScores.map( score => {
                return (
                  <Grid key={score.dimension} item xs={12} sm={6} md={4}>
                    <MarketDatumVisualisation
                      datum={score}
                      granularity={granularity}
                      comparisonMethod={comparisonMethod}
                      classes={classes}
                      appTheme={appTheme} />
                  </Grid>
                )
              })}
            </>
          )}
        </Grid>
      </Box>
    </>
  )
}

