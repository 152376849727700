import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import { Grid, GridSize, makeAppStyles } from '@percept/mui'

import { vodafoneExternalLinks } from 'components/Links'

import { UserPrivilegeContext } from '@percept/app-components'

import { userHasGlobalPrivileges } from '@percept/utils'

import { GlobalPrivilege } from '@percept/types'


type LinkHierarchy = {
  name: string
  path?: string
  children: {
    name: string
    globalPrivileges?: GlobalPrivilege[] | null
    path?: string
    openInNewWindow?: boolean
  }[]
}


const useLinkHierarchy = (): LinkHierarchy[] => {
  const {
    global_privileges = [],
    user: { default_org_unit_id } = { default_org_unit_id: null }
  } = useContext(UserPrivilegeContext)

  const links: LinkHierarchy[] = [
    {
      name: 'Strategy',
      path: '/wizard/strategy',
      children: [
        {
          name: 'Pathways',
          path: '/wizard/strategy/pathways',
          openInNewWindow: true,
          globalPrivileges: ['vfMicrosites.pathways.view'],
        },
        // {
        //   name: 'Brand Growth Planner',
        //   path: '/wizard/strategy/brand-growth-planner',
        //   openInNewWindow: true,
        //   globalPrivileges: ['brandGrowthPlanner.viewAny'],
        // },
        {
          name: 'Media Mix Modelling',
          path: '/wizard/strategy/media-mix-modelling',
          openInNewWindow: true,
          globalPrivileges: ['vfMicrosites.mmm.view'],
        },
        {
          name: 'Representation',
          path: vodafoneExternalLinks.representation,
          openInNewWindow: true,
        },
      ]
    },
    {
      name: 'Optimisation',
      path: '/wizard/optimisation',
      children: [
        {
          name: 'Media Quality',
          path: default_org_unit_id ? `/dashboards/${default_org_unit_id}` : undefined,
          openInNewWindow: true,
          globalPrivileges: ['structuralReporting.viewAny', 'performanceOverview.viewAny'],
        },
        {
          name: 'Creative Quality',
          path: vodafoneExternalLinks.creativeX,
          openInNewWindow: true,
        },
        {
          name: 'Media Carbon Calculator',
          path: vodafoneExternalLinks.carbonCalculator,
          openInNewWindow: true,
        },
        /* NOTE - Partnerships application has been disabled */
        // {
        //   name: 'Partnerships',
        //   path: '/wizard/optimisation/partnerships',
        //   openInNewWindow: true,
        //   globalPrivileges: ['partnerships.viewAny'],
        // },
        {
          name: 'Sponsorships',
          path: '/wizard/optimisation/sponsorships',
          openInNewWindow: true,
          globalPrivileges: ['sponsorships.viewAny'],
        },
      ]
    },
    {
      name: 'Reporting',
      path: '/wizard/reporting',
      children: [
        {
          name: 'Media Investment',
          path: default_org_unit_id ? `/reporting/${default_org_unit_id}` : undefined,
          openInNewWindow: true,
          globalPrivileges: ['mediaInvestment.viewAny'],
        },
        {
          name: 'Smart Campaign Assessment',
          path: '/wizard/reporting/smart-campaign-assessment',
          openInNewWindow: true,
          globalPrivileges: ['vfSmartCampaignAssessment.view'],
        },
        {
          name: 'Wastage Trend Reports',
          path: '/wizard/reporting/wastage-trend-reports',
          openInNewWindow: true,
          globalPrivileges: ['vfWastageTrendReports.viewAny'],
        }
        // {
        //   name: 'KPIs',
        // },
        // {
        //   name: 'Brand Consistency',
        // },
      ]
    },
    {
      name: 'News',
      path: '/wizard/news',
      children: [
        {
          name: 'News',
        },
      ]
    }
  ]

  return links.map( linkHierarchy => ({
    ...linkHierarchy,
    children: linkHierarchy.children && linkHierarchy.children.filter( c => (
      !c.globalPrivileges ?
        true :
        userHasGlobalPrivileges(c.globalPrivileges, global_privileges || [])
    ))
  })).filter( l => !!l.children.length )
}


const useStyles = makeAppStyles( theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
  },
  page: {
    color: theme.palette.action.disabled,
    width: 'max-content',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '26px',
    marginBottom: theme.spacing(2),
  },
  childPage: {
    color: theme.palette.action.disabled,
    width: 'max-content',
    fontSize: 18,
    lineHeight: '26px',
    margin: theme.spacing(1, 0),
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline solid transparent',
    textUnderlineOffset: '5px',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: `underline solid ${theme.palette.primary.main}`,
    },
    transition: theme.transitions.create(['color', 'text-decoration']),
  },
}))


export const FooterLinks = (): JSX.Element => {
  const classes = useStyles()
  const linkHierarchy = useLinkHierarchy()
  let gridSize: GridSize = 12
  switch(linkHierarchy.length){
    case 4:
      gridSize = 3
      break
    case 3:
      gridSize = 4
      break
    case 2:
      gridSize = 6
      break
    case 1:
    default:
      gridSize = 12
  }
  return (
    <Grid className={classes.root} container spacing={4} justify='space-evenly'>
      { linkHierarchy.map( ({ name, path, children }) => (
        <Grid key={name} className={classes.gridItem} item xs={12} sm={gridSize}>
          { path ? (
            <Link to={path} className={`${classes.page} ${classes.link}`}>{ name }</Link>
          ) : (
            <span className={classes.page}>{ name }</span>
          )}
          { children.map( ({ name, path, openInNewWindow }) => (
            path && openInNewWindow ? (
              <a
                key={name}
                className={`${classes.childPage} ${classes.link}`}
                href={path}
                target='_blank'
                rel='noopener noreferrer'>
                { name }
              </a>
            ) : path ? (
              <Link key={name} to={path} className={`${classes.childPage} ${classes.link}`}>{ name }</Link>
            ) : (
              <span key={name} className={classes.childPage}>{ name }</span>
            )
          ))}
        </Grid>
      ))}
    </Grid>
  )
}
