import React from 'react'

import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'

import { RestrictedRoute } from '@percept/app-components'

// import { BrandGrowthPlanner } from 'screens/BrandGrowthPlanner'
import { Home } from 'screens/Home'
import { News } from 'screens/News'
import { Optimisation } from 'screens/Optimisation'
import { Overview } from 'screens/Overview'
import { Reporting } from 'screens/Reporting'
// import { Partnerships } from 'screens/Partnerships'
import { Strategy } from 'screens/Strategy'
import { Sponsorships } from 'screens/Sponsorships'
import { MediaMixModelling } from 'screens/MediaMixModelling'
import { Pathways } from 'screens/Pathways'
import { UserManagement } from 'screens/UserManagement'
import { SmartCampaignAssessment } from 'screens/SmartCampaignAssessment'
import { WastageTrendReports } from 'screens/WastageTrendReports'
import { Analytics } from 'screens/Analytics'
import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'
import { PageNotFound } from 'screens/PageNotFound'


const MediaWizardRoutes = (): JSX.Element => {
  
  const { path } = useRouteMatch() || { path: '' }

  const homePath = `${path}/home`

  return (
    <Switch>
      <Route exact path={homePath} component={Home} />

      <RestrictedRoute
        requiredGlobalPrivileges={
          [
            'vfMicrosites.overview.view',
            'vfMediaWizardOverview.viewAny',
          ]
        }
        exact
        fallbackRedirect={homePath}
        path={`${path}/overview`}
        component={Overview} />

      <Route exact path={`${path}/strategy`} component={Strategy} />

      {/* <RestrictedRoute
        requiredGlobalPrivileges={['brandGrowthPlanner.viewAny']}
        fallbackRedirect={homePath}
        exact
        path={`${path}/strategy/brand-growth-planner`}
        component={BrandGrowthPlanner} /> */}

      {/* BGP deprecated, add a redirect to catch any stale deeplinks */}
      <Route exact path={`${path}/strategy/brand-growth-planner`}>
        <Redirect to={`${path}/strategy`} />
      </Route>

      <RestrictedRoute
        requiredGlobalPrivileges={['vfMicrosites.mmm.view']}
        fallbackRedirect={homePath}
        exact
        path={`${path}/strategy/media-mix-modelling`}
        component={MediaMixModelling} />

      <RestrictedRoute
        requiredGlobalPrivileges={['vfMicrosites.pathways.view']}
        fallbackRedirect={homePath}
        exact
        path={`${path}/strategy/pathways`}
        component={Pathways} />

      <Route exact path={`${path}/optimisation`} component={Optimisation} />

      <RestrictedRoute
        requiredGlobalPrivileges={['sponsorships.viewAny']}
        fallbackRedirect={homePath}
        exact
        path={`${path}/optimisation/sponsorships`}
        component={Sponsorships} />

      {/* NOTE - Partnerships application has been disabled */}
      {/* <RestrictedRoute
        requiredGlobalPrivileges={['partnerships.viewAny']}
        fallbackRedirect={homePath}
        exact
        path={`${path}/optimisation/partnerships`}
        component={Partnerships} /> */}
      <Route
        exact
        path={`${path}/optimisation/partnerships`}>
        <Redirect to={homePath} />
      </Route>

      <Route exact path={`${path}/reporting`} component={Reporting} />

      <RestrictedRoute
        requiredGlobalPrivileges={['vfSmartCampaignAssessment.view']}
        fallbackRedirect={homePath}
        exact
        path={`${path}/reporting/smart-campaign-assessment`}
        component={SmartCampaignAssessment} />

      <RestrictedRoute
        requiredGlobalPrivileges={['vfWastageTrendReports.viewAny']}
        fallbackRedirect={homePath}
        exact
        path={`${path}/reporting/wastage-trend-reports`}
        component={WastageTrendReports} />

      <Route exact path={`${path}/news`} component={News} />

      <RestrictedRoute
        requiredGlobalPrivileges={['tenantManagement.viewAny']}
        fallbackRedirect={homePath}
        allowAdmin={true}
        exact
        path={`${path}/user-management`}
        component={UserManagement} />

      <RestrictedRoute
        requiredOrgPrivileges={['vfMediaWizard.analytics.view']}
        orgUnitId={VODAFONE_GLOBAL_ID}
        fallbackRedirect={homePath}
        exact
        path={`${path}/analytics`}
        component={Analytics} />

      <Route component={PageNotFound} />

    </Switch>
  )
}

export default MediaWizardRoutes
