import { format, isBefore, parse } from 'date-fns'
import { flatten, range } from 'lodash-es'

export const getFinancialYearStart = (date: Date): Date => {
  const YYYY = date.getFullYear()
  const financialYYYY = (
    date.getMonth() < 3 ?
      YYYY - 1 :
      YYYY
  )
  const financialYearStart = new Date(financialYYYY, 3, 1)
  return financialYearStart
}

export const parseFinancialYearStart = (fyLabel: string): Date => {
  const trimmed = fyLabel.replace('/', '').replace('FY', '').trim()
  if( trimmed.length !== 4 ){
    throw new Error(`Could not parse FY label: '${fyLabel}'`)
  }
  const financialYYYY = Number(`20${trimmed.slice(0, 2)}`)
  return new Date(financialYYYY, 3, 1)
}

const getMonthIndexForFinancialYear = (date: Date): number => {
  let monthIndex = date.getMonth()
  if( monthIndex < 3 ){
    return monthIndex + 9
  }
  return monthIndex -= 3
}

export const getMonthIndicesForFinancialYear = ({
  startMonth,
  endMonth
}: {
  startMonth: string;
  endMonth: string;
}): number[] => {
  const referenceDate = new Date()
  const minMonth = parse(startMonth, 'MMM', referenceDate)
  const maxMonth = parse(endMonth, 'MMM', referenceDate)
  const fyStartMonthIndex = getMonthIndexForFinancialYear(minMonth)
  const fyEndMonthIndex = getMonthIndexForFinancialYear(maxMonth)
  return range(fyStartMonthIndex, fyEndMonthIndex + 1)
}

export const getMonthRangeForFinancialYear = ({
  startMonth,
  startYear,
  endMonth,
  endYear,
  maxDate = null,
}: {
  startMonth: string;
  startYear: number;
  endMonth: string;
  endYear: number;
  maxDate?: Date | null;
}): Date[] => {

  const fyMonthIndices = getMonthIndicesForFinancialYear({ startMonth, endMonth })

  const canonicalMaxDate = maxDate || new Date()

  const reportDates = flatten(
    range(startYear, endYear + 1).reduce( (acc, yyyy) => {
      fyMonthIndices.forEach( monthIndex => {
        const normalisedMonthIndex = monthIndex < 9 ? monthIndex + 3 : monthIndex - 9
        const resolvedYYYY = monthIndex >= 9 ? yyyy + 1 : yyyy
        const normalisedDate = new Date(resolvedYYYY, normalisedMonthIndex, 1)
        if( isBefore(normalisedDate, canonicalMaxDate) ){
          acc.push(normalisedDate)
        }
      })
      return acc
    }, [] as Date[])
  )

  return reportDates
}

export const isoDate = (date: Date): string => (
  format(date, 'yyyy-MM-dd')
)
