import React from 'react'

import { Badge, BadgeProps, MenuAbstractionProps, RoundedPlainTextButtonMenu, makeAppStyles } from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { Flag, FlagName, FlagProps } from '@percept/mui/flags'


const useDisplayLabelStyles = makeAppStyles((theme) => ({
  marketLabelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  flag: {
    marginRight: theme.spacing(0.75),
  },
  badgeContainer: {
    paddingRight: 20,
  },
  reportCountBadge: {
    right: -16,
    top: '50%',
    fontWeight: 700,
  },
}))


type MarketDisplayItem = {
  name: string
  iso_code: string
  badgeCount?: number | null
}


export const MarketDisplayLabel = ({
  iso_code,
  name,
  badgeCount,
  badgeColor = 'secondary',
  FlagProps = {},
}: MarketDisplayItem & {
  FlagProps?: Partial<FlagProps>
  badgeCount?: number | null
  badgeColor?: BadgeProps['color']
}): JSX.Element => {
  const classes = useDisplayLabelStyles()
  const displayLabel = (
    <span className={classes.marketLabelRoot}>
      <Flag
        isoCode={iso_code as FlagName}
        size={16}
        rounded
        border='#999'
        className={classes.flag}
        {...FlagProps} />
      {name}
    </span>
  )
  if( badgeCount === undefined ){
    return displayLabel
  }
  return (
    <span className={classes.badgeContainer}>
      <Badge
        showZero
        color={badgeColor}
        classes={{
          badge: classes.reportCountBadge,
        }}
        badgeContent={badgeCount}>
        { displayLabel }
      </Badge>
    </span>
  )
}


type MarketSelectItem = {
  id: string
  name: string
  iso_code: string
  badgeCount?: number | null
}

export const MarketSelect = ({
  value,
  label,
  options,
  onChange,
  badgeColor,
  TriggerProps = {},
}: {
  value: string | null
  label?: React.ReactNode
  options: MarketSelectItem[]
  onChange: (marketSelectItem: MarketSelectItem) => void
  badgeColor?: BadgeProps['color']
  TriggerProps?: MenuAbstractionProps<{}>['TriggerProps']
}): JSX.Element => {

  const matchingMarket = options.find( o => o.id === value)
  
  return (
    <RoundedPlainTextButtonMenu
      value={value}
      label={
        label || (
          matchingMarket ?
            <MarketDisplayLabel
              {...matchingMarket}
              badgeColor={badgeColor} /> :
            'Loading...'
        )
      }
      TriggerProps={{
        variant: 'contained',
        endIcon: <ArrowDropDown />,
        ...TriggerProps,
      }}
      options={
        options.map( m => ({
          value: m.id,
          label: (
            <MarketDisplayLabel
              {...m}
              badgeColor={badgeColor} />
          )
        }))
      }
      onChange={(_, value: string): void => {
        const matchingMarket = options.find( o => o.id === value)
        if( matchingMarket ){
          onChange(matchingMarket)
        }
      }} />
  )
}
