import React from 'react'

import { useParams } from 'react-router'

import { ChannelLayout } from './ChannelLayout'

import { matchToSeriesId, useActivePlatformUnit } from 'components/Organisation'
import { SeriesViewManager } from 'components/Series'

import { get } from 'lodash-es'

import { coerceReportProvider } from '@percept/utils'

import { providerChannelMap } from '@percept/constants'

import { SeriesParams } from '@percept/types'


export const SeriesScreen = (): JSX.Element => {

  const { series_id } = useParams<SeriesParams>()

  const activePlatformUnit = useActivePlatformUnit()

  const series = matchToSeriesId(
    get(activePlatformUnit, 'report_series'),
    series_id,
  )

  const provider = series && coerceReportProvider(series.provider.slug)    

  const channel = provider && providerChannelMap[provider]
  return (
    <ChannelLayout
      channel={channel}>
      <SeriesViewManager key={series_id} series_id={series_id} />
    </ChannelLayout>
  )
}

export default SeriesScreen
