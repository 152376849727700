import { Box, Paper, Typography } from '@material-ui/core'
import React from 'react'
import { FilterItem } from '../Filters'
import { InvestmentReport, Report } from './InvestmentReport'
import { DashboardType } from 'redux/ReportingDashboard/reducers/filtersReducer'
import { DashboardTypeContext } from './contexts'
import { ErrorBoundary } from '@percept/app-components'
import { useDispatch } from 'react-redux'

import { reportError } from 'dashboard/src/redux/actions'
import { MediaInvestmentFilterPanel } from '../Filters/MediaInvestmentFilterPanel'


type DashboardLayoutProps = {
  filters: FilterItem[]
  dashboardType: DashboardType
  reports: Report[]
}

export const DashboardLayout = ({
  filters,
  dashboardType,
  reports,
}: DashboardLayoutProps): JSX.Element => {
  const dispatch = useDispatch()
  return (
    <DashboardTypeContext.Provider value={dashboardType}>
      <Box>
        <MediaInvestmentFilterPanel allowedFilters={filters} dashboardType={dashboardType} />
        {reports.map((el, idx) => (
          <ErrorBoundary
            key={idx}
            reportError={payload => {
              dispatch(reportError(payload))
            }}
            fallbackContent={
              <Box my={5} display='flex' justifyContent='center' borderRadius={4}>
                <Paper>
                  <Box p={3}>
                    <Typography variant='h5' color='textSecondary'>
                      An error occurred and the {el.title} report could not be loaded
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            }>
            <InvestmentReport key={idx} {...el} />
          </ErrorBoundary>
        ))}
      </Box>
    </DashboardTypeContext.Provider>
  )
}
