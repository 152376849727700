import { dateRangeCalculators, DateRangePresetOption } from '@percept/mui'

import { useLocationParams } from '@percept/hooks'

import { PropLoaderHook } from '@percept/hooks/libv2'

import { Nullable, SeriesParams } from '@percept/types'


export function makeSeriesPropLoader<T, P extends SeriesParams>(
  useThing: PropLoaderHook<T, P>
): PropLoaderHook<T, Omit<P, 'series_id'>> {
  const useCurriedThing: PropLoaderHook<T, Omit<P, 'series_id'>> = (params) => {
    const { series_id } = useLocationParams()
    const [thing, loader] = useThing({
      series_id,
      ...params
    } as Nullable<P>)
    return [thing, (updateParams): void => loader({ series_id, ...params, ...updateParams || {} } as any)]
  }
  return useCurriedThing
}


export type DateRangeUrlState = {
  dateRangePreset: DateRangePresetOption
  start?: string
  end?: string
}

export const resolveDateRangeFromUrlState = (
  urlState: DateRangeUrlState,
  maxDate: Date | null
): [Date | null, Date | null] => {
  if( urlState.dateRangePreset !== 'custom' ){
    return (
      maxDate === null ?
        [null, null] :
        dateRangeCalculators[urlState.dateRangePreset](maxDate)
    )
  }
  if( urlState.start && urlState.end ){
    return [new Date(urlState.start), new Date(urlState.end)]
  }
  return [null, null]
}
