import { useMutation, UseMutationOptions, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from 'react-query'
import { PrimaryApplication, SecondaryApplication } from '../UserEvents'
import { Dictionary } from '@percept/types'
import { apiClients, makeClientQueryFn, useNotifications } from '@percept/hooks'
import { omit } from 'lodash-es'


type UserFavouriteBase = {
  name: string
  primary_application: PrimaryApplication
  secondary_application: SecondaryApplication | null
  view_parameters: Dictionary | null
}

export type CreateUserFavouriteParams = UserFavouriteBase
export type EditUserFavouriteParams = UserFavouriteBase & { id: string }

export type UserFavourite = UserFavouriteBase & {
  id: string
  created: string
  modified: string
}


export const useUserFavourites = (): UseQueryResult<UserFavourite[]> => (
  useQuery({
    queryKey: ['userFavourites'],
    queryFn: makeClientQueryFn(
      apiClients.core,
      {
        url: '/users/me/favourites'
      }
    ),
    retry: false,
  })
)


export const useAddUserFavourite = (
  { onSuccess, onError, ...options }: Partial<UseMutationOptions<unknown, unknown, CreateUserFavouriteParams>> = {}
): UseMutationResult<unknown, unknown, CreateUserFavouriteParams> => {
  const queryClient = useQueryClient()
  const [, addNotification] = useNotifications()
  return useMutation({
    mutationKey: ['addUserFavourite'],
    mutationFn: async (userFavourite) => {
      const response = await apiClients.core.post(
        '/users/me/favourites',
        userFavourite
      )
      return response.data
    },
    onSuccess: (_, params, context) => {
      queryClient.invalidateQueries({
        queryKey: ['userFavourites']
      })
      onSuccess && onSuccess(_, params, context)
    },
    onError: (_, params, context) => {
      addNotification({
        type: 'error',
        name: 'Create Favourite Failed',
        message: `Favourite '${params.name}' could not be created`,
      })
      onError && onError(_, params, context)
    },
    ...options,
  })
}


export const useEditUserFavourite = (
  { onSuccess, onError, ...options }: Partial<UseMutationOptions<unknown, unknown, EditUserFavouriteParams>> = {}
): UseMutationResult<unknown, unknown, EditUserFavouriteParams> => {
  const queryClient = useQueryClient()
  const [, addNotification] = useNotifications()
  return useMutation({
    mutationKey: ['editUserFavourite'],
    mutationFn: async (userFavourite) => {
      const response = await apiClients.core.patch(
        `/users/me/favourites/${userFavourite.id}`,
        omit(userFavourite, ['id']),
      )
      return response.data
    },
    onSuccess: (_, params, context) => {
      queryClient.invalidateQueries({
        queryKey: ['userFavourites']
      })
      onSuccess && onSuccess(_, params, context)
    },
    onError: (_, params, context) => {
      addNotification({
        type: 'error',
        name: 'Edit Favourite Failed',
        message: `Favourite '${params.name}' could not be edited`,
      })
      onError && onError(_, params, context)
    },
    ...options,
  })
}

export const useDeleteUserFavourite = (
  { onSuccess, onError, ...options }: Partial<UseMutationOptions<unknown, unknown, EditUserFavouriteParams>> = {}
): UseMutationResult<unknown, unknown, EditUserFavouriteParams> => {
  const queryClient = useQueryClient()
  const [, addNotification] = useNotifications()
  return useMutation({
    mutationKey: ['deleteUserFavourite'],
    mutationFn: async (userFavourite) => {
      const response = await apiClients.core.delete(`/users/me/favourites/${userFavourite.id}`)
      return response.data
    },
    onSuccess: (_, params, context) => {
      queryClient.invalidateQueries({
        queryKey: ['userFavourites']
      })
      onSuccess && onSuccess(_, params, context)
    },
    onError: (_, params, context) => {
      addNotification({
        type: 'error',
        name: 'Delete Favourite Failed',
        message: `Favourite '${params.name}' could not be deleted`,
      })
      onError && onError(_, params, context)
    },
    ...options,
  })
}
