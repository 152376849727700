import React from 'react'

import {
  Box,
  BoxProps,
} from '@material-ui/core'

import { ArrowDropDown, CalendarToday, CalendarViewDay, Schedule } from '../../icons'

import { DateRangeInput, DateRangeInputProps, DateRangeValue } from './DateRangeInput'
import { ReportRange } from '../ReportRange'
import { RoundedPlainTextButtonMenu } from '../Menus'


import { getFinancialYearStart } from '@percept/utils'

import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns'

export type DateRangePreset = (
  | 'current-financial-year'
  | 'current-year'
  | 'last-year'
  | 'last-financial-year'
  | 'last-quarter'
  | 'last-month'
  | 'last-week'
  | 'last-7-days'
  | 'last-30-days'
  | 'yesterday'
  | 'today'
)

export const dateRangeCalculators: Record<DateRangePreset, (referenceDate: Date) => [Date, Date]> = {
  'current-financial-year': refDate => [
    getFinancialYearStart(refDate), refDate
  ],
  'current-year': refDate => [
    startOfYear(refDate), refDate,
  ],
  'last-financial-year': refDate => [
    getFinancialYearStart(subYears(refDate, 1)), subDays(getFinancialYearStart(refDate), 1)
  ],
  'last-year': refDate => [
    startOfYear(subYears(refDate, 1)), endOfYear(subYears(refDate, 1))
  ],
  'last-quarter': refDate => [
    startOfQuarter(subQuarters(refDate, 1)), endOfQuarter(subQuarters(refDate, 1))
  ],
  'last-month': refDate => [
    startOfMonth(subMonths(refDate, 1)), endOfMonth(subMonths(refDate, 1))
  ],
  'last-week': refDate => [
    startOfWeek(subWeeks(refDate, 1), { weekStartsOn: 1 }),
    endOfWeek(subWeeks(refDate, 1), { weekStartsOn: 1 })
  ],
  'today': () => {
    const today = new Date()
    return [today, today] 
  },
  'yesterday': () => {
    const yesterday = subDays(new Date(), 1)
    return [yesterday, yesterday]
  },
  'last-7-days': refDate => [
    subDays(refDate, 6), refDate,
  ],
  'last-30-days': refDate => [
    subDays(refDate, 29), refDate,
  ]
}


export type DateRangePresetOption = DateRangePreset | 'custom'

export const defaultDateRangePresetOptions: DateRangePresetOption[] = [
  'current-financial-year',
  'current-year',
  'last-financial-year',
  'last-year',
  'last-quarter',
  'last-month',
  'last-week',
  'custom',
]

export const dateRangePresetLabels: Record<DateRangePresetOption, string> = {
  'custom': 'Custom',
  'current-financial-year': 'Current Financial Year',
  'current-year': 'Current Year',
  'last-financial-year': 'Last Financial Year',
  'last-year': 'Last Year',
  'last-quarter': 'Last Quarter',
  'last-month': 'Last Month',
  'last-week': 'Last Week',
  'last-30-days': 'Last 30 Days',
  'last-7-days': 'Last 7 Days',
  'today': 'Today',
  'yesterday': 'Yesterday',
}

export const resolveDateRangePresetLabel = (
  dateRangePreset: DateRangePresetOption,
  dateRange: DateRangeValue,
): React.ReactNode => (
  dateRangePreset === 'custom' ?
    <ReportRange
      start={dateRange[0]}
      end={dateRange[1]}
      TypographyProps={{
        variant: 'inherit',
      }} /> :
    dateRangePresetLabels[dateRangePreset]
)


export type DateRangePresetInputProps = (
  Omit<DateRangeInputProps, 'onChange' | 'maxDate'> & {
    maxDate: Date | null
    dateRangePreset: DateRangePresetOption
    dateRangePresetOptions?: DateRangePresetOption[]
    onChange: (value: [Date, Date], dateRangePreset: DateRangePresetOption) => void
    BoxProps?: BoxProps
  }
)

export const DateRangePresetInput = ({
  dateRangePreset,
  value,
  dateRangePresetOptions = defaultDateRangePresetOptions,
  onChange,
  color,
  maxDate,
  BoxProps = {},
  ...props
}: DateRangePresetInputProps): JSX.Element => {
  return (
    <Box {...BoxProps}>
      <Box mb={3}>
        <RoundedPlainTextButtonMenu
          TriggerProps={{
            variant: 'contained',
            size: 'small',
            startIcon: <CalendarToday />,
            endIcon: <ArrowDropDown />,
          }}
          value={dateRangePreset}
          label={dateRangePresetLabels[dateRangePreset]}
          onChange={(e, preset: DateRangePreset | 'custom'): void => {
            const existingPreset = dateRangePreset
            let newRange = value
            if( preset !== 'custom' && maxDate ){
              newRange = dateRangeCalculators[preset](maxDate)
            }else if( existingPreset !== 'custom' && maxDate){
              newRange = dateRangeCalculators[existingPreset](maxDate)
            }
            onChange(newRange as [Date, Date], preset)
          }}
          options={
            dateRangePresetOptions.map( value => ({
              value,
              label: dateRangePresetLabels[value],
            }))
          } />
      </Box>
      <DateRangeInput
        variant='static'
        divided
        color={color}
        value={value}
        maxDate={maxDate}
        onChange={(dateRange): void => {
          onChange([new Date(dateRange[0]), new Date(dateRange[1])], 'custom')
        }}
        {...props} />
    </Box>
  )
}
