import React, { useContext, useEffect, useMemo, useRef } from 'react'

import {
  Box,
  ButtonDialog,
  Card,
  Checkbox,
  CheckboxGroup,
  FilterPanel,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Loader,
  RadioFormGroup,
  RoundedPlainTextButton,
  Typography,
  makeAppStyles,
  useAppTheme,
} from '@percept/mui'
import { ArrowDropDown, ChevronRightThin, CloudDownload, InfoOutlined } from '@percept/mui/icons'

import { TimeComparisonFilterInput, TimeComparisonValue } from './TimeComparisonFilter'

import { SmartCampaignAssessmentUpload } from './SmartCampaignAssessmentUpload'

import { SmartCampaignReportType, useSmartCampaignAssesmentQuadrantReport, useSmartCampaignFilters } from './hooks'

import { get, some, sortBy } from 'lodash-es'

import { isNullTimeRange, parseFilterDateRange, parseTimeRange, quadrantReportsToChartData } from './lib'

import { QuadrantType } from '@percept/types'
import { VODAFONE_GLOBAL_ID, VodafoneMarket, legacyMarkets, partnerMarkets, vodafoneMarkets } from 'vodafoneMarkets'
import { SmartCampaignListing } from './SmartCampaignListing'
import { SmartCampaignInfographic } from './SmartCampaignInfographic'
import { QuadrantReport } from './QuadrantCharts/QuadrantReport'
import { UserPrivilegeContext } from '@percept/app-components'
import { downloadSVG, isoDate, produceKeyedMapping, userHasOrgPrivileges } from '@percept/utils'
import { format } from 'date-fns'
import { quadrantHealth } from './QuadrantCharts'
import { useUrlState } from '@percept/hooks'
import { MarketDisplayLabel } from 'components/MarketDisplay'


const useStyles = makeAppStyles( theme => ({
  title: {
    fontSize: 38,
    lineHeight: 1.125,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: 18,
    lineHeight: 1.35,
    maxWidth: '68em',
  },
  control: {
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  checkbox: {
    marginLeft: theme.spacing(2),
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
  },
  chartHeader: {
    marginBottom: theme.spacing(1),
  },
  filterCard: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
  },
  timeFilterLabel: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    '&:first-of-type': {
      marginLeft: 0,
    },
    opacity: 0.65,
  },
  legend: {
    fontSize: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  legendItemSeparator: {
    color: theme.palette.action.disabled,
    marginRight: theme.spacing(1),
  },
  legendItemSmall: {
    marginRight: theme.spacing(0.5),
  },
  legendDateRangeSeparator: {
    fontSize: 13,
    margin: theme.spacing(0, 0.25),
  },
}))


type ComparisonType = 'vodafone' | 'vodafoneVsCompetitor' | 'competitor'

const comparisonLabels: Record<ComparisonType, string> = {
  vodafone: 'Vodafone',
  vodafoneVsCompetitor: 'Vodafone vs Competitors',
  competitor: 'Competitors',
}

const smartCampaignMarkets: VodafoneMarket[] = [
  ...vodafoneMarkets,
  ...legacyMarkets,
  ...partnerMarkets,
]

const marketsById = produceKeyedMapping(smartCampaignMarkets, 'id')

const getVodafoneMarkets = (marketIds: string[]): VodafoneMarket[] => {
  const markets = marketIds.reduce( (acc, id) => {
    if( marketsById[id] ){
      acc.push(marketsById[id])
    }
    return acc
  }, [] as VodafoneMarket[])
  return sortBy(markets, 'ordinal_no')
}

const displayList = (items: string[]): string => {
  if( !items.length ){
    return ''
  }
  if( items.length === 1 ){
    return items[0]
  }
  const commaSeparated = [
    ...items.slice(0, items.length - 1),
  ].join(', ')
  return `${commaSeparated} and ${items[items.length - 1]}`
}


type SmartCampaignAssessmentState = {
  markets: string[]
  comparisonType: ComparisonType
  showFlags: boolean
  baselineStart: string | null
  baselineEnd: string | null
  comparisonStart: string | null
  comparisonEnd: string | null
  quadrant: QuadrantType | null
}


const resolveDate = (value: string | null): Date | null => (
  value ? new Date(value) : null
)

const urlStateToTimeComparisonValue = (state: SmartCampaignAssessmentState): TimeComparisonValue => ({
  baseline: [resolveDate(state.baselineStart), resolveDate(state.baselineEnd)],
  comparison: [resolveDate(state.comparisonStart), resolveDate(state.comparisonEnd)],
})

const timeComparisonValueToUrlState = (value: TimeComparisonValue): Pick<
  SmartCampaignAssessmentState,
  'baselineStart' | 'baselineEnd' | 'comparisonStart' | 'comparisonEnd'
> => ({
  baselineStart: value.baseline[0] && isoDate(value.baseline[0]),
  baselineEnd: value.baseline[1] && isoDate(value.baseline[1]),
  comparisonStart: value.comparison[0] && isoDate(value.comparison[0]),
  comparisonEnd: value.comparison[1] && isoDate(value.comparison[1]),
})


type SmartCampaignAssessmentFilters = (
  Pick<SmartCampaignAssessmentState, 'comparisonType' | 'markets' | 'showFlags'>
  & {
    timeComparison: TimeComparisonValue
  }
)


export const SmartCampaignAssessment = (): JSX.Element => {

  const classes = useStyles()

  const privileges = useContext(UserPrivilegeContext)

  const filters = useSmartCampaignFilters()

  const availableDateRange = parseFilterDateRange(filters.data)

  const [state, setState] = useUrlState<SmartCampaignAssessmentState>({
    markets: [],
    comparisonType: 'vodafone',
    showFlags: true,
    baselineStart: availableDateRange.start && isoDate(availableDateRange.start),
    baselineEnd: availableDateRange.end && isoDate(availableDateRange.end),
    comparisonStart: null,
    comparisonEnd: null,
    quadrant: null,
  })

  const { markets, comparisonType, showFlags, quadrant } = state

  const timeComparison = useMemo(() => urlStateToTimeComparisonValue(state), [state])

  const filterState: SmartCampaignAssessmentFilters = {
    markets,
    comparisonType,
    showFlags,
    timeComparison,
  }

  useEffect(() => {
    if( filters.data && isNullTimeRange(timeComparison.baseline) ){
      const parsedRange = parseFilterDateRange(filters.data)
      if( parsedRange.start && parsedRange.end ){
        setState({
          baselineStart: parsedRange.start && isoDate(parsedRange.start),
          baselineEnd: parsedRange.end && isoDate(parsedRange.end),
        })
      }
    }
  }, [timeComparison, filters.data, setState])

  useEffect(() => {
    if( filters.data && !markets.length ){
      setState({ markets: filters.data.market_ids })
    }
  }, [filters.data, markets.length, setState])

  const availableMarkets = filters.data && getVodafoneMarkets(filters.data.market_ids) || []

  const selectedMarkets = availableMarkets.filter( m => markets.includes(m.id) )

  const allMarketsSelected = selectedMarkets.length === availableMarkets.length

  const baselineReportType: SmartCampaignReportType = comparisonType === 'competitor' ? 'COMPETITOR' : 'VODAFONE'

  const baselineCampaignAssessmentHook = useSmartCampaignAssesmentQuadrantReport({
    ...parseTimeRange(timeComparison.baseline),
    markets,
    reportType: baselineReportType,
  })

  const comparisonTimeRange = comparisonType !== 'vodafoneVsCompetitor' ? timeComparison.comparison : timeComparison.baseline
  const comparisonReportType: SmartCampaignReportType = comparisonType === 'vodafone' ? 'VODAFONE' : 'COMPETITOR'

  const comparisonCampaignAssessmentHook = useSmartCampaignAssesmentQuadrantReport({
    ...parseTimeRange(comparisonTimeRange),
    markets,
    reportType: comparisonReportType,
  })

  const isError = some(
    [baselineCampaignAssessmentHook, comparisonCampaignAssessmentHook],
    hook => hook.isError
  )

  const hasComparisonRange = (
    comparisonType === 'vodafoneVsCompetitor' || !isNullTimeRange(timeComparison.comparison)
  )

  const baselineCampaignCount = get(baselineCampaignAssessmentHook.data, 'total_campaign_count', null)

  const comparisonCampaignCount = (
    !hasComparisonRange ? null : get(comparisonCampaignAssessmentHook.data, 'total_campaign_count', null)
  )

  const chartData = (
    baselineCampaignAssessmentHook.data
    && (!hasComparisonRange ? true : comparisonCampaignAssessmentHook.data)
    && quadrantReportsToChartData({
      baseline: baselineCampaignAssessmentHook.data,
      comparison: comparisonCampaignAssessmentHook.data || null,
    })
    || null
  )

  const canUploadData = (
    userHasOrgPrivileges(
      ['vfSmartCampaignAssessment.edit'],
      VODAFONE_GLOBAL_ID,
      privileges.org_privileges,
    )
  )

  const appTheme = useAppTheme()

  const chartRef = useRef<SVGSVGElement | null>(null)

  const marketLabel = allMarketsSelected ? 'All Markets' : displayList(
    selectedMarkets.map( m => m.name )
  )

  return (
    <Box p={3}>
      <Typography variant='h1' className={classes.title}>Welcome to Smart Campaign Assessment</Typography>
      <Typography className={classes.text}>
        Smart Campaign Assessments are research driven reports that determine the performance and effectiveness of campaigns in
        driving consumer consideration and action. Of all the metrics tested, <strong>Ad Imprint</strong> has the greatest correlation
        to brand consideration, whilst <strong>Triggers</strong> determine the short-term action consumers are likely to take as a
        result of seeing the campaign. To drive future brand growth as well as short term action, campaigns should deliver on both metrics.
      </Typography>
      <Box mt={1}>
        <Typography className={classes.text}>
          The below quadrant chart reports on the effectiveness of both Vodafone and Competitor campaigns in doing so.
        </Typography>
      </Box>

      <Card className={classes.filterCard}>
        <FilterPanel
          values={filterState}
          onConfirm={(values): void => {
            setState({
              comparisonType: values.comparisonType,
              markets: values.markets,
              showFlags: values.showFlags,
              ...(timeComparisonValueToUrlState(values.timeComparison)),
            })
          }}
          valueOrder={['comparisonType', 'markets', 'timeComparison', 'showFlags']}
          displayConfig={{
            /* eslint-disable react/display-name, react/prop-types */
            comparisonType: {
              label: 'Report Type',
              nullable: false,
              render: (value): string => comparisonLabels[value],
            },
            timeComparison: {
              label: 'Time Range',
              nullable: false,
              render: (value): JSX.Element | null => {
                if( isNullTimeRange(value.comparison) ){
                  if( isNullTimeRange(value.baseline) ){
                    return null
                  }
                  return (
                    <span className={classes.legendItem}>
                      { format(value.baseline[0] as Date, 'MMMM yyyy')}
                      <ChevronRightThin fontSize='inherit' className={classes.legendDateRangeSeparator} />
                      { format(value.baseline[1] as Date, 'MMMM yyyy')}
                    </span>
                  )
                }
                return (
                  <span className={classes.legendItem}>
                    <span className={classes.timeFilterLabel}>
                      Baseline
                    </span>
                    { format(value.baseline[0] as Date, 'MMMM yyyy')}
                    <ChevronRightThin fontSize='inherit' className={classes.legendDateRangeSeparator} />
                    { format(value.baseline[1] as Date, 'MMMM yyyy')}
                    <span className={classes.timeFilterLabel}>
                      Comparison
                    </span>
                    { format(value.comparison[0] as Date, 'MMMM yyyy')}
                    <ChevronRightThin fontSize='inherit' className={classes.legendDateRangeSeparator} />
                    { format(value.comparison[1] as Date, 'MMMM yyyy')}
                  </span>
                )
              }
            },
            markets: {
              label: 'Markets',
              render: (value): JSX.Element => <MarketDisplayLabel {...marketsById[value]} />,
            },
            showFlags: {
              label: 'View',
              nullable: false,
              render: (value): string => value ? 'Show Flags' : 'Hide Flags',
            },
            /* eslint-enable react/display-name, react/prop-types */
          }}>
          { ({ values, updateValues }): JSX.Element => (
            <Grid container spacing={5}>
              <Grid item xs='auto'>
                <RadioFormGroup
                  name='Report Type'
                  value={values.comparisonType}
                  options={
                    (['vodafone', 'vodafoneVsCompetitor', 'competitor'] as ComparisonType[]).map( value => ({
                      value,
                      label: comparisonLabels[value],
                    }))
                  }
                  onChange={(comparisonType): void => {
                    updateValues({ comparisonType })
                  }} />
              </Grid>

              <Grid item xs='auto'>
                <CheckboxGroup
                  name='Markets'
                  value={values.markets}
                  options={
                    (filters.data && getVodafoneMarkets(filters.data.market_ids) || []).map( option => ({
                      value: option.id,
                      label: <MarketDisplayLabel {...option} />,
                    }))
                  }
                  onChange={(value): void => {
                    updateValues({ markets: value })
                  }} />
              </Grid>

              <Grid item xs='auto'>
                <FormLabel>Time Filter</FormLabel>
                <TimeComparisonFilterInput
                  comparisonEnabled={values.comparisonType !== 'vodafoneVsCompetitor'}
                  value={values.timeComparison}
                  onChange={(value): void => {
                    updateValues({ timeComparison: value })
                  }}
                  minDate={availableDateRange.start as Date}
                  maxDate={availableDateRange.end as Date} />
              </Grid>

              <Grid item xs='auto'>
                <FormGroup>
                  <FormLabel>View</FormLabel>
                  <FormControlLabel
                    label='Show Flags'
                    control={<Checkbox />}
                    checked={values.showFlags}
                    onChange={(e, checked): void => {
                      updateValues({ showFlags: checked })
                    }} />
                </FormGroup>
              </Grid>
            </Grid>
          )}
        </FilterPanel>
      </Card>

      <Box my={4} display='flex' justifyContent='flex-end' alignItems='center'>

        { chartData && (
          <>
            <ButtonDialog
              ButtonComponent={RoundedPlainTextButton}
              buttonContent='Chart Explainer'
              startIcon={<InfoOutlined />}
              size='small'
              className={classes.control}
              DialogProps={{
                maxWidth: false,
                BackdropProps: {
                  classes: {
                    root: classes.backdrop,
                  }
                }
              }}
              variant='contained'>
              <SmartCampaignInfographic />
            </ButtonDialog>

            {/* NOTE - currently commented until we add titling and legends to the SVG */}
            {/* <RoundedPlainTextButton
              className={classes.control}
              size='small'
              color='secondary'
              variant='contained'
              startIcon={<CloudDownload />}
              onClick={(): void => {
                if( chartRef.current ){
                  downloadSVG(chartRef.current, 'smart-campaign-assessment.svg').then(console.log)
                }
              }}>
              Export Chart
            </RoundedPlainTextButton> */}

            { canUploadData && (
              <SmartCampaignAssessmentUpload
                ButtonProps={{
                  className: classes.control,
                  size: 'small'
                }} />
            )}
          </>
        )}
      </Box>
      
      { isError ? (
        <Box p={6}>
          <Typography variant='h5'>
            Quadrant report could not be loaded
          </Typography>
        </Box>
      ) : !chartData ? (
        <Loader preset='fullsize' minHeight={700} />
      ) : (
        <Box display='flex' flexBasis='100%' width='100%' flexDirection='column' alignItems='center'>
          <Typography
            className={classes.chartHeader}
            variant='h5'>
            { marketLabel }
          </Typography>

          <QuadrantReport
            {...chartData}
            svgRef={chartRef}
            flagsEnabled={showFlags}
            onQuadrantClick={(quadrant): void => {
              setState({ quadrant })
            }}
            width={700}
            height={700} />

          <Box mt={2} pl={'50px'} display='flex' flexDirection='column' alignItems='flex-start' width={700}>
            { baselineCampaignCount !== null && (
              <Typography className={classes.legend}>
                <svg className={classes.legendItem} width={24} height={24}>
                  <rect
                    width='50%'
                    height='50%'
                    x={0}
                    y={0}
                    fill={appTheme.chart.healthColourScale(quadrantHealth.top_left)} />
                  <rect
                    width='50%'
                    height='50%'
                    x='50%'
                    y={0}
                    fill={appTheme.chart.healthColourScale(quadrantHealth.top_right)} />
                  <rect
                    width='50%'
                    height='50%'
                    x='50%'
                    y='50%'
                    fill={appTheme.chart.healthColourScale(quadrantHealth.bottom_right)} />
                  <rect
                    width='50%'
                    height='50%'
                    x={0}
                    y='50%'
                    fill={appTheme.chart.healthColourScale(quadrantHealth.bottom_left)} />
                </svg>
                <strong className={classes.legendItem}>Baseline</strong>
                <span className={classes.legendItemSeparator}>|</span>
                <span className={classes.legendItem}>
                  { comparisonType === 'competitor' ? 'Competitor' : 'Vodafone'}
                </span>
                { !isNullTimeRange(timeComparison.baseline) && (
                <>
                  <span className={classes.legendItemSeparator}>|</span>
                  <span className={classes.legendItem}>
                    { format(timeComparison.baseline[0] as Date, 'MMMM yyyy')}
                    <ChevronRightThin fontSize='inherit' className={classes.legendDateRangeSeparator} />
                    { format(timeComparison.baseline[1] as Date, 'MMMM yyyy')}
                  </span>
                </>
                )}
                <span className={classes.legendItemSeparator}>|</span>
                {baselineCampaignCount} Campaigns
              </Typography>
            )}
            { comparisonCampaignCount !== null && (
              <Typography className={classes.legend}>
                <svg className={classes.legendItem} width={24} height={24}>
                  <rect width={22} height={22} x={1} y={1} stroke='#000' strokeWidth={2} strokeDasharray='6 3' fill='none' />
                </svg>
                <strong className={classes.legendItem}>Comparison</strong>
                <span className={classes.legendItemSeparator}>|</span>
                <span className={classes.legendItem}>
                  { comparisonType === 'vodafone' ? 'Vodafone' : 'Competitor'}
                </span>
                { !isNullTimeRange(comparisonTimeRange) && (
                <>
                  <span className={classes.legendItemSeparator}>|</span>
                  <span className={classes.legendItem}>
                    { format(comparisonTimeRange[0] as Date, 'MMMM yyyy')}
                    <ChevronRightThin fontSize='inherit' className={classes.legendDateRangeSeparator} />
                    { format(comparisonTimeRange[1] as Date, 'MMMM yyyy')}
                  </span>
                </>
                )}
                <span className={classes.legendItemSeparator}>|</span>
                {comparisonCampaignCount} Campaigns
              </Typography>
            )}
          </Box>
        </Box>
      )}

      { quadrant && (
        <SmartCampaignListing
          quadrant={quadrant}
          markets={markets}
          marketLabel={marketLabel}
          baseline={{
            dateRange: timeComparison.baseline,
            reportType: baselineReportType,
          }}
          comparison={
            !hasComparisonRange ?
              null : {
                dateRange: comparisonTimeRange,
                reportType: comparisonReportType,
              }
          }
          onClose={(): void => setState({ quadrant: null })} />
      )}

    </Box>
  )
}
