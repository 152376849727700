import React, { Fragment, useContext, useMemo } from 'react'

import {
  AppBar,
  BackdropLoader,
  Box,
  ButtonProps,
  Divider,
  Grid,
  Health,
  makeAppStyles,
  ReportRange,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  Tab,
  Tabs,
} from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import {
  InsightContext,
  InsightsReport as InsightsReportComponent,
  ReportSeriesPerformance,
} from '@percept/app-components'

import { ReportLink } from 'components/Series/ReportLink'

import {
  useReportSeries,
  useReportSeriesReportInsightsReport,
  useReportSeriesReports,
} from '@percept/hooks'

import { find, some, sortBy } from 'lodash-es'

import { deslugify } from '@percept/utils'

import {
  InsightsReportTypeParams,
  ReportProviderV2,
  SeriesParams,
  StructuralReportResultSubtype,
} from '@percept/types'


const getInsightReportTypeLabel = (insightReportType: StructuralReportResultSubtype, provider: ReportProviderV2): React.ReactNode => {
  if( provider === 'GOOGLE_ADS' ){
    if( insightReportType === 'PRIMARY' ){
      return 'Search'
    }
  }
  return deslugify(insightReportType)
}


const useStyles = makeAppStyles( theme => ({
  gridHeaderItem: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  infoBox: {
    display: 'inline-flex',
    fontSize: 14,
    color: theme.palette.text.primary,
    margin: theme.spacing(2, 0, 0, 0),
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      margin: theme.spacing(0, 2),
      top: -2,
    },
  },
  headerDateRange: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(4),
    fontSize: 16,
    fontWeight: 500,
  },
  viewTypeSelector: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: 'auto',
  },
  typeSelectorAppBar: {
    height: 'unset !important',
    padding: 0,
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  typeSelectorTabsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  typeSelectorTab: {
    textTransform: 'none',
  },
  typeSelectorTabIndicator: {
    backgroundColor: theme.palette.secondary.contrastText,
  },
}))


export type InsightsReportProps = (
  SeriesParams
  & InsightsReportTypeParams
  & {
    activeReportId: string | null
    setActiveReportId: (value: string) => void
    gridInserts?: JSX.Element[]
    title?: React.ReactNode
    showReportLink?: boolean
    showSubTypeOptions?: boolean
  }
)

export const InsightsReport = ({
  series_id,
  insights_report_type,
  gridInserts = [],
  title,
  activeReportId,
  setActiveReportId,
  showReportLink = false,
  showSubTypeOptions = false,
}: InsightsReportProps): JSX.Element => {

  const [series] = useReportSeries({ series_id })

  const [reports] = useReportSeriesReports({ series_id })

  const [state, setState] = useContext(InsightContext)

  const activeInsightReportType = state.verticals && state.verticals[0] || 'PRIMARY'

  const completedReports = useMemo(() => {
    return sortBy(reports.data, 'end').reverse().filter(
      r => !!(
        r.results.length && some(
          r.results,
          res => res.result_type === 'INSIGHTS_REPORT' && res.result_subtype === activeInsightReportType
        )
      )
    )
  }, [reports.data, activeInsightReportType])

  const latestReport = completedReports[0] || null

  const latestReportId = latestReport && latestReport.id

  const reportId = activeReportId || latestReportId 

  const activeReport = reportId && find(completedReports, r => r.id === reportId ) || null

  const subTypeOptions = (
    activeReport &&
    sortBy(
      activeReport.results.reduce( (acc, r) => {
        if( r.result_type === 'INSIGHTS_REPORT' ){
          acc.push(r.result_subtype)
        }
        return acc
      }, [] as StructuralReportResultSubtype[]),
      subtype => subtype === 'PRIMARY' ? 0 : subtype.charCodeAt(0)
    )
  )

  const [insightsReport] = useReportSeriesReportInsightsReport({
    report_id: reportId,
    series_id,
    insights_report_type: activeInsightReportType,
  })

  const classes = useStyles()

  if( (!title && !insightsReport.data) || !reports.data || !activeReport || !series.data ){
    return <BackdropLoader />
  }

  const health = (
    series.data.provider.slug === 'DV360' ?
      insightsReport.data && insightsReport.data.health :
      null
  )

  const selectTriggerProps: Partial<ButtonProps> = {
    variant: 'contained',
    size: 'small',
    color: 'secondary',
  }

  const selectLabel = (
    <ReportRange
      disableTypography
      start={activeReport.start}
      end={activeReport.end} />
  )

  return (
    <Box px={3} mt={5}>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            className={classes.gridHeaderItem}
            display='inline-flex'>

            <Box
              display='flex'
              flexGrow={showReportLink ? 1 : 0}
              alignItems='center'
              fontSize={22}
              fontWeight={700}>

              { title || insightsReport.data && insightsReport.data.name }

              <Box mx={2}>
                { completedReports.length === 1 ? (
                  <RoundedPlainTextButton
                    {...selectTriggerProps}>
                    { selectLabel }
                  </RoundedPlainTextButton>
                ) : (
                  <RoundedPlainTextButtonMenu
                    TriggerProps={{
                      ...selectTriggerProps,
                      endIcon: <ArrowDropDown />,
                    }}
                    value={activeReportId}
                    label={selectLabel}
                    options={
                      completedReports.map( r => ({
                        value: r.id,
                        label: (
                          <ReportRange
                            disableTypography
                            start={r.start}
                            end={r.end} />
                        )
                      }))
                    }
                    onChange={(e, value: string): void => {
                      setActiveReportId(value)
                    }}
                  />
                )}
              </Box>

              { health !== null && (
                <Health
                  animate={false}
                  value={health}
                  fontSize='22px' />
              )}

              { showReportLink && (
                <Box ml='auto'>
                  <ReportLink
                    report={activeReport}
                    series_id={series_id}
                    text={
                      reportId === latestReportId ?
                        'View Latest Report' :
                        'View Report'
                    } />
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        { gridInserts && (
          <Fragment>
            { gridInserts.map( (content, i) => (
              <Fragment key={`grid-insert-${i}`}>
                <Grid item xs={12}>
                  { content }
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Fragment>
            ))}
          </Fragment>
        )}

        <Grid item xs={12}>
          <ReportSeriesPerformance
            series_id={series_id}
            segmentation={insights_report_type === 'video' ? 'video' : null} />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      { showSubTypeOptions && subTypeOptions && subTypeOptions.length > 1 && (
        <Box mt={4} mb={2}>
          <AppBar
            position='static'
            color='secondary'
            classes={{root: classes.typeSelectorAppBar}}>
            <Tabs
              value={activeInsightReportType}
              classes={{
                indicator: classes.typeSelectorTabIndicator,
              }}
              onChange={(e, value) => {
                setState({ verticals: [value] })
              }}>
              { subTypeOptions.map( subType => (
                <Tab
                  key={subType}
                  value={subType}
                  label={getInsightReportTypeLabel(subType, series.data.provider.slug)}
                  classes={{
                    root: classes.typeSelectorTab,
                  }} />
              ))}
            </Tabs>
          </AppBar>
        </Box>
      )}

      { reportId && (
        <InsightsReportComponent
          report_id={reportId}
          series_id={series_id}
          insights_report_type={activeInsightReportType}
          viewType={state.view || 'LAYOUT'}
          setViewType={(view): void => {
            setState({ view })
          }}
          BoxProps={{mt: 2}} />
      )}
    </Box>
  )
}
