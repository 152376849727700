import React, { useEffect, useRef, useState } from 'react'

import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Loader,
  RoundedPlainTextButton,
  Typography,
  makeAppStyles,
} from '@percept/mui'
import { Close, CloudDownload, ErrorOutline } from '@percept/mui/icons'

import { format, startOfMonth, subMonths } from 'date-fns'

import { useExCoDownload } from './apiHook'
import { isoDate } from '@percept/utils'


const useStyles = makeAppStyles( theme => ({
  appBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
  },
  closeIcon: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  content: {
    paddingTop: theme.spacing(3),
  },
}) )


const ExCoReportLoader = ({
  onClose
}: {
  onClose: () => void
}): JSX.Element => {

  const [reportRequested, setReportRequested] = useState(false)

  const { download, isLoading, isSuccess, error } = useExCoDownload()

  const downloadRef = useRef(download)
  downloadRef.current = download

  const closeRef = useRef(onClose)
  closeRef.current = onClose

  useEffect(() => {
    if( !reportRequested && !isLoading ){
      const lastMonth = startOfMonth(subMonths(new Date(), 1))
      const reportStart = new Date('2022-04-01')
      downloadRef.current({
        start_date: isoDate(reportStart),
        end_date: isoDate(lastMonth),
      })
      setReportRequested(true)
    }
  }, [isLoading, reportRequested])

  useEffect(() => {
    if( isSuccess ){
      closeRef.current()
    }
  }, [isSuccess])

  const classes = useStyles()

  return (
    <Dialog
      open
      maxWidth='xs'
      fullWidth
      onClose={onClose}>

      <AppBar
        className={classes.appBar}
        color='primary'
        position='relative'>
        <IconButton
          className={classes.closeIcon}
          color='inherit'
          onClick={onClose}>
          <Close />
        </IconButton>
        <Typography variant='h4'>
          ExCo Report
        </Typography>
      </AppBar>

      <DialogContent className={classes.content}>
        { error ? (
          <Box my={3} display='flex' alignItems='center' justifyContent='center'>
            <Box display='flex' mr={1}>
              <ErrorOutline color='error' />
            </Box>
            <Typography variant='h5'>
              ExCo Report could not be downloaded
            </Typography>
          </Box>
        ) : (
          <Loader mt={1} preset='fullsize' minHeight='8rem'>
            <Box my={3}>
              <Typography variant='h5'>
                Downloading ExCo Report...
              </Typography>
            </Box>
          </Loader>
        )}
      </DialogContent>
    </Dialog>
  )
}


export const ExCoReportDownloadTrigger = ({
  trigger
}: {
  trigger?: (props: { onClick: () => void }) => JSX.Element
}): JSX.Element => {
  const [open, setOpen ] = useState(false)
  const onClick = (): void => {
    setOpen(true)
  }
  return (
    <>
      { trigger ? (
        trigger({ onClick })
      ) : (
        <RoundedPlainTextButton
          variant='contained'
          startIcon={<CloudDownload />}
          onClick={onClick}>
          Download ExCo Report
        </RoundedPlainTextButton>
      )}

      { open && (
        <ExCoReportLoader
          onClose={(): void => (
            setOpen(false)
          )} />
      )}
    </>
  )
}
