import React, { Fragment, useEffect, useMemo } from 'react'

import {
  Alert,
  BackdropLoader,
  BackdropProps,
  Box,
  Divider,
  Typography,
} from '@percept/mui'

import { QualityAnalysis } from 'components/QualityAnalysis'

import { PerformanceControls } from 'components/PerformanceControls'

import { PotentialEfficiencies } from './PotentialEfficiencies/PotentialEfficiencies'

import { ChannelSummaryProps } from 'components/ChannelSummary'

import {
  AnyPerformanceDimension,
  DoubleVerifyProvider,
  NormalizedError,
  PerformanceComparisonRange,
  PlatformUnit,
  PlatformUnitProviderInfo,
  PrimaryPerformanceDataProvider,
} from '@percept/types'

import { stringifyUrlSearchParams, useChannelPillarScores, useDocumentTitle, usePlatformUnit, useUrlState } from '@percept/hooks'

import { DashboardContext, DashboardUrlParams } from 'contexts'

import { resolvePerformanceReportingDimensions } from 'components/Organisation/lib'

import { isLoading } from '@percept/utils'

import { CrossChannelPerformanceSummary } from './CrossChannelPerformanceSummary'
import { useSavedQueryStrings } from 'hooks'
import { useLocation } from 'react-router'


export type CrossChannelDashboardProps = {
  platformUnit: PlatformUnit | null
  providers: PrimaryPerformanceDataProvider[]
  doubleVerifyProviders?: DoubleVerifyProvider[] | null
  providerInfo: PlatformUnitProviderInfo | null
  referenceDate: Date | null
  latestAvailableReferenceDate: Date | null
  activeComparison?: PerformanceComparisonRange
  mainHeader?: React.ReactNode
  secondaryHeaderContent?: React.ReactNode
  spendLabel?: string
  qualityAnalysisHeader?: React.ReactNode
  error?: NormalizedError
  displayLoader?: boolean
  showContent?: boolean
} & Pick<ChannelSummaryProps, 'seriesListing' | 'onSeriesClick'>



export const CrossChannelDashboard = ({
  mainHeader,
  secondaryHeaderContent,
  error,
  displayLoader = false,
  showContent = true,
  platformUnit,
  providers,
  referenceDate,
  latestAvailableReferenceDate,
}: CrossChannelDashboardProps): JSX.Element => {

  const org_unit_id = platformUnit && platformUnit.id

  const [platformUnitDetail] = usePlatformUnit({
    org_unit_id,
  })

  const [pillarScores] = useChannelPillarScores({
    org_unit_id,
  })

  const urlStateHookValue = useUrlState<DashboardUrlParams>({
    currency: 'EUR',
    dimension: process.env.APP === 'vodafone' ? 'potential_efficiency' : 'cost',
  })

  const [state, setState] = urlStateHookValue

  const [, setSavedQueryStrings] = useSavedQueryStrings()

  const location = useLocation()
  
  useEffect(() => {
    if( !location.pathname.includes('performance') ){
      setSavedQueryStrings({ dashboardOverview: stringifyUrlSearchParams(state) })
    }
  }, [state, setSavedQueryStrings, location.pathname])

  const stillLoading = isLoading(pillarScores)

  const availableDimensions = useMemo(() => {
    if( platformUnitDetail.data ){
      return resolvePerformanceReportingDimensions({
        platformUnit: platformUnitDetail.data,
      })
    }
    return undefined
  }, [platformUnitDetail.data])

  useEffect(() => {
    if(
      !location.pathname.includes('performance')
      && availableDimensions
      && state.dimension !== 'potential_efficiency'
      && !availableDimensions.includes(state.dimension as AnyPerformanceDimension)
    ){
      setState({ dimension: availableDimensions[0] })
    }
  }, [availableDimensions, state.dimension, setState, location.pathname])

  useDocumentTitle({
    paths: [
      platformUnitDetail.data && platformUnitDetail.data.name,
      'Overview',
    ]
  })

  const shouldRender = Boolean(
    showContent
    && !stillLoading
    && platformUnit
    && availableDimensions
    && (
      state.dimension === 'potential_efficiency'
      || availableDimensions.includes(state.dimension as AnyPerformanceDimension)
    )
  )

  const backdropProps: Partial<BackdropProps> = {
    open: displayLoader || !shouldRender
  }

  return (
    <DashboardContext.Provider value={urlStateHookValue}>

      <Box
        my={4}
        mx={3}>

        { error && (
          <Alert
            variant='error'
            maxWidth='30em'
            mx='auto'
            {...error} />
        )}

        { !!(
          shouldRender && platformUnit
        ) && (
          <Fragment>

            <Box mt={5} mb={3}>
              <Typography variant='h5'>
                Quality Analysis
              </Typography>
            </Box>

            <QualityAnalysis
              provider={null}
              platformUnit={platformUnit} />
            
            <Box my={3}>
              <Divider />
            </Box>
            
            <PerformanceControls
              platformUnit={platformUnit}
              availableDimensions={availableDimensions || []}
              referenceDate={referenceDate}
              maxDate={latestAvailableReferenceDate}
              doubleVerifyReferenceDate={null}
              requiresDoubleVerifyTrigger={false} />

            <Box mt={4}>
              { state.dimension === 'potential_efficiency' ? (
                <PotentialEfficiencies
                  suppressLoader={!showContent}
                  org_unit_id={platformUnit.id} />
              ) : (
                <CrossChannelPerformanceSummary
                  platformUnit={platformUnit}
                  providers={providers}
                  referenceDate={referenceDate} />
              )}
            </Box>

          </Fragment>
        )}

      </Box>

      { backdropProps.open && (
        <BackdropLoader
          BackdropProps={backdropProps} />
      )}

    </DashboardContext.Provider>
  )

}

export default CrossChannelDashboard
