import React, { useMemo, useState } from 'react'

import { useLocation } from 'react-router'

import { Link } from 'react-router-dom'

import {
  Box,
  CircularProgress,
  ConfirmDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  IconButtonPopover,
  IconButtonPopoverProps,
  Input,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Loader,
  makeAppStyles,
  RoundedPlainTextButton,
  Typography,
} from '@percept/mui'

import { Bookmarks, Bookmark, Edit, Close, Check, DeleteForever } from '@percept/mui/icons'

import {
  useAddUserFavourite,
  useDeleteUserFavourite,
  useEditUserFavourite,
  UserFavourite,
  useUserFavourites,
} from './hooks'

import { areLocationsEqual, LocationLike, locationToUrl, locationToUserFavourite, userFavouriteToLocation } from './lib'

import { some, tail } from 'lodash-es'

import { getPrimaryApplicationLabel, getSecondaryApplicationLabel } from '../UserEvents'


const useDialogStyles = makeAppStyles({
  container: {
    background: 'rgba(0,0,0,0.35)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  titleSeparator: {
    marginLeft: 8,
    marginRight: 8,
    opacity: 0.5,
  },
})

export const AddUserFavouriteDialog = ({
  location,
  onClose,
  favourite = null,
}: {
  favourite?: UserFavourite | null
  location: LocationLike
  onClose: () => void
}): JSX.Element => {
  const addUserFavourite = useAddUserFavourite({ onSuccess: onClose })
  const editUserFavourite = useEditUserFavourite({ onSuccess: onClose })
  const [favouriteName, setFavouriteName] = useState(
    favourite ?
      favourite.name :
      tail(document.title.split(' | ')).join(' | ')
  )
  const parsedFavourite = favourite || locationToUserFavourite(location)
  const classes = useDialogStyles()
  const titleFragment = favourite ? 'Edit' : 'Add'
  return (
    <Dialog
      open
      classes={{
        container: classes.container,
      }}
      onClose={onClose}
      maxWidth='sm'
      fullWidth>
      <DialogTitle>
        {titleFragment} Favourite
      </DialogTitle>
      <DialogContent>
        { parsedFavourite && (
          <Typography className={classes.title} variant='h5'>
            { getPrimaryApplicationLabel(parsedFavourite.primary_application)}
            { parsedFavourite.secondary_application && (
              <>
                <span className={classes.titleSeparator}>|</span>
                { getSecondaryApplicationLabel(parsedFavourite.secondary_application) }
              </>
            )}
          </Typography>
        )}
        <form
          noValidate
          onSubmit={(e): void => {
            e.preventDefault()
            e.stopPropagation()
            if( parsedFavourite ){
              if( favourite ){
                editUserFavourite.mutate({
                  ...favourite,
                  name: favouriteName.trim(),
                })
              }else{
                addUserFavourite.mutate({
                  ...parsedFavourite,
                  name: favouriteName.trim(),
                })
              }
            }
          }}>
          <Box my={2}>
            <InputLabel>Favourite Name</InputLabel>
            <Input
              autoFocus
              fullWidth
              value={favouriteName}
              placeholder='Choose a name for this favourite'
              onChange={(e): void => setFavouriteName(e.target.value)} />
          </Box>
          <DialogActions>
            <RoundedPlainTextButton
              variant='contained'
              startIcon={<Close />}
              onClick={onClose}>
              Cancel
            </RoundedPlainTextButton>
            <RoundedPlainTextButton
              variant='contained'
              color='primary'
              type='submit'
              startIcon={
                (addUserFavourite.isLoading || editUserFavourite.isLoading) ?
                  <CircularProgress size='1em' color='inherit' /> :
                  <Check/>
              }
              disabled={
                !parsedFavourite || !favouriteName || addUserFavourite.isLoading || editUserFavourite.isLoading
              }>
              Save
            </RoundedPlainTextButton>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  )
}

const DeleteUserFavouriteDialog = ({
  favourite,
  onClose
}: {
  favourite: UserFavourite
  onClose: () => void
}): JSX.Element => {
  const deleteUserFavourite = useDeleteUserFavourite({ onSuccess: onClose })
  const classes = useDialogStyles()
  return (
    <ConfirmDialog
      title='Delete Favourite'
      classes={{container: classes.container}}
      content={
        <Box fontSize={14}>
          Delete <strong>{favourite.name}</strong> from favourites? This action cannot be undone
        </Box>
      }
      onCancel={onClose}
      confirmText='Delete Favourite'
      confirmIcon={
        deleteUserFavourite.isLoading ?
          <CircularProgress size='1em' color='inherit' /> :
          <DeleteForever /> 
      }
      onConfirm={(): void => {
        deleteUserFavourite.mutate(favourite)
      }} />
  )
}


const useStyles = makeAppStyles( theme => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  refetchIndicator: {
    height: 4,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  addTrigger: {
    marginLeft: 'auto',
  },
  divider: {
    margin: theme.spacing(1.5, 0, 2, 0),
  },
  list: {
    maxHeight: '30rem',
    overflowY: 'auto',
  },
  listItemText: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: theme.spacing(7),
  },
  listItemTextSelected: {
    color: theme.palette.primary.main,
  },
  spacedButton: {
    marginRight: theme.spacing(1),
  },
}))

export const UserFavouritesPopover = (
  props: Partial<Omit<IconButtonPopoverProps, 'children' | 'IconComponent'>> = {}
): JSX.Element => {
  const location = useLocation()
  const storedUserFavourites = useUserFavourites()
  const parsedFavourites = useMemo(() => {
    if( !storedUserFavourites.data ){
      return []
    }
    return storedUserFavourites.data.map( favourite => {
      const favouriteLocation = userFavouriteToLocation(favourite)
      return {
        favourite,
        href: favouriteLocation ? locationToUrl(favouriteLocation) : null,
        isActiveView: !!favouriteLocation && areLocationsEqual(location, favouriteLocation),
      }
    })
  }, [location, storedUserFavourites.data])
  const currentlocationIsFavourite = some(parsedFavourites, f => f.isActiveView)
  const [isAdding, setIsAdding] = useState(false)
  const [editFavourite, setEditFavourite] = useState<UserFavourite | null>(null)
  const [deleteFavourite, setDeleteFavourite] = useState<UserFavourite | null>(null)
  const classes = useStyles()
  return (
    <IconButtonPopover
      IconComponent={Bookmarks}
      {...props}>
      <Box minWidth='24rem' p={2} position='relative'>
        { storedUserFavourites.isRefetching && (
          <LinearProgress className={classes.refetchIndicator} variant='indeterminate' />
        )}
        <Typography variant='h5' className={classes.dialogTitle}>
          <Bookmarks className={classes.icon} />
          Favourites
          <RoundedPlainTextButton
            className={classes.addTrigger}
            variant='contained'
            disabled={currentlocationIsFavourite}
            size='small'
            startIcon={<Bookmark />}
            onClick={(): void => setIsAdding(true)}>
            Add to Favourites
          </RoundedPlainTextButton>
        </Typography>

        { (isAdding || editFavourite) && (
          <AddUserFavouriteDialog
            location={location}
            favourite={editFavourite}
            onClose={(): void => {
              setIsAdding(false)
              setEditFavourite(null)
            }} />
        )}

        <Divider className={classes.divider} />

        { storedUserFavourites.isLoading ? (
          <Loader minHeight='5rem' preset='centered' />
        ) : parsedFavourites.length ? (
          <List className={classes.list} disablePadding>
            { parsedFavourites.map( ({ favourite, href, isActiveView }, i) => {
              let listItemTextClassName = classes.listItemText
              if( isActiveView ) listItemTextClassName += ` ${classes.listItemTextSelected}`
              return (
                <ListItem
                  button
                  component={Link}
                  to={href}
                  key={`favourite-${i}`}>
                  <ListItemIcon>
                    <Bookmark color={isActiveView ? 'primary' : 'inherit'} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: listItemTextClassName,
                    }}
                    primary={favourite.name} />
                  <ListItemSecondaryAction>
                    <IconButton
                      className={classes.spacedButton}
                      size='small'
                      onClick={(): void => {
                        setEditFavourite(favourite)
                      }}>
                      <Edit />
                    </IconButton>
                    <IconButton
                      edge='end'
                      size='small'
                      onClick={(): void => {
                        setDeleteFavourite(favourite)
                      }}>
                      <DeleteForever />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        ) : (
          <Typography color='textSecondary'>You have no favourites stored</Typography>
        )}

        { deleteFavourite && (
          <DeleteUserFavouriteDialog
            favourite={deleteFavourite}
            onClose={(): void => {
              setDeleteFavourite(null)
            }} />
        )}

      </Box>
    </IconButtonPopover>
  )
}
