import React, { useContext, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { notifications } from '@percept/redux/bundles'

import { useQueryClient } from 'react-query'

import { Formik, FormikErrors } from 'formik'

import {
  BackdropLoader,
  Box,
  Card,
  CircularProgress,
  Loader,
  MassiveRoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  TextFieldProps,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown, Check, ErrorOutline } from '@percept/mui/icons'

import { FormItem, FormSection, FormSectionProps, useFormStyles } from './formComponents'

import { ValueCalculation } from './ValueCalculation'

import { MarketingCPMs } from './MarketingCPMs'

import { MarketSelect } from 'components/MarketDisplay'

import {
  useAddSponsorshipsEstimate,
  useSponsorshipsEstimate,
  useSponsorshipsMarketingCPMs,
  useSponsorshipsMarketEstimates,
  useSponsorshipsMarketPrefills,
  useAddSponsorshipsPrefills,
  useSponsorshipsMarkets,
} from './api'

import { sponsorshipsFormSchema } from './formSchema'

import { UserPrivilegeContext } from '@percept/app-components'

import { SponsorshipsStateContext } from './contexts'

import { produceEntryFormSchema } from './utils'

import { format } from 'date-fns'

import { mapValues } from 'lodash-es'

import { SubApplicationTabContext } from 'components/SubApplicationTabs'

import {
  CreateSponsorshipsEstimatePayload,
  CreateSponsorshipsPrefillsPayload,
  FormViewProps,
  SponsorshipsDerivedMetricsPayload,
  SponsorshipsDimension,
  SponsorshipsFormSchema,
  SponsorshipsFormValues,
} from './typings'


const entryFormSchema = produceEntryFormSchema(sponsorshipsFormSchema)

// const initialValues: SponsorshipsFormValues = {
//   crm_members: 19300000,
//   active_rate: 0.085,
//   number_of_stores: 415,
//   flagship_store_average_monthly_footfall: 18398,
//   store_average_monthly_footfall: 2275,
//   stores_with_radio: 400,
//   supplier_panels_per_store: 2,
//   paym_payg_panels_per_store: 2,
//   business_panels_per_store: 1,
//   business_website_visits_per_month: 250000,
//   business_website_pages_with_ads_per_visit: 3,
//   business_website_logged_in_rate: 0,
//   business_website_conversion_rate: 0,
//   business_app_visits_per_month: 0,
//   business_app_screenviews_with_ads_per_visit: 3,
//   business_app_logged_in_rate: 1,
//   business_app_conversion_rate: 0,
//   consumer_website_visits_per_month: 40000,
//   consumer_website_pages_with_ads_per_visit: 3,
//   consumer_website_logged_in_rate: 0,
//   consumer_website_conversion_rate: 0,
//   consumer_app_visits_per_month: 0,
//   consumer_app_screenviews_with_ads_per_visit: 3,
//   consumer_app_logged_in_rate: 1,
//   consumer_app_conversion_rate: 0,
//   facebook_reach: 1100000,
//   sponsored_facebook_posts_per_year: 4,
//   twitter_reach: 246000,
//   sponsored_twitter_posts_per_year: 4,
//   instagram_reach: 45000,
//   sponsored_instagram_posts_per_year: 4,
//   global_business_social_channel_reach: 100000,
//   sponsored_global_business_social_channel_posts_per_year: 4,
//   local_market_vodafone_business_impressions_per_post: 32500,
//   local_market_vodafone_business_posts_per_year: 4,
//   employee_advocacy_reach: 150000,
//   email_open_rate: 0.2,
//   email_click_rate: 0.03,
//   annual_emails_sent: 5
// }


const allDimensions: SponsorshipsDimension[] = [
  'active_rate',
  'annual_emails_sent',
  'business_app_conversion_rate',
  'business_app_logged_in_rate',
  'business_app_screenviews_with_ads_per_visit',
  'business_app_visits_per_month',
  'business_panels_per_store',
  'business_website_conversion_rate',
  'business_website_logged_in_rate',
  'business_website_pages_with_ads_per_visit',
  'business_website_visits_per_month',
  'consumer_app_conversion_rate',
  'consumer_app_logged_in_rate',
  'consumer_app_screenviews_with_ads_per_visit',
  'consumer_app_visits_per_month',
  'consumer_website_conversion_rate',
  'consumer_website_logged_in_rate',
  'consumer_website_pages_with_ads_per_visit',
  'consumer_website_visits_per_month',
  'crm_members',
  'email_click_rate',
  'email_open_rate',
  'employee_advocacy_reach',
  'facebook_reach',
  'flagship_store_average_monthly_footfall',
  'global_business_social_channel_reach',
  'instagram_reach',
  'local_market_vodafone_business_impressions_per_post',
  'local_market_vodafone_business_posts_per_year',
  'number_of_stores',
  'paym_payg_panels_per_store',
  'sponsored_facebook_posts_per_year',
  'sponsored_global_business_social_channel_posts_per_year',
  'sponsored_instagram_posts_per_year',
  'sponsored_twitter_posts_per_year',
  'store_average_monthly_footfall',
  'stores_with_radio',
  'supplier_panels_per_store',
  'twitter_reach',
]


const EstimatePayloadComponent = ({
  estimateValues,
  derivedMetrics,
  formSchema,
  ...props
}: {
  estimateValues: SponsorshipsFormValues
  formSchema: SponsorshipsFormSchema
  derivedMetrics?: SponsorshipsDerivedMetricsPayload
} & Omit<TextFieldProps, 'classes'>): JSX.Element => {
  const classes = useFormStyles()

  const subFormSectionProps: Partial<FormSectionProps> = {
    divided: true,
    TypographyProps: {
      variant: 'h4',
      className: classes.nestedCardTitle,
    },
  }

  return (
    <>
      { formSchema.map( sectionWrapper => (
        <Card key={sectionWrapper.title} className={classes.card} elevation={2}>
          <FormSection title={sectionWrapper.title} bgcolor='primary.main' color='white'>
            <Box p={4}>
              { sectionWrapper.sections.map( section => (
                <Card key={section.title} className={classes.nestedCard} elevation={1}>
                  <FormSection title={section.title} {...subFormSectionProps}>
                    <Box p={2} pt={0}>
                      { section.formItems.map( (formItem, i) => (
                        <FormItem
                          key={i}
                          classes={classes}
                          formItem={formItem}
                          values={estimateValues}
                          derivedMetrics={derivedMetrics}
                          {...props} />
                      ))}
                    </Box>
                  </FormSection>
                </Card>
              ))}
            </Box>
          </FormSection>
        </Card>
      ))}
    </>
  )
}


type SponsorshipsSubmissionFormProps = {
  formSchema: SponsorshipsFormSchema
  initialValues: SponsorshipsFormValues
  onSubmit: (values: SponsorshipsFormValues) => void
  isLoading?: boolean
  submitButtonText: React.ReactNode
}

const SponsorshipsSubmissionForm = ({
  formSchema,
  initialValues,
  onSubmit,
  submitButtonText,
  isLoading = false,
}: SponsorshipsSubmissionFormProps): JSX.Element => {
  return (
    <Formik<SponsorshipsFormValues>
      initialValues={initialValues}
      validate={(values): FormikErrors<SponsorshipsFormValues> => {
        const errors: FormikErrors<SponsorshipsFormValues> = {}
        for( const dimension of allDimensions ){
          if( values[dimension] === '' ){
            errors[dimension] = 'This field is required'
          }
        }
        return errors
      }}
      onSubmit={onSubmit}>
      {({ values, isValid, handleChange, handleBlur, handleSubmit }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <EstimatePayloadComponent
            formSchema={formSchema}
            estimateValues={values}
            onChange={handleChange}
            onBlur={handleBlur} />
          
          <Box mt={5} display='flex' justifyContent='center'>
            <MassiveRoundedPlainTextButton
              type='submit'
              size='large'
              color='primary'
              variant='contained'
              startIcon={
                !isValid ?
                  <ErrorOutline /> :
                  isLoading ?
                    <CircularProgress size='1em' color='inherit' /> :
                    <Check />
              }
              disabled={!isValid || isLoading}>
              { !isValid ? 'All Form Fields Are Required' : submitButtonText }
            </MassiveRoundedPlainTextButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}


export const EstimateSubmissionForm = ({ onSubmit }: FormViewProps): JSX.Element => {

  const { user: { default_org_unit_id: market_id } } = useContext(UserPrivilegeContext)

  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const prefillsHook = useSponsorshipsMarketPrefills(market_id)

  const submissionHook = useAddSponsorshipsEstimate(market_id, {
    onMutate: () => {
      dispatch(notifications.actions.addNotification({
        id: 'create-calculation',
        type: 'info',
        loading: true,
        ttl: 0,
        message: 'Saving your calculation...',
      }))
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsorshipsMarketEstimates', market_id] })
      dispatch(notifications.actions.addNotification({
        id: 'create-calculation',
        type: 'success',
        message: 'Your calculation has been saved',
      }))
      window.scrollTo({ top: 0 })
      onSubmit()
    },
    onError: (error) => {
      dispatch(notifications.actions.addNotification({
        id: 'create-calculation',
        type: 'error',
        ttl: 0,
        message: `Your calculation could not be saved - ${error.message}`,
      }))
    },
  })

  if( prefillsHook.error ){
    return (
      <Box m={8} fontSize={18} display='flex' justifyContent='center'>
        No prefill data has been stored for your market
      </Box>
    )
  }

  if( !prefillsHook.data ){
    return <BackdropLoader />
  }

  return (
    <Box mt={6}>
      <SponsorshipsSubmissionForm
        initialValues={prefillsHook.data.prefill_json}
        formSchema={entryFormSchema}
        isLoading={submissionHook.isLoading}
        submitButtonText='Submit Data'
        onSubmit={(values): void => {
          const payload: CreateSponsorshipsEstimatePayload = {
            estimate_json: mapValues(values, v => Number(v) )
          }
          submissionHook.mutate(payload)
        }} />
    </Box>
  )
}


const emptyPrefills: SponsorshipsFormValues = allDimensions.reduce( (acc, dimension) => {
  acc[dimension] = ''
  return acc
}, {} as SponsorshipsFormValues)


export const PrefillSubmissionForm = ({ onSubmit }: FormViewProps): JSX.Element => {

  const { data: markets } = useSponsorshipsMarkets()

  const [{ marketId }, setState] = useContext(SponsorshipsStateContext)
  
  useEffect(() => {
    if( !marketId && markets && markets.length ){
      setState({ marketId: markets[0].id })
    }
  }, [marketId, markets, setState])

  const dispatch = useDispatch()

  const queryClient = useQueryClient()

  const submissionHook = useAddSponsorshipsPrefills(marketId, {
    onMutate: () => {
      dispatch(notifications.actions.addNotification({
        id: 'submit-prefills',
        type: 'info',
        loading: true,
        ttl: 0,
        message: 'Saving your prefill data...',
      }))
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sponsorshipsMarketPrefills', marketId] })
      dispatch(notifications.actions.addNotification({
        id: 'submit-prefills',
        type: 'success',
        message: 'Your prefill data has been saved',
      }))
      window.scrollTo({ top: 0 })
      onSubmit()
    },
    onError: (error) => {
      dispatch(notifications.actions.addNotification({
        id: 'submit-prefills',
        type: 'error',
        ttl: 0,
        message: `Your prefill data could not be saved - ${error.message}`,
      }))
    },
  })

  return (
    <>
      <Box mb={4} display='flex' justifyContent='flex-end'>
        <MarketSelect
          value={marketId}
          options={markets || []}
          onChange={(market): void => setState({ marketId: market.id })} />
      </Box>
      <SponsorshipsSubmissionForm
        initialValues={emptyPrefills}
        formSchema={entryFormSchema}
        isLoading={submissionHook.isLoading}
        submitButtonText='Submit Prefill Data'
        onSubmit={(values): void => {
          const payload: CreateSponsorshipsPrefillsPayload = {
            prefill_json: mapValues(values, v => Number(v) )
          }
          submissionHook.mutate(payload)
        }} />
    </>
  )
}


const useCalculationViewStyles = makeAppStyles( theme => ({
  link: {
    margin: '0 0.2em',
    cursor: 'pointer',
    textDecoration: 'underline',
    textUnderlineOffset: '4px',
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))


export const CalculationView = ({
  marketId,
  marketSelector,
  emptyCalculationsMessage,
}: {
  marketId: string,
  emptyCalculationsMessage?: React.ReactNode
  marketSelector?: React.ReactNode
}
): JSX.Element => {
  const [{ estimateId }, setState] = useContext(SponsorshipsStateContext)

  const { data: estimates, error: estimatesError } = useSponsorshipsMarketEstimates(marketId)

  useEffect(() => {
    if( estimateId === null && estimates && estimates.length ){
      setState({ estimateId: estimates[0].id })
    }
    if( estimateId && estimates && !estimates.find( e => e.id === estimateId )){
      setState({ estimateId: estimates.length ? estimates[0].id : null })
    }
  }, [estimates, estimateId, setState])

  const { data: estimateWithCalculation, isError: calculationError } = useSponsorshipsEstimate(marketId, estimateId)

  const { data: estimatedCPMs } = useSponsorshipsMarketingCPMs(marketId)

  const { setActiveTabValue } = useContext(SubApplicationTabContext)

  const classes = useCalculationViewStyles()

  if( estimatesError ){
    return (
      <Box m={8} fontSize={18} display='flex' justifyContent='center'>
        An error occurred and estimates could not be loaded
      </Box>
    )
  }

  const activeEstimate = estimates && estimates.find( e => e.id === estimateId )

  const estimateCount = estimates && estimates.length || 0

  return (
    <>
      <Box display='flex' justifyContent='flex-end' mb={4}>
        { marketSelector }
        <RoundedPlainTextButtonMenu
          value={estimateId}
          label={
            activeEstimate ?
              `Calculation ${format(new Date(activeEstimate.created_at), 'dd/MM/yy HH:mm')}` :
              estimates && !estimateCount ?
                'No Calculations' :
                'Loading...'
          }
          TriggerProps={{
            variant: 'contained',
            color: 'secondary',
            disabled: !estimateCount,
            endIcon: <ArrowDropDown />,
          }}
          options={
            estimates && estimates.map( estimate => ({
              value: estimate.id,
              label: `Calculation ${format(new Date(estimate.created_at), 'dd/MM/yy HH:mm')}`,
            })) || []
          }
          onChange={(e, value): void => {
            setState({ estimateId: value })
          }} />
      </Box>
      { estimates && !estimateCount ? (
        <Box m={8} fontSize={18} display='flex' justifyContent='center'>
          { emptyCalculationsMessage || (
            <>
              No sponsorships calculations have been saved. Please
              <span className={classes.link} onClick={(): void => setActiveTabValue('submit')}>
                submit data
              </span>
              to create a calculation.
            </>
          )}
        </Box>
      ) : calculationError ? (
        <Box m={8} fontSize={18} display='flex' justifyContent='center'>
          An error occurred and your calculation could not be loaded
        </Box>
      ) : !estimateWithCalculation || !estimates ? (
        <Loader preset='centered' minHeight='16rem' />
      ) : (
        <>
          <ValueCalculation
            valueCalculation={estimateWithCalculation.value_calculation} />
          <EstimatePayloadComponent
            formSchema={sponsorshipsFormSchema}
            estimateValues={estimateWithCalculation.estimate_json}
            derivedMetrics={estimateWithCalculation.derived_metrics} />
          { estimatedCPMs && (
            <MarketingCPMs
              estimatedCPMs={estimatedCPMs} />
          )}
          </>
      )}
    </>
  )
}


export const MarketUserCalculationView = (): JSX.Element => {
  const { user: { default_org_unit_id: marketId } } = useContext(UserPrivilegeContext)
  return (
    <CalculationView marketId={marketId} />
  )
}


export const GlobalUserCalculationView = (): JSX.Element => {
  const markets = useSponsorshipsMarkets()

  const [{ marketId }, setState] = useContext(SponsorshipsStateContext)

  useEffect(() => {
    if( !marketId && markets.data && markets.data.length ){
      setState({ marketId: markets.data[0].id })
    }
  }, [marketId, markets.data, setState])

  if( markets.error ){
    return (
      <Box m={8} fontSize={18} display='flex' justifyContent='center'>
        An error occurred and markets could not be loaded
      </Box>
    )
  }

  if( !markets.data || !marketId ){
    return <Loader preset='centered' minHeight='16rem' />
  }

  const marketSelector = (
    <Box mr={2}>
      <MarketSelect
        value={marketId}
        options={
          markets.data.map( m => ({
            ...m,
            badgeCount: m.estimate_count
          }))
        }
        onChange={(market): void => {
          setState({ marketId: market.id })
        }} />
    </Box>
  )

  return (
    <CalculationView
      marketId={marketId}
      marketSelector={marketSelector}
      emptyCalculationsMessage={
        'No calculations have been stored for this market'
      } />
  )
}
