import React, { useContext } from 'react'

import {
  Box,
  Card,
  Chip,
  Divider,
  Grid,
  Loader,
  SimpleTable,
  Typography,
} from '@percept/mui'

import { OverviewAnalysis } from './OverviewAnalysis'
import { OverviewCard } from './OverviewCard'
import { OverviewDateControls } from './OverviewDateControls'

// import { MarketsSegmentationQuartal, ReportService } from 'components/BrandGrowthPlanner/api/reports'

import { useCreativeWastageByMarket, useNestedOrgUnitPotentialEfficiencyScores } from '@percept/hooks'

import { useCompetitiveSOSByMarket, useGlobalOverviewTables, useMediaInvestmentReportsByMarket } from './globalDataHooks'

import { some } from 'lodash-es'

import { getPreviousPotentialEfficiencyReferenceDate, resolveDateParams } from './utils'

// import { formatFinancialQuarter } from './lib'

// import { parseLastSegmentationQuartal, parseMarketSegmentation } from 'components/BrandGrowthPlanner/pages/all-markets/components'

import { OverviewContext } from './overviewContext'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

import { multiMarketAnalysis } from './overviewData'

import { BaseOverviewComponentProps } from './typings'

// import { BrandGrowthDatum, BrandGrowthQuadrantChart } from 'components/BrandGrowthQuadrantChart'
import { ComparisonMethodSelect } from './overviewComponents'
import { isoDate } from '@percept/utils'


export const GlobalOverview = ({
  classes,
  cellRenderers,
  tableClasses,
}: BaseOverviewComponentProps): JSX.Element => {

  const { granularity, dateRanges, referenceDate, comparisonMethod, setComparisonMethod } = useContext(OverviewContext)

  const primaryCreativeWastageByMarket = useCreativeWastageByMarket(resolveDateParams(dateRanges.primary))
  const comparisonCreativeWastageByMarket = useCreativeWastageByMarket(resolveDateParams(dateRanges.comparison))
  // const secondaryCreativeWastageByMarket = useCreativeWastageByMarket(resolveDateParams(secondary))
  // const secondaryComparisonCreativeWastageByMarket = useCreativeWastageByMarket(resolveDateParams(secondaryComparison))

  const potentialEfficiencyRatiosByMarket = useNestedOrgUnitPotentialEfficiencyScores({
    org_unit_id: VODAFONE_GLOBAL_ID,
  })

  const secondaryPotentialEfficiencyRatiosByMarket = useNestedOrgUnitPotentialEfficiencyScores({
    org_unit_id: VODAFONE_GLOBAL_ID,
    reference_date: isoDate(getPreviousPotentialEfficiencyReferenceDate()),
  })

  const investmentDataByMarket = useMediaInvestmentReportsByMarket({
    referenceDate,
    comparisonMethod,
  })

  const competitiveSOSByMarket = useCompetitiveSOSByMarket({
    referenceDate,
    comparisonMethod,
  })

  // const marketSegmentation = ReportService.useMarketsSegmentation()

  const isLoading = some([
    primaryCreativeWastageByMarket,
    comparisonCreativeWastageByMarket,
    potentialEfficiencyRatiosByMarket,
    investmentDataByMarket,
    competitiveSOSByMarket,
    ...(granularity === 'year' && [
      secondaryPotentialEfficiencyRatiosByMarket
    ] || []),
    // secondaryCreativeWastageByMarket,
    // secondaryComparisonCreativeWastageByMarket,
  ], hook => !hook.data )

  const tables = useGlobalOverviewTables({
    granularity,
    dateRanges,
    referenceDate,
    comparisonMethod,
    primaryCreativeWastageByMarket: primaryCreativeWastageByMarket.data,
    comparisonCreativeWastageByMarket: comparisonCreativeWastageByMarket.data,
    potentialEfficiencyRatiosByMarket: potentialEfficiencyRatiosByMarket.data,
    secondaryPotentialEfficiencyRatiosByMarket: secondaryPotentialEfficiencyRatiosByMarket.data,
    investmentDataByMarket: investmentDataByMarket.data,
    competitiveSOSByMarket: competitiveSOSByMarket.data,
  })

  // let bgpData: BrandGrowthDatum[] | null = null
  // let quartal: MarketsSegmentationQuartal | null = null
  // if( marketSegmentation.data ){
  //   bgpData = parseMarketSegmentation(marketSegmentation.data)
  //   quartal = parseLastSegmentationQuartal(marketSegmentation.data)
  // }

  const fixedTables = tables.slice(0, 3)

  const switchableTables = tables.slice(3)

  return (
    <>
      <Grid container spacing={5}>
        {/* NOTE - BGP data and components removed */}
        {/* <Grid item xs={12} md={6}>
          <Card className={classes.fullHeightCard} elevation={3}>
            <Box display='flex' alignItems='center' justifyContent='space-between' p={1.5}>
              <Typography variant='h5'>
                Market Segmentation
              </Typography>
              <Chip
                size='small'
                color='primary'
                label='Strategy' />
            </Box>
            <Divider />
            { !(quartal && bgpData) ? (
              <Loader preset='fullsize' minHeight={491} />
            ) : (
              <>
                <Box ml={6} mb={1} mt={2}>
                  <Typography variant='subtitle1'>
                    {formatFinancialQuarter(new Date(quartal.start_date))} Customer Appreciation Rankings
                  </Typography>
                </Box>
                <Box p={2} height={475}>
                  <BrandGrowthQuadrantChart
                    markets={bgpData} />
                </Box>
              </>
            )}
          </Card>
        </Grid> */}
        <Grid item xs={12}>
          <OverviewAnalysis
            className={classes.fullHeightCard}
            elevation={3}
            items={multiMarketAnalysis}
            GridItemProps={{
              xs: 12,
              md: 4,
            }} />
        </Grid>
      </Grid>

      <Box mt={4}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <ComparisonMethodSelect
              value={comparisonMethod}
              onChange={setComparisonMethod} />
          </Grid>
          { fixedTables.map( ({ dimension, category, title, tooltipContent, props }) => {
            const isTableLoading = (
              !investmentDataByMarket.data && dimension === 'brand_investment_share'
              || !competitiveSOSByMarket.data && dimension === 'share_of_spend'
            )
            return (
              <Grid key={dimension} item xs={12} sm={6} md={4}>
                <OverviewCard
                  elevation={3}
                  classes={classes}
                  title={title}
                  category={category}
                  tooltipContent={tooltipContent}>
                  { isTableLoading ? (
                    <Loader preset='fullsize' minHeight={628} />
                  ) : (
                    <SimpleTable
                      classes={tableClasses}
                      renderers={cellRenderers}
                      {...props} />
                  )}
                </OverviewCard>
              </Grid>
            )
          })}

          <Grid item xs={12}>
            <OverviewDateControls />
          </Grid>

          { isLoading ? (
            <Loader preset='fullsize' minHeight='30rem' />
          ) : (
            <>
              { switchableTables.map( ({ dimension, category, title, tooltipContent, props }) => (
                <Grid key={dimension} item xs={12} sm={6} md={4}>
                  <OverviewCard
                    elevation={3}
                    classes={classes}
                    title={title}
                    category={category}
                    tooltipContent={tooltipContent}>
                    <SimpleTable
                      classes={tableClasses}
                      renderers={cellRenderers}
                      {...props} />
                  </OverviewCard>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </>
  )
}

