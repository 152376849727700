import { createContext } from 'react'
import { makeUrlStateContext } from '@percept/hooks'
import { DimensionType, InsightsReportViewType, InsightVertical, UserPrivileges } from '@percept/types'

export const UserPrivilegeContext = createContext<UserPrivileges>({} as UserPrivileges)


export type InsightUrlParams = {
  report: string
  verticals?: InsightVertical[] | null
  insight?: string
  view?: InsightsReportViewType
  metric?: string
  segment?: string
  dimension?: DimensionType
}

export const defaultInsightUrlParams: InsightUrlParams = {
  report: 'latest',
  insight: undefined,
  metric: undefined,
  dimension: undefined,
  segment: undefined,
  verticals: undefined,
  view: undefined,
}

export const InsightContext = makeUrlStateContext<InsightUrlParams>()
