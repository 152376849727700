import React, { useEffect, useMemo } from 'react'

import {
  BackdropLoader,
  Box,
  Card,
  DateRangePresetOption,
  dateRangeCalculators,
  DateRangeValue,
  FormLabel,
  LinearProgress,
  SimpleTable,
  SimpleTableProps,
  Typography,
  makeAppStyles,
  FilterPanel,
  Grid,
  RadioFormGroup,
  CheckboxGroup,
  ProviderLogo,
  formatMoney,
  DateRangePresetInput,
  resolveDateRangePresetLabel,
} from '@percept/mui'

import { stringifyUrlSearchParams, useDocumentTitle, useUrlState, useUserPlatformUnits } from '@percept/hooks'

import {
  DataWarehouseProvider,
  useDataWarehouseQuery,
  useDataWarehouseQueryGroupings,
} from './hooks'

import { useActivePlatformUnit } from 'components/Organisation'

import { currencyOptions } from 'components/CurrencySelect'
import { GenericTableRow, getDataWarehouseTableProps } from './lib'

import { flatten, get, maxBy, omit } from 'lodash-es'

import { resolveDateRangeFromUrlState } from '@percept/app-components'

import { isoDate, produceKeyedMapping } from '@percept/utils'
import { useSavedQueryStrings } from 'hooks'


const dataWarehouseProviders: DataWarehouseProvider[] = [
  'GOOGLE_ADS', 'FACEBOOK', 'ADFORM', 'DV360'
]


const minDatesByProvider: Partial<Record<DataWarehouseProvider, Date>> = {
  GOOGLE_ADS: new Date('2018-01-01'),
  ADFORM: new Date('2018-01-01'),
  FACEBOOK: new Date('2019-10-03'),
  DV360: new Date('2022-10-25'),
}

const minDate = maxBy(Object.values(minDatesByProvider), d => d.getTime())

const maxDate = new Date('2024-10-01')

const useStyles = makeAppStyles( theme => ({
  filterCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  radioFormLabelTopAligned: {
    alignItems: 'start',
  },
  filterSelector: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  filterLabel: {
    fontSize: 11,
    marginLeft: 10,
    marginBottom: 6,
  },
  groupingTitle: {
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  groupingExplainer: {
    marginTop: 2,
    fontSize: 12,
    lineHeight: 1.5,
    maxWidth: '21rem',
    whiteSpace: 'normal',
    marginBottom: theme.spacing(1),
  },
  tableCard: {
    position: 'relative'
  },
  refetchIndicator: {
    height: 4,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.appBar + 4,
  },
}))

type DataWarehouseState = {
  reportType?: string
  reportGrouping?: string
  organisations: string[]
  providers: DataWarehouseProvider[]
  dateRangePreset: DateRangePresetOption
  start?: string
  end?: string
  currency: string
}

type FilterPanelState = Omit<DataWarehouseState, 'dateRangePreset' | 'start' | 'end'> & {
  dateRange: {
    preset: DateRangePresetOption
    value: DateRangeValue
  }
}

const urlStateToFilterPanelState = (
  state: DataWarehouseState,
  maxDate: Date,
): FilterPanelState => ({
  ...state,
  dateRange: {
    preset: state.dateRangePreset,
    value: (
      state.dateRangePreset === 'custom' ?
        [
          state.start ? new Date(state.start) : null,
          state.end ? new Date(state.end) : null,
        ] as DateRangeValue :
        dateRangeCalculators[state.dateRangePreset](maxDate)
    ),
  }
})

export const DataWarehouse = () => {

  const activePlatformUnit = useActivePlatformUnit()

  useDocumentTitle({
    paths: [
      activePlatformUnit && activePlatformUnit.name,
      'Data Warehouse',
    ]
  })

  const groupingsQueryResult = useDataWarehouseQueryGroupings()

  const [state, setState] = useUrlState<DataWarehouseState>({
    reportType: get(groupingsQueryResult.data, [0, 'key']),
    reportGrouping: get(groupingsQueryResult.data, [0, 'sub_groupings', 0, 'key']),
    organisations: activePlatformUnit ? [activePlatformUnit.id] : [],
    providers: dataWarehouseProviders,
    dateRangePreset: 'current-financial-year',
    start: undefined,
    end: undefined,
    currency: 'EUR',
  })

  const [, setSavedQueryStrings] = useSavedQueryStrings()

  useEffect(() => {
    setSavedQueryStrings({
      // NOTE - we store all params other than organisation filter
      // to provide sane defaults when navigating to other orgs
      dataWarehouse: stringifyUrlSearchParams(
        omit(state, 'organisations')
      )
    })
  }, [state, setSavedQueryStrings])

  const groupingsByKey = produceKeyedMapping(groupingsQueryResult.data, 'key')

  const grouping = get(groupingsByKey, state.reportType || '', null)
  const sub_grouping = get(grouping, 'sub_groupings', []).find( g => g.key === state.reportGrouping) || null

  const dateRange = resolveDateRangeFromUrlState(state, maxDate)

  const [orgUnitHierarchy] = useUserPlatformUnits()

  const availableOrgUnits = flatten(
    (orgUnitHierarchy.data || []).map( org => [
      org,
      ...(org.children || [])
    ])
  )

  const orgUnitMapping = produceKeyedMapping(availableOrgUnits, 'id')

  useEffect(() => {
    if( !state.organisations.length && activePlatformUnit ){
      setState({ organisations: [activePlatformUnit.id] })
    }
  }, [activePlatformUnit, state.organisations, setState])

  useEffect(() => {
    if( !state.reportType && groupingsQueryResult.data && groupingsQueryResult.data.length === 1 ){
      const defaultGrouping = groupingsQueryResult.data[0]
      const update: Partial<DataWarehouseState> = {
        reportType: defaultGrouping.key
      }
      if( defaultGrouping.sub_groupings && defaultGrouping.sub_groupings.length ){
        update.reportGrouping = defaultGrouping.sub_groupings[0].key
      }
      setState(update)
    }
  }, [state.reportType, groupingsQueryResult.data, setState])

  const queryResult = useDataWarehouseQuery({
    grouping: grouping && grouping.key,
    sub_grouping: sub_grouping && sub_grouping.key,
    org_unit_ids: state.organisations,
    providers: state.providers,
    start: dateRange && dateRange[0],
    end: dateRange && dateRange[1],
    currency: state.currency,
  })

  const tableProps: SimpleTableProps<GenericTableRow> = useMemo(() => {
    if( !queryResult.data ){
      return {
        grouped: false,
        columns: [],
        rows: [],
      } as unknown as SimpleTableProps<GenericTableRow>
    }
    return getDataWarehouseTableProps(queryResult.data, state.currency) as SimpleTableProps<GenericTableRow>
  }, [queryResult.data, state.currency])

  const classes = useStyles()

  return (
    <Box px={3} pt={5}>

      <Card className={classes.filterCard}>
        <FilterPanel
          values={urlStateToFilterPanelState(state, maxDate)}
          onConfirm={(values): void => {
            setState({
              ...values,
              dateRangePreset: values.dateRange.preset,
              start: (
                values.dateRange.preset === 'custom' ?
                  isoDate(new Date(values.dateRange.value[0])) :
                  undefined
              ),
              end: (
                values.dateRange.preset === 'custom' ?
                  isoDate(new Date(values.dateRange.value[1])) :
                  undefined
              ),
            })
          }}
          valueOrder={
            [
              'reportType',
              'reportGrouping',
              'dateRange',
              'organisations',
              'providers',
              'currency',
            ]
          }
          displayConfig={{
            /* eslint-disable react/display-name, react/prop-types */
            reportType: {
              label: 'Report Type',
              nullable: false,
              render: (value) => get(groupingsByKey, [value, 'title'], 'Loading...'),
            },
            reportGrouping: {
              label: 'Report Behaviour',
              nullable: false,
              render: (value) => {
                const subGroupings = get(groupingsByKey, [state.reportType || '', 'sub_groupings'])
                const match = (subGroupings || []).find( g => g.key === value )
                if( match ){
                  return match.title
                }
                return null
              }
            },
            dateRange: {
              label: 'Date Range',
              nullable: false,
              render: ({ preset, value }) => resolveDateRangePresetLabel(preset, value),
            },
            organisations: {
              label: 'Organisations',
              render: (value) => get(orgUnitMapping, [value, 'name'], null),
            },
            providers: {
              label: 'Providers',
              render: (value): JSX.Element => (
                <ProviderLogo size={1.25} provider={value} style={{position: 'relative', top: 3}} />
              ),
            },
            currency: {
              label: 'Currency',
              nullable: false,
              render: (value) => (
                <span>
                  {formatMoney({ amount: null, currency: value })} {value}
                </span>
              )
            },
            /* eslint-enable react/display-name, react/prop-types */
          }}>
          { ({ values, updateValues }): JSX.Element => {
            const activeGrouping = values.reportType && groupingsByKey[values.reportType]
            return (
              <Grid container spacing={5}>
                <Grid item xs='auto'>
                  <Box display='flex' flexDirection='column'>
                    <RadioFormGroup
                      name='Report Type'
                      value={values.reportType}
                      options={
                        (groupingsQueryResult.data || []).map( grouping => ({
                          value: grouping.key,
                          label: grouping.title,
                        }))
                      }
                      onChange={(reportType): void => {
                        updateValues({ reportType })
                      }} />

                    { !!(activeGrouping && activeGrouping.sub_groupings && activeGrouping.sub_groupings.length) && (
                      <Box mt={3}>
                        <RadioFormGroup
                          name='Report Behaviour'
                          value={values.reportGrouping}
                          FormControlLabelProps={{
                            className: classes.radioFormLabelTopAligned,
                          }}
                          options={
                            (activeGrouping.sub_groupings || []).map( sub_grouping => ({
                              value: sub_grouping.key,
                              label: (
                                <div>
                                  <Typography className={classes.groupingTitle}>
                                    {sub_grouping.title}
                                  </Typography>
                                  { sub_grouping.info && (
                                    <Typography className={classes.groupingExplainer}>
                                      {sub_grouping.info}
                                    </Typography>
                                  )}
                                </div>
                              ),
                            }))
                          }
                          onChange={(reportGrouping): void => {
                            updateValues({ reportGrouping })
                          }} />
                      </Box>
                    )}
                  </Box>
                </Grid>

                <Grid item xs='auto'>
                  <FormLabel>Date Range</FormLabel>
                  <DateRangePresetInput
                    BoxProps={{
                      mt: 1,
                    }}
                    variant='static'
                    divided
                    color='secondary'
                    dateRangePreset={values.dateRange.preset}
                    value={values.dateRange.value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={(value, preset): void => {
                      updateValues({
                        dateRange: {
                          preset,
                          value,
                        }
                      })
                    }} />
                </Grid>

                <Grid item xs='auto'>
                  <CheckboxGroup
                    name='Organisations'
                    value={values.organisations}
                    options={
                      availableOrgUnits.map( org => ({
                        value: org.id,
                        label: org.name,
                      }))
                    }
                    onChange={(organisations): void => {
                      updateValues({ organisations })
                    }} />
                </Grid>

                <Grid item xs='auto'>
                  <CheckboxGroup
                    name='Providers'
                    value={values.providers}
                    options={
                      dataWarehouseProviders.map( provider => ({
                        value: provider,
                        label: <ProviderLogo size={1.35} provider={provider} style={{position: 'relative', top: 3}} />,
                      }))
                    }
                    onChange={(providers): void => updateValues({ providers })} />
                </Grid>

                <Grid item xs='auto'>
                  <RadioFormGroup
                    name='Currency'
                    value={values.currency}
                    options={currencyOptions}
                    onChange={(currency): void => {
                      updateValues({ currency })
                    }} />
                </Grid>

              </Grid>
            )
          }}
        </FilterPanel>
      </Card>

      { (queryResult.isLoading || !orgUnitHierarchy.data) && <BackdropLoader />}
      { queryResult.data && (
        <Card className={classes.tableCard}>
          { queryResult.isRefetching && (
            <LinearProgress variant='indeterminate' className={classes.refetchIndicator} />
          )}
          <SimpleTable
            size='small'
            sortable
            stickyHeader
            pinFirstColumn
            unsetStickyHeaderZIndex={false}
            TableContainerProps={{
              style: {
                maxHeight: 'calc(100vh - 245px)',
              }
            }}
            {...tableProps} />
        </Card>
      )}

    </Box>
  )
}
