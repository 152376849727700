import { makeUrlStateContext } from '@percept/hooks'
import { AnyPerformanceDimension } from '@percept/types'

export type DashboardDimension = AnyPerformanceDimension | 'potential_efficiency'

export type DashboardUrlParams = { currency: string, dimension: DashboardDimension }

export const DashboardContext = makeUrlStateContext<DashboardUrlParams>()


export type OverviewPerformanceDimension = AnyPerformanceDimension

export const PerformanceOverviewContext = makeUrlStateContext<{ currency: string, dimension: OverviewPerformanceDimension }>()
