import React, { useContext } from 'react'

import { Link } from 'react-router-dom'

import { Box, RoundedPlainTextButton, RoundedPlainTextButtonMenu } from '@percept/mui'

import { ArrowDropDown, dimensionIcons, TableChart } from '@percept/mui/icons'

import { CurrencySelect, CurrencySelectMenu } from 'components/CurrencySelect'

import { getPerformanceDimensionOptions } from 'components/PerformanceDimensionSelect'

import { DoubleVerifyReferenceDateTrigger, ReferenceDateSelect } from 'components/ReferenceDates'

import {
  usePerformanceReportingDimension,
  useReferenceDate,
  useReferenceDateBehaviour,
  useSavedQueryStrings,
} from 'hooks'

import { getPotentialEfficiencyLabel } from 'components/Organisation'

import { dimensionMap } from '@percept/constants'

import { AnyPerformanceDimension, PlatformUnit } from '@percept/types'
import { platformUnitHasDoubleVerify } from 'components/Organisation/lib'
import { isPlatformUnitContainer } from '@percept/utils'
import { DashboardContext } from 'contexts'


export type PerformanceControlsProps = {
  platformUnit: PlatformUnit
  availableDimensions: AnyPerformanceDimension[]
  referenceDate: Date | null
  doubleVerifyReferenceDate: Date | null
  requiresDoubleVerifyTrigger: boolean
  maxDate: Date | null
  showDimensionSelector?: boolean
  potentialEfficiencyEnabled?: boolean
  onDimensionChange?: (dimension: AnyPerformanceDimension | 'potential_efficiency') => void
}

export const PerformanceControls = ({
  platformUnit,
  availableDimensions,
  referenceDate,
  requiresDoubleVerifyTrigger,
  doubleVerifyReferenceDate,
  maxDate,
  showDimensionSelector = true,
  potentialEfficiencyEnabled = true,
  onDimensionChange,
}: PerformanceControlsProps): JSX.Element => {

  const [state, setState] = useContext(DashboardContext)

  const potentialEfficiencyActive = state.dimension === 'potential_efficiency'

  // const [activeDimension, setActiveDimension] = usePerformanceReportingDimension()

  const potentialEfficiencyLabel = getPotentialEfficiencyLabel(platformUnit)

  const dimensionOptions = getPerformanceDimensionOptions(availableDimensions)
  const options = !potentialEfficiencyEnabled ? dimensionOptions : [
    {
      key: 'potentialEfficency',
      value: 'potential_efficiency',
      label: potentialEfficiencyLabel,
    },
    ...dimensionOptions
  ]

  const [ , setActiveReferenceDate] = useReferenceDate()

  const hasDoubleVerify = platformUnitHasDoubleVerify(platformUnit)

  const mediaQualityRateEnabled = hasDoubleVerify && !isPlatformUnitContainer(platformUnit)

  const dataWareHouseEnabled = process.env.APP === 'vodafone'

  const [ , setReferenceDateBehaviour] = useReferenceDateBehaviour()

  const [savedQueryStrings] = useSavedQueryStrings()

  const TriggerIconComponent = state.dimension !== 'potential_efficiency' && dimensionIcons[state.dimension]

  const startIcon = TriggerIconComponent && (
    <TriggerIconComponent />
  )

  return (
    <Box display='flex' justifyContent='space-between'>

      <Box display='flex'>
        <Box mr={2}>
          <CurrencySelectMenu
            value={state.currency}
            onChange={(_, currency): void => {
              setState({ currency })
            }}
            TriggerProps={{
              variant: 'contained',
              color: 'secondary',
            }} />
        </Box>

        { showDimensionSelector &&(
          <Box mr={2}>
            <RoundedPlainTextButtonMenu
              value={state.dimension}
              label={
                state.dimension === 'potential_efficiency' ?
                  potentialEfficiencyLabel :
                  state.dimension && dimensionMap[state.dimension].text
              }
              TriggerProps={{
                startIcon,
                endIcon: <ArrowDropDown />,
                variant: 'contained',
                color: 'secondary',
              }}
              options={options}
              onChange={(e, dimension): void => {
                setState({ dimension })
                if( onDimensionChange ){
                  onDimensionChange(dimension)
                }
              }} />
          </Box>
        )}

        { !potentialEfficiencyActive && referenceDate && (
          <Box mr={5}>
            <ReferenceDateSelect
              value={referenceDate}
              hasDoubleVerify={hasDoubleVerify}
              maxDate={maxDate}
              onChange={setActiveReferenceDate} />
          </Box>
        )}

        { !potentialEfficiencyActive && requiresDoubleVerifyTrigger && doubleVerifyReferenceDate && (
          <DoubleVerifyReferenceDateTrigger
            referenceDate={doubleVerifyReferenceDate}
            onClick={(): void => {
              setReferenceDateBehaviour('LATEST_ALL_INCLUDING_DOUBLEVERIFY')
            }} />
        )}
      </Box>

      <Box display='flex' alignItems='center'>
        <Link to={{
          pathname: `/dashboards/${platformUnit.id}/performance-report`,
          search: savedQueryStrings.customPerformanceReport,
        }}>
          <RoundedPlainTextButton
            color='secondary'
            variant='contained'
            startIcon={<TableChart />}>
            Custom Performance Report
          </RoundedPlainTextButton>
        </Link>

        { mediaQualityRateEnabled && (
          <Box ml={2}>
            <Link to={{
              pathname: `/dashboards/${platformUnit.id}/media-quality-rate`,
              search: savedQueryStrings.mediaQualityRate,
            }}>
              <RoundedPlainTextButton
                color='secondary'
                variant='contained'
                startIcon={<TableChart />}>
                Media Quality Rate
              </RoundedPlainTextButton>
            </Link>
          </Box>
        )}

        { dataWareHouseEnabled && (
          <Box ml={2}>
            <Link to={{
              pathname: `/dashboards/${platformUnit.id}/data-warehouse`,
              search: savedQueryStrings.dataWarehouse,
            }}>
              <RoundedPlainTextButton
                color='secondary'
                variant='contained'
                startIcon={<TableChart />}>
                Data Warehouse (Beta)
              </RoundedPlainTextButton>
            </Link>
          </Box>
        )}
      </Box>

    </Box>
  )
}
