import React from 'react'

import {
  ButtonProps,
  formatMoney,
  makeAppStyles,
  MenuAbstractionProps,
  MenuOption,
  RoundedPlainTextButtonMenu,
} from '@percept/mui'

import { ArrowDropDown } from '@percept/mui/icons'

import { useTargetCurrency } from 'hooks'


export const currencyOptions: MenuOption<string>[] = [
  'AUD',
  'CAD',
  'EUR',
  'GBP',
  'JPY',
  'USD',
].map( value => ({
  value,
  label: (
    <span>
      {formatMoney({ amount: null, currency: value })} {value}
    </span>
  ),
}))


const useStyles = makeAppStyles({
  button: {
    minWidth: 'unset !important',
  }
})

export type CurrencySelectMenuProps = Omit<
  MenuAbstractionProps<ButtonProps>, 'options' | 'children'
>

export const CurrencySelectMenu = ({ TriggerProps = {}, ...props }: CurrencySelectMenuProps): JSX.Element => {

  const classes = useStyles()

  return (
    <RoundedPlainTextButtonMenu
      {...props}
      options={currencyOptions}
      TriggerProps={{
        startIcon: formatMoney({ amount: null, currency: props.value }),
        endIcon: <ArrowDropDown />,
        ...TriggerProps,
        className: `${TriggerProps.className || ''} ${classes.button}`,
      }} />
  )
}



export type CurrencySelectProps = Omit<
  MenuAbstractionProps<ButtonProps>, 'value' | 'options' | 'children' | 'onChange'
>

export const CurrencySelect = (props: CurrencySelectProps): JSX.Element => {

  const [targetCurrency, setTargetCurrency] = useTargetCurrency()

  return (
    <CurrencySelectMenu
      {...props}
      value={targetCurrency}
      onChange={(e, value: string): void => setTargetCurrency(value)} />
  )
}
