import React, { useContext, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Card,
  CellRenderers,
  CheckboxGroup,
  CircularProgress,
  Column,
  Dialog,
  Divider,
  FilterPanel,
  FormLabel,
  Input,
  InputAdornment,
  LinearProgress,
  Loader,
  MenuOption,
  RoundedPlainTextButton,
  RoundedPlainTextButtonMenu,
  SimpleTable,
  TablePagination,
  Typography,
  makeAppStyles,
} from '@percept/mui'

import { ArrowDropDown, Block, CheckCircle, Close, CloudDownload, Help, OpenInNew, PersonAdd, Search } from '@percept/mui/icons'

import { UserManagementDialog } from './UserManagementDialog'

import { VodafoneLogoShort } from './VodafoneLogoShort'

import { TablePaginationActions } from './tableComponents'

import { getMarketLabel } from './attributeComponents'

import {
  SearchMatchType,
  useSearchTenantUserManagementEstablishedUsers,
  useTenantUserManagementEstablishedUsers,
  useTenantUserManagementMetadata,
  useTenantUserManagementPendingSSOUsers,
  useTenantUsersCSVExport,
  useTimeago,
} from '@percept/hooks'

import { UserPrivilegeContext } from '@percept/app-components'

import { isAdminUser, isValidEmail, produceKeyedMapping, userHasOrgPrivileges } from '@percept/utils'

import { DerivedListingUser, ListingViewType, generateDerivedListingUser } from './lib'

import { VODAFONE_GLOBAL_ID } from 'vodafoneMarkets'

import { DateType } from '@percept/types'

import { marketOptions } from 'components/Analytics'

import { MarketDisplayLabel } from 'components/MarketDisplay'


const columns: Column<DerivedListingUser>[] = [
  {
    key: 'user_type',
    label: '',
    align: 'center',
    style: {
      width: 20,
    }
  },
  {
    key: 'name',
    label: 'Name',
    align: 'left',
  },
  {
    key: 'email',
    label: 'Email',
    align: 'left',
  },
  {
    key: 'user_status',
    label: 'User Status',
    align: 'left'
  },
  {
    key: 'market',
    label: 'Market',
    align: 'left',
  },
  {
    key: 'last_login',
    label: 'Last Login',
    align: 'left',
  },
  {
    key: 'activated_at',
    label: 'Activated',
    align: 'left',
  },
  {
    key: 'modified',
    label: 'Last Edited',
    align: 'left',
  }
]


const useStyles = makeAppStyles( theme => ({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  spacedLeft: {
    marginLeft: theme.spacing(2),
  },
  filterCard: {
    marginTop: theme.spacing(3),
  },
  filterVodafoneIcon: {
    width: 16,
    height: 16,
    fill: theme.palette.primary.main,
    marginRight: 6,
  },
  filterIcon: {
    width: 16,
    height: 16,
    fontSize: 16,
    marginRight: 6,
  },
  filters: {
    display: 'flex',
  },
  filterControl: {
    minWidth: 120,
  },
  filterFormGroup: {
  },
  filterDivider: {
    margin: theme.spacing(0, 4),
  },
  table: {
    marginTop: theme.spacing(4),
  },
  inputWrapper: {
    display: 'flex',
    width: '28rem',
  },
  searchMatchTypeSelect: {
    marginLeft: theme.spacing(0.5),
  },
  input: {
    minHeight: 24,
  },
  pointer: {
    cursor: 'pointer',
  },
  card: {
    position: 'relative',
    paddingTop: theme.spacing(0.5),
  },
  enabledIcon: {
    color: theme.palette.success.main,
    marginRight: theme.spacing(0.75),
  },
  disabledIcon: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(0.75),
  },
  neutralIcon: {
    color: 'inherit',
    marginRight: theme.spacing(0.75),
  },
  loadingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 4,
    width: '100%',
  },
  tableCell: {
    fontSize: 13,
    fontWeight: 500,
    padding: theme.spacing(1, 2),
  },
  tableRowClickable: {
    cursor: 'pointer',
    userSelect: 'none',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '& .MuiTableCell-body': {
        color: theme.palette.primary.contrastText,
      },
      '& $tableVodafoneIcon': {
        fill: 'currentColor',
      },
      '& $enabledIcon': {
        color: 'inherit',
      },
      '& $disabledIcon': {
        color: 'inherit',
      },
    },
  },
  tableVodafoneIcon: {
    width: 18,
    height: 18,
    transition: theme.transitions.create('fill'),
    fill: theme.palette.primary.main,
  },
  tableExternalIcon: {
    fontSize: 18,
  },
  logo: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 1),
  },
}))


type FilterPanelValues = {
  email: string
  marketSlugs: string[]
  userTypeSlugs: string[]
  searchMatchType: SearchMatchType
}

const marketOptionsBySlug = produceKeyedMapping(
  marketOptions, 'slug'
)

type TableConfig = {
  items: DerivedListingUser[]
  total: number
  rowsPerPage: number
}

const searchMatchTypeLabels: Record<SearchMatchType, string> = {
  STARTS_WITH: 'Starts with',
  EXACT: 'Exactly matches',
}


export const UserManagement = (): JSX.Element => {

  const [timeago] = useTimeago()

  const [open, setOpen] = useState(false)

  const [page, setPage] = useState(1)

  const [editUserId, setEditUserId] = useState<string | null>(null)

  const [filterValues, setFilterValues] = useState<FilterPanelValues>({
    email: '',
    marketSlugs: [],
    userTypeSlugs: [],
    searchMatchType: 'STARTS_WITH',
  })
  
  const searchMatchTypeLabel =  searchMatchTypeLabels[filterValues.searchMatchType || 'EXACT']

  const [listingViewType, setListingViewType] = useState<ListingViewType>('ACTIVE_USERS')

  useEffect(() => {
    setPage(1)
  }, [listingViewType])

  const privileges = useContext(UserPrivilegeContext)

  const tenantId = privileges.user.default_org_unit_id || VODAFONE_GLOBAL_ID

  const canAddUsers = !!(
    isAdminUser(privileges.user) || userHasOrgPrivileges(
      ['tenant.admin.createUser'],
      tenantId,
      privileges.org_privileges
    )
  )

  const userManagementMetadata = useTenantUserManagementMetadata(tenantId)

  const activeUsers = useTenantUserManagementEstablishedUsers(
    tenantId, page, { enabled: listingViewType === 'ACTIVE_USERS' }
  )

  const pendingSSOUsers = useTenantUserManagementPendingSSOUsers({
    tenant_id: tenantId,
    page,
    enabled: listingViewType === 'PENDING_SSO_USERS',
    searchMatchType: filterValues.searchMatchType,
    emailFilter: filterValues.email,
    marketSlugs: filterValues.marketSlugs,
  })

  const searchEnabled = !!(
    listingViewType === 'ACTIVE_USERS' && (
      filterValues.email || (
        filterValues.marketSlugs &&
        filterValues.marketSlugs.length &&
        filterValues.marketSlugs.length !== marketOptions.length
      ) || (
        filterValues.userTypeSlugs &&
        filterValues.userTypeSlugs.length &&
        filterValues.userTypeSlugs.length < 2
      )
    )
  )

  const searchActiveUsers = useSearchTenantUserManagementEstablishedUsers({
    tenant_id: tenantId,
    query: filterValues.email,
    matchType: filterValues.searchMatchType,
    marketSlugs: filterValues.marketSlugs,
    userTypeSlugs: filterValues.userTypeSlugs,
    searchField: filterValues.email ? 'email' : null,
    enabled: searchEnabled,
  })

  const exportHook = useTenantUsersCSVExport()

  const editUser = (
    editUserId && (
      listingViewType === 'ACTIVE_USERS' ?
        (
          searchEnabled ?
            (searchActiveUsers.data || []).find( u => u.user_id === editUserId ) :
            (activeUsers.data && activeUsers.data.items || []).find( u => u.user_id === editUserId )
        ) :
        (pendingSSOUsers.data && pendingSSOUsers.data.items || []).find( u => u.user_id === editUserId )
    ) || null
  )

  const userListingHook = listingViewType === 'ACTIVE_USERS' ? activeUsers : pendingSSOUsers
  
  const activeUserListingHook = searchEnabled ? searchActiveUsers : userListingHook

  const classes = useStyles()

  const renderNA = <>N / A</>

  const tableConfig: TableConfig = useMemo((): TableConfig => {
    if( searchEnabled ){
      if( !searchActiveUsers.data ){
        return {
          items: [],
          total: 0,
          rowsPerPage: 20,
        }
      }
      return {
        items: searchActiveUsers.data.slice(((page - 1) * 20), page * 20).map(generateDerivedListingUser),
        total: searchActiveUsers.data.length,
        rowsPerPage: 20,
      }
    }
    if( !userListingHook.data ){
      return {
        items: [],
        total: 0,
        rowsPerPage: 20,
      }
    }
    return {
      items: userListingHook.data.items.map(generateDerivedListingUser),
      total: userListingHook.data.total,
      rowsPerPage: userListingHook.data.size,
    }
  }, [userListingHook.data, searchEnabled, searchActiveUsers, page])

  const renderers: CellRenderers<DerivedListingUser> = {
    /* eslint-disable react/display-name, react/prop-types */
    user_type: ({ user_type }) => (
      <span style={{position: 'relative', top: 2}}>
        {user_type === 'Vodafone User' ?
          <VodafoneLogoShort className={classes.tableVodafoneIcon} width={18} height={18} /> :
          <OpenInNew className={classes.tableExternalIcon} />
        }
      </span>
    ),
    name: ({ name }) => (
      !name ?
        renderNA :
        <>{name}</>
    ),
    enabled: ({ enabled }) => {
      const IconComponent = enabled ? CheckCircle : Block
      const className = enabled ? classes.enabledIcon : classes.disabledIcon
      const text = enabled ? 'Enabled' : 'Disabled'
      return (
        <span className={classes.flexCenter}>
          <IconComponent className={className} />
          { text }
        </span>
      )
    },
    market: ({ market }) => (
      !market ?
        renderNA :
        getMarketLabel(market)
    ),
    user_status: ({ enabled, user_status }) => {
      if( !enabled ){
        return (
          <span className={classes.flexCenter}>
            <Block className={classes.disabledIcon} />
            Disabled
          </span>
        )
      }
      if( !user_status ){
        return renderNA
      }
      const icon = (
        user_status === 'Active User' ?
          <CheckCircle className={classes.enabledIcon} /> :
          user_status === 'Unknown SSO User' ?
            <Help className={classes.neutralIcon} /> :
            null
      )
      return (
        <span className={classes.flexCenter}>
          {icon}
          {user_status}
        </span>
      )
    },
    ...(['last_login', 'activated_at', 'modified'] as (keyof DerivedListingUser)[]).reduce( (acc, key) => {
      acc[key] = (props): JSX.Element => <>{props[key] ? timeago(props[key] as DateType) : renderNA}</>
      return acc
    }, {} as CellRenderers<DerivedListingUser>),
    /* eslint-enable react/display-name, react/prop-types */
  }
  
  return (
    <Box p={4}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Typography className={classes.flexCenter} variant='h2'>
          User Management

          <RoundedPlainTextButtonMenu
            TriggerProps={{
              className: classes.spacedLeft,
              size: 'small',
              variant: 'contained',
              color: 'secondary',
              endIcon: <ArrowDropDown />,
            }}
            label={listingViewType === 'ACTIVE_USERS' ? 'Active Users' : 'Pending SSO Users'}
            value={listingViewType}
            options={[
              {
                value: 'ACTIVE_USERS',
                label: 'Active Users',
              },
              {
                value: 'PENDING_SSO_USERS',
                label: 'Pending SSO Users',
              }
            ] as MenuOption<ListingViewType>[]}
            onChange={(e, value): void => {
              setListingViewType(value)
            }} />
        </Typography>

        <Box display='flex' alignItems='center'>
          { listingViewType === 'ACTIVE_USERS' && (
            <RoundedPlainTextButton
              variant='contained'
              disabled={exportHook.isLoading}
              startIcon={
                exportHook.isLoading ?
                  <CircularProgress size='1em' color='inherit' /> :
                  <CloudDownload />
              }
              onClick={(): void => {
                exportHook.mutate({
                  tenant_id: tenantId,
                  query: filterValues.email,
                  matchType: filterValues.searchMatchType,
                  marketSlugs: filterValues.marketSlugs,
                  userTypeSlugs: filterValues.userTypeSlugs,
                  searchField: filterValues.email ? 'email' : null,
                })
              }}>
              Download CSV
            </RoundedPlainTextButton>
          )}

          { canAddUsers && (
            <Box ml={3}>
              <RoundedPlainTextButton
                color='primary'
                variant='contained'
                startIcon={
                  userManagementMetadata.data ?
                    <PersonAdd /> :
                    <CircularProgress size='1em' color='inherit' />
                }
                disabled={!userManagementMetadata.data}
                onClick={(): void => {
                  setEditUserId(null)
                  setOpen(true)
                }}>
                Add User
              </RoundedPlainTextButton>
            </Box>
          )}
        </Box>
      </Box>

      <Card className={classes.filterCard}>
        <FilterPanel
          name='Search Filters'
          padding={2}
          values={filterValues}
          onConfirm={setFilterValues}
          valueOrder={
            listingViewType === 'ACTIVE_USERS' ?
              ['email', 'userTypeSlugs', 'marketSlugs'] :
              ['email', 'marketSlugs']
          }
          validate={(values): boolean => {
            return (
              !values.email || (
                values.searchMatchType === 'EXACT' ?
                  isValidEmail(values.email) :
                  values.email.length >= 3
              )
            )
          }}
          displayConfig={{
            email: {
              label: 'Email',
              render: (value): JSX.Element => (
              <>
                <Typography variant='inherit' color='textSecondary'>{searchMatchTypeLabel}:</Typography>{' '}{value}
              </>
              ),
            },
            userTypeSlugs: {
              label: 'User Type',
              render: (value): JSX.Element => (
                <>
                  {value === 'agency-user' ? (
                    <span className={classes.flexCenter}>
                      <OpenInNew className={classes.filterIcon} />
                      Agency User
                    </span>
                  ) : (
                    <span className={classes.flexCenter}>
                      <VodafoneLogoShort className={classes.filterVodafoneIcon} />
                      Vodafone User
                    </span>
                  )}
                </>
              )
            },
            marketSlugs: {
              label: 'Markets',
              render: (value): JSX.Element => (
                marketOptionsBySlug[value].labelOverride ||
              <MarketDisplayLabel {...marketOptionsBySlug[value]} />
              ),
            },
          }}>
          { ({ values, updateValues }): JSX.Element => {
            return (
              <div className={classes.filters}>
                <div className={classes.filterControl}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    className={classes.inputWrapper}
                    inputProps={{
                      className: classes.input,
                    }}
                    startAdornment={
                      <InputAdornment position='start'>
                        { !values.email ? (
                          <Search />
                        ) : (
                          <Close
                            className={classes.pointer}
                            color='primary'
                            onClick={(): void => {
                              updateValues({ email: '' })
                            }} />
                        )}
                        <RoundedPlainTextButtonMenu
                          TriggerProps={{
                            className: classes.searchMatchTypeSelect,
                            variant: 'outlined',
                            size: 'small',
                            endIcon: <ArrowDropDown />,
                          }}
                          value={values.searchMatchType}
                          label={searchMatchTypeLabels[values.searchMatchType || 'EXACT']}
                          options={[
                            {value: 'EXACT', label: searchMatchTypeLabels.EXACT },
                            {value: 'STARTS_WITH', label: searchMatchTypeLabels.STARTS_WITH }
                          ] as MenuOption<SearchMatchType>[]}
                          onChange={(e, value): void => {
                            updateValues({ searchMatchType: value })
                          }} />
                      </InputAdornment>
                    }
                    placeholder='Search users by email'
                    value={values.email}
                    onChange={(e): void => {
                      updateValues({ email: e.target.value })
                    }} />
                </div>

                <Divider flexItem orientation='vertical' className={classes.filterDivider} />

                { listingViewType === 'ACTIVE_USERS' && (
                  <>
                    <div className={classes.filterControl}>
                      <CheckboxGroup
                        name='User Type'
                        FormGroupProps={{
                          className: classes.filterFormGroup,
                        }}
                        value={values.userTypeSlugs || []}
                        options={[
                          {
                            value: 'vodafone-user',
                            label: 'Vodafone',
                          },
                          {
                            value: 'agency-user',
                            label: 'Agency'
                          }
                        ]}
                        onChange={(userTypeSlugs): void => {
                          updateValues({ userTypeSlugs })
                        }} />
                    </div>
                    <Divider flexItem orientation='vertical' className={classes.filterDivider} />
                  </>
                )}
                
                <div className={classes.filterControl}>
                  <CheckboxGroup
                    name='Markets'
                    FormGroupProps={{
                      className: classes.filterFormGroup,
                    }}
                    value={values.marketSlugs || []}
                    options={
                      marketOptions.map( option => ({
                        value: option.slug,
                        label: option.labelOverride || <MarketDisplayLabel {...option} />,
                      }))
                    }
                    onChange={(marketSlugs): void => {
                      updateValues({ marketSlugs })
                    }} />
                </div>
              </div>
            )
          }}
        </FilterPanel>
      </Card>

      <Box mt={2}>
        <Card elevation={2} className={classes.card}>
          { activeUserListingHook.isRefetching && (
            <LinearProgress variant='indeterminate' className={classes.loadingIndicator} />
          )}
          { activeUserListingHook.data ? (
            <>
              <SimpleTable
                classes={{
                  tableRowClickable: classes.tableRowClickable,
                  tableCell: classes.tableCell,
                }}
                columns={columns}
                renderers={renderers}
                rows={tableConfig.items}
                onRowClick={(_, user): void => {
                  setEditUserId(user.user_id)
                  setOpen(true)
                }} />
              <Divider />
              <TablePagination
                component={'div'}
                ActionsComponent={TablePaginationActions}
                page={page - 1}
                rowsPerPage={tableConfig.rowsPerPage}
                rowsPerPageOptions={[tableConfig.rowsPerPage]}
                labelDisplayedRows={(pageInfo): JSX.Element => (
                  <Typography variant='h5'>
                    {`${pageInfo.from} - ${pageInfo.to} of ${pageInfo.count}`}
                  </Typography>
                )}
                count={tableConfig.total}
                onChangePage={(e, page): void => {
                  setPage(page + 1)
                }} />
            </>
          ) : (
            <Loader preset='centered' minHeight='20rem' />
          )}
        </Card>
      </Box>

      { userManagementMetadata.data && (
        <UserManagementDialog
          tenantId={tenantId}
          userManagementMetadata={userManagementMetadata.data}
          open={open}
          editUser={editUser}
          editUserContext={listingViewType}
          setListingViewType={setListingViewType}
          onClose={(): void => {
            setOpen(false)
            setTimeout(() => {
              setEditUserId(null)
            }, 200)
          }} />
      )}

      { exportHook.isLoading && (
        <Dialog
          open
          fullWidth
          maxWidth='sm'>
          <Card>
            <Loader
              preset='fullsize'
              minHeight='11rem'>
              <Box mt={3}>
                <Typography variant='h4'>
                  Generating user export...
                </Typography>
              </Box>
            </Loader>
          </Card>
        </Dialog>
      )}
    </Box>
  )
}
