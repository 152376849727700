import React, { Fragment, useMemo, useState } from 'react'

import {
  Alert,
  BackdropLoader,
  Box,
  BoxProps,
  Grid,
  MenuOption,
  RoundedPlainTextButtonMenu,
  useAppTheme,
} from '@percept/mui'

import { ArrowDropDown, ViewList } from '@percept/mui/icons'

import { ExamplesDialog } from './ExamplesDialog'

import { MetricDetailDialog } from './MetricDetailDialog'

import { 
  EmptyInsightsReportMessage,
  InsightsReportComponent,
  InsightsReportPriorityView,
} from './InsightsReportComponents'

import { DetailDialog } from './DetailDialog'

import { useInsightsReportTreeHierarchy } from './InsightsReportTree/lib'

import {
  InsightOutputSummary,
  ItemRendererDependencies,
  isNullInsightsReport,
} from './lib'

import {
  useFilteredMetricMetadata,
  usePotentialEfficiencyConfiguration,
  useReportSeriesReportEntity,
  useReportSeriesReportInsightsReport,
  useReportSeriesReportPayload,
  useReportSeriesReports,
} from '@percept/hooks'

import { get, some, sortBy } from 'lodash-es'

import {
  InsightsReportTypeParams,
  MetricIdentification,
  DimensionType,
  DistributionMetric as DistributionMetricType,
  InsightsReportOutputUnitType,
  MetricExample,
  InsightsReportType,
  MetricMetadataType,
  ReportEntityPayload,
  StructuralReportParams,
  DateType,
  EntityParams,
  ReportParams,
  ReportResultParams,
  Nullable,
  InsightsReportViewType,
  PotentialEfficiencyInsightConfiguration,
  ReportProvider,
} from '@percept/types'

import { coerceReportProvider, isErrorResponse } from '@percept/utils'

import { PropLoaderHook } from '@percept/hooks/libv2'
import { InsightsReportContext, InsightsReportContextValue } from './context'


const EMPTY_METRIC_EXAMPLES: MetricExample[] = []  // Idempotent typed ref


export type InsightsReportViewProps = {
  insightsReport: InsightsReportType
  reportEntity: ReportEntityPayload
  metricMetadata: MetricMetadataType
  start: DateType
  end: DateType
  hasPotentialEfficiencies?: boolean
  potentialEfficiencyConfigurations?: Record<string, PotentialEfficiencyInsightConfiguration>
  viewType?: InsightsReportViewType
  setViewType?: (viewType: InsightsReportViewType) => void
  BoxProps?: BoxProps
  enableMetricDrilldown?: boolean
  showSectionHealth?: boolean
  isNullableReportType?: boolean
} & StructuralReportParams & EntityParams

export const InsightsReportView = ({
  series_id,
  report_id,
  entity_type,
  entity_id,
  insightsReport,
  reportEntity,
  metricMetadata,
  start,
  end,
  viewType = 'LAYOUT',
  setViewType,
  hasPotentialEfficiencies = false,
  potentialEfficiencyConfigurations,
  BoxProps = {},
  showSectionHealth = false,
  enableMetricDrilldown = true,
  isNullableReportType = false,
}: InsightsReportViewProps): JSX.Element => {

  const { provider } = insightsReport

  const [activeViewType, setActiveViewType] = useState(viewType)

  const insightsReportTreeHierarchy = useInsightsReportTreeHierarchy(insightsReport)

  const isNullReport = useMemo(() => {
    return isNullableReportType && isNullInsightsReport({ sections: insightsReport.sections })
  }, [isNullableReportType, insightsReport.sections])

  const [activeMetric, setActiveMetric] = useState<MetricIdentification | null>(null)

  const [activeOutput, setActiveOutput] = useState<InsightOutputSummary | null>(null)

  const [activeOutputUnit, setActiveOutputUnit] = useState<InsightsReportOutputUnitType & { id?: string | null } | null>(null)

  const appTheme = useAppTheme()

  const itemRendererDependencies: ItemRendererDependencies = {
    appTheme,
    currency: reportEntity.currency_code,
    metricMetadata,
    reportEntity,
    potentialEfficiencyConfigurations,
    setActiveOutputUnit,
    setActiveOutput,
    setActiveMetric,
  }

  const effectiveViewType = setViewType ? viewType : activeViewType
  const viewTypeChangeHandler = setViewType || setActiveViewType

  return (
    <Fragment>
      <Box pt={2} pb={4}>

        <Box mb={3} display='flex' alignItems='center'>
          <Box pr={5} fontSize={14} fontWeight={appTheme.typography.fontWeightBold}>
            Insights provide an easy way to see what actions should be taken to improve performance. You
            can order by priority or view by area of analysis
          </Box>

          <Box display='flex' alignItems='center' marginLeft='auto'>
            <RoundedPlainTextButtonMenu
              TriggerProps={{
                variant: 'contained',
                color: 'secondary',
                size: 'small',
                startIcon: <ViewList />,
                endIcon: <ArrowDropDown />,
              }}
              value={effectiveViewType}
              label={
                effectiveViewType === 'PRIORITY' ? 'View By Priority' : 'View By Category'
              }
              onChange={(e, value): void => {
                viewTypeChangeHandler(value)
              }}
              options={[
                { value: 'PRIORITY', label: 'View By Priority' },
                { value: 'LAYOUT', label: 'View By Category' },
              ] as MenuOption<InsightsReportViewType>[]} />
          </Box>
        </Box>
        { isNullReport ? (
          <Grid container spacing={3}>
            <EmptyInsightsReportMessage insightsReportType={insightsReport.report_type} />
          </Grid>
        ) : effectiveViewType === 'PRIORITY' ? (
          <InsightsReportPriorityView
            {...itemRendererDependencies}
            {...insightsReport} />
        ) : (
          <InsightsReportComponent
            start={start}
            end={end}
            BoxProps={BoxProps}
            showSectionHealth={showSectionHealth}
            {...itemRendererDependencies}
            {...insightsReport} />
        )}
      </Box>

      { enableMetricDrilldown && activeMetric && (
        <MetricDetailDialog
          DialogProps={{
            open: true,
            onClose: (): void => setActiveMetric(null),
          }}
          title={activeOutputUnit && activeOutputUnit.text}
          provider={provider}
          series_id={series_id}
          report_id={report_id}
          entity_id={entity_id}
          entity_type={entity_type}
          reportMetric={reportEntity.metrics[activeMetric.metric_id]}
          metric_id={activeMetric.metric_id}
          segment={activeMetric.segment && activeMetric.segment.label as string | undefined}
          onSegmentChange={(segment): void => {
            if( reportEntity && activeMetric ){
              const reportMetric = reportEntity.metrics[activeMetric.metric_id] as DistributionMetricType
              const activeDimensionData = reportMetric['dimensions'][activeMetric.dimension || 'count']
              if( activeDimensionData.segments && activeDimensionData.segments[segment] ){
                setActiveMetric({
                  ...activeMetric,
                  segment: activeDimensionData.segments[segment],
                })
              }
            }
          }}
          dimension={(activeMetric.dimension || 'count') as DimensionType}
          onDimensionChange={(e, dimension): void => {
            if( activeMetric ){
              setActiveMetric({
                ...activeMetric,
                dimension
              })
            }
          }} />
      )}

      { activeOutput && (
        <ExamplesDialog
          appTheme={appTheme}
          DialogProps={{
            open: true,
            onClose: (): void => setActiveOutput(null),
          }}
          insightOutputUnitId={activeOutput.id}
          series_id={series_id}
          title={activeOutput.title}
          has_entities={activeOutput.has_entities}
          examples={activeOutput.entities || EMPTY_METRIC_EXAMPLES}
          text={activeOutput.text}
          metricMetadata={metricMetadata} />
      )}

      { activeOutputUnit && insightsReportTreeHierarchy && (
        <DetailDialog
          DialogProps={{
            open: true,
            onClose: (): void => setActiveOutputUnit(null),
          }}
          insightsReportTree={insightsReportTreeHierarchy}
          showSectionHealth={showSectionHealth}
          outputUnit={activeOutputUnit}
          title={activeOutputUnit.text}
          {...itemRendererDependencies} />
      )}
    </Fragment>
  )
}


type InsightsReportLoadProps = (
  StructuralReportParams &
  InsightsReportTypeParams &
  Partial<ReportResultParams>
)

type InsightsReportAutoloadProps = (
  Omit<InsightsReportLoadProps, 'report_id'> &
  Partial<Nullable<ReportParams>>
)


export const InsightsReport = ({
  report_id,
  result_id,
  series_id,
  insights_report_type,
  useInsightsReport = useReportSeriesReportInsightsReport,
  showSectionHealth = false,
  viewType,
  setViewType,
  BoxProps = {},
}: (
  InsightsReportAutoloadProps & {
    headerContent?: JSX.Element | null
    BoxProps?: BoxProps
    useInsightsReport?: PropLoaderHook<InsightsReportType, InsightsReportLoadProps>
    showSectionHealth?: boolean
    viewType?: InsightsReportViewType
    setViewType?: (viewType: InsightsReportViewType) => void
  }
 )): JSX.Element => {

  const [reports] = useReportSeriesReports({ series_id })

  const completedReports = useMemo(() => {
    return sortBy(reports.data, 'end').reverse().filter(
      r => !!(
        r.results.length && some(
          r.results,
          res => (
            res.result_type === 'INSIGHTS_REPORT'
            && res.result_subtype === insights_report_type.toUpperCase()
          )
        )
      )
    )
  }, [reports.data, insights_report_type])

  const latestReport = completedReports[0] || null

  report_id = report_id || (
    latestReport ?
      latestReport.id : ''
  )

  const [insightsReport] = useInsightsReport({
    report_id,
    series_id,
    insights_report_type,
    result_id,
  })

  const payloadProvider = get(insightsReport.data, 'provider', null)
  const provider = payloadProvider && coerceReportProvider(payloadProvider) as ReportProvider

  const [metricMetadata] = useFilteredMetricMetadata({ provider })

  const [efficiencyConfigurations] = usePotentialEfficiencyConfiguration({ provider })

  const potentialEfficiencyConfigurationMapping = useMemo(() => {
    if( efficiencyConfigurations.data ){
      return efficiencyConfigurations.data.reduce( (acc, e) => {
        acc[e.insight_id] = e
        return acc
      }, {} as Record<string, PotentialEfficiencyInsightConfiguration>)
    }
  }, [efficiencyConfigurations.data])

  const [report] = useReportSeriesReportPayload({
    series_id,
    report_id,
  })

  const entity_type = get(report.data, 'root_entity_type', null)
  const entity_id = get(report.data, 'root_entity_id', null)

  const [reportEntity] = useReportSeriesReportEntity({
    series_id,
    report_id,
    entity_type,
    entity_id,
  })

  const loadingSignals = [insightsReport, metricMetadata, report, reportEntity]
  for( const signal of loadingSignals ){
    if( isErrorResponse<any>(signal) ){
      return (
        <Alert
          marginY={8}
          marginX='auto'
          paddingX={5}
          paddingY={3}
          textAlign='center'
          variant='info'
          header='Insights Unavailable'
          maxWidth='30rem'
          message={
            <p>
              { signal.error.message }
            </p>
          } />
      )
    }
  }

  if( !(
    insightsReport.data && metricMetadata.data && reportEntity.data && report.data
    && report_id && entity_id && entity_type
  )){
    return <BackdropLoader />
  }

  const isNullableReportType = insights_report_type.toUpperCase() !== 'PRIMARY'

  const contextValue: InsightsReportContextValue = {
    series_id,
    report_id,
    result_id,
    version: Number(insightsReport.data.version),
    insights_report_type,
  }

  return (
    <InsightsReportContext.Provider value={contextValue}>
      <InsightsReportView
        series_id={series_id}
        report_id={report_id}
        insightsReport={insightsReport.data}
        reportEntity={reportEntity.data}
        entity_id={entity_id}
        entity_type={entity_type}
        metricMetadata={metricMetadata.data}
        hasPotentialEfficiencies={provider === 'adwords'}
        potentialEfficiencyConfigurations={potentialEfficiencyConfigurationMapping}
        start={report.data.start}
        end={report.data.end}
        showSectionHealth={showSectionHealth}
        viewType={viewType}
        setViewType={setViewType}
        isNullableReportType={isNullableReportType}
        BoxProps={BoxProps} />
    </InsightsReportContext.Provider>
  )
}
