import React, { useState } from 'react'

import {
  Box,
  ButtonProps,
  Divider,
} from '@material-ui/core'

import { ArrowDropDown } from '../../../icons'

import { DateType } from '@percept/types'

import { isoDate } from '@percept/utils'

import {
  DateRangePresetInput,
  DateRangePresetInputProps,
  defaultDateRangePresetOptions,
  resolveDateRangePresetLabel,
} from '../DateRangePresetInput'

import { ButtonPopover } from '../../Popovers'
import { RoundedPlainTextButton } from '../../Buttons'


const areDatesEqual = (a: DateType | null, b: DateType | null): boolean => Boolean(
  a && b && isoDate(new Date(a)) === isoDate(new Date(b))
)


export type DateRangePopoverProps = (
  DateRangePresetInputProps & {
    ButtonProps?: Partial<ButtonProps>
  }
)

export const DateRangePopover = ({
  dateRangePreset,
  value,
  dateRangePresetOptions = defaultDateRangePresetOptions,
  onChange,
  color,
  maxDate,
  ButtonProps = {},
  ...props
}: DateRangePopoverProps): JSX.Element => {
  const [localValue, setLocalValue] = useState(value)

  const [localDateRangePreset, setLocalDateRangePreset] = useState(dateRangePreset)

  const filtersApplied = (
    areDatesEqual(value[0], localValue[0])
    && areDatesEqual(value[1], localValue[1])
  )

  return (
    <ButtonPopover
      buttonContent={resolveDateRangePresetLabel(dateRangePreset, localValue)}
      ButtonComponent={RoundedPlainTextButton}
      endIcon={<ArrowDropDown />}
      onClose={(reason): void => {
        // NOTE - we want to reset local state when the dialog is closed
        // by the popover on click away or `Esc` keypress. However, this
        // close handler is also closed when we apply the filters - so
        // we need to examine the reason given for the close event to
        // avoid setting local state twice and getting out of sync.
        if( reason === 'backdropClick' || reason === 'escapeKeyDown' ){
          setLocalValue(value)
          setLocalDateRangePreset(dateRangePreset)
        }
      }}
      size='small'
      variant='contained'
      color='secondary'
      {...ButtonProps}>
      { ({ onClose }): JSX.Element => (
        <Box p={2}>
          <DateRangePresetInput
            color={color}
            maxDate={maxDate}
            dateRangePresetOptions={dateRangePresetOptions}
            value={localValue}
            dateRangePreset={localDateRangePreset}
            onChange={(value, dateRangePreset): void => {
              setLocalValue(value)
              setLocalDateRangePreset(dateRangePreset)
            }}
            {...props} />
          <Divider />
          <Box mt={2} display='flex' justifyContent='flex-end'>
            <RoundedPlainTextButton
              variant='contained'
              color={color}
              disabled={filtersApplied}
              onClick={(): void => {
                onChange(
                  [
                    new Date(localValue[0]),
                    new Date(localValue[1]),
                  ],
                  localDateRangePreset,
                )
                onClose()
              }}>
              { filtersApplied ? 'Filter Applied' : 'Apply Filter'}
            </RoundedPlainTextButton>
          </Box>
        </Box>
      )}
    </ButtonPopover>
  )
}
