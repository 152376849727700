import { differenceInDays } from 'date-fns'

import { TimeseriesGranularity } from '@percept/types'


export type ViewType = 'USERS' | 'VIEWS' 

export type ViewSegmentation = 'MARKET' | 'APPLICATION'

const orderedGranularities: TimeseriesGranularity[] = [
  'DAY',
  'WEEK',
  'MONTH',
  'QUARTER',
  'FINANCIAL_YEAR',
  'CALENDAR_YEAR',
]

export const getMaximumGranularity = (
  dateRange: [Date, Date]
): TimeseriesGranularity | null => {
  const dayDiff = Math.abs(differenceInDays(dateRange[0], dateRange[1]))
  let maximumGranularity: TimeseriesGranularity | null = null
  switch(true){
    case dayDiff < 7:
      maximumGranularity = 'DAY'
      break
    case dayDiff < 33:
      maximumGranularity = 'WEEK'
      break
    case dayDiff < 94:
      maximumGranularity = 'MONTH'
      break
    case dayDiff < 365:
      maximumGranularity = 'QUARTER'
      break
  }
  return maximumGranularity
}

export const getRequiredGranularity = (
  dateRange: [Date, Date],
  currentGranularity: TimeseriesGranularity
): TimeseriesGranularity => {
  const maximumGranularity = getMaximumGranularity(dateRange)

  if( maximumGranularity === null ){
    return currentGranularity
  }

  const currentGranularityIndex = orderedGranularities.indexOf(currentGranularity)
  const maximumGranularityIndex = orderedGranularities.indexOf(maximumGranularity)
  if( currentGranularityIndex > maximumGranularityIndex ){
    return maximumGranularity
  }

  return currentGranularity
}

export const getAvailableGranularities = (
  dateRange: [Date, Date],
): TimeseriesGranularity[] => {
  const maximumGranularity = getMaximumGranularity(dateRange)
  if( maximumGranularity === null ){
    return orderedGranularities
  }
  return orderedGranularities.slice(
    0, orderedGranularities.indexOf(maximumGranularity) + 1
  )
}
