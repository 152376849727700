import React from 'react'
import { Box, ButtonProps, Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { RoundedPlainTextButton } from '../Buttons'
import { Check, Close } from '@material-ui/icons'


export type ConfigmDialogProps = {
  title: React.ReactNode
  content: React.ReactNode
  confirmText: string
  cancelText?: string
  confirmIcon?: JSX.Element
  confirmDisabled?: boolean
  onConfirm: () => void
  onCancel: () => void
  ButtonComponent?: React.FC<ButtonProps>
} & Partial<Omit<DialogProps, 'children' | 'onClose'>>

export const ConfirmDialog = ({
  title,
  content,
  confirmText,
  cancelText = 'Cancel',
  confirmIcon,
  confirmDisabled = false,
  onConfirm,
  onCancel,
  ButtonComponent = RoundedPlainTextButton,
  ...props
}: ConfigmDialogProps): JSX.Element => {
  return (
    <Dialog
      open
      fullWidth
      maxWidth='sm'
      {...props}
      onClose={onCancel}>
      <DialogTitle>
        { title }
      </DialogTitle>
      <DialogContent>
        { content }
        <Box mb={1} mt={2} display='flex' alignItems='center' justifyContent='flex-end'>
          <ButtonComponent
            variant='contained'
            startIcon={<Close />}
            onClick={onCancel}>
            { cancelText }
          </ButtonComponent>
          <Box ml={2}>
            <ButtonComponent
              variant='contained'
              color='primary'
              disabled={confirmDisabled}
              startIcon={confirmIcon || <Check />}
              onClick={onConfirm}>
              { confirmText }
            </ButtonComponent>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
