import { lighten, vodafonePalette } from '@percept/mui'

import {
  ChartData,
  MultiDataset,
  SVGDatumType,
  financialYearFormatter,
} from '@percept/mui/charts'

import {
  CombinedWastageWithSegmentation,
  CreativeWastageWithSegmentation,
  DigitalWastageWithSegmentation,
  WastageComparisonType,
  WastageProvider,
  WastageSegmentation,
  WastageVariant,
} from '@percept/hooks'

import { get, mapValues, sortBy } from 'lodash-es'

import {
  AllDatasets,
  DatasetsColorScheme,
  DatasetConfiguration,
  PathDataset,
  SpendDataset,
} from './typings'

import { getDatasetTotal } from '@percept/mui/charts/lib'

import { vodafoneMarkets } from 'vodafoneMarkets'

import { TickFormatter } from '@visx/axis'

import { providerLabelMap } from '@percept/constants'

import {
  addMonths,
  endOfMonth,
  format,
  max,
  min,
  startOfMonth,
  startOfQuarter,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns'

import { ComparisonMethod, getComparisonFunction, isoDate, produceKeyedMapping } from '@percept/utils'

import { Nullable } from '@percept/types'


type AllowableDateRange = Record<'minDate' | 'maxDate', Date>

export const getAllowableDateRange = ({
  minDate,
  maxDate,
}: Nullable<AllowableDateRange>): AllowableDateRange => {
  const today = new Date()
  if( !(minDate && maxDate) ){
    return {
      minDate: today,
      maxDate: today,
    }
  }

  // CreativeX goes back to April 2022
  const earliestCreativeXMDate = new Date('2022-04-01')

  /*
    CreativeX data updates on the 7th of the month, and is
    available at a monthly granularity only. To ensure that
    dates are comparable across all wastage types, we restrict
    the range to complete months where we will have CreativeX
    data available.
  */
  const creativeXMonthDelta = today.getDate() < 7 ? 2 : 1
  const latestCreativeXDate = startOfMonth(subMonths(today, creativeXMonthDelta))

  const maxDateMonthDelta = (
    maxDate.getDate() === endOfMonth(maxDate).getDate() ?
      0 :
      1
  )
  const latestMaxDate = subMonths(maxDate, maxDateMonthDelta)

  const earliestMinDate = minDate.getDay() === 1 ? minDate : addMonths(minDate, 1)

  return {
    minDate: startOfMonth(
      max([earliestCreativeXMDate, earliestMinDate])
    ),
    maxDate: endOfMonth(
      min([latestCreativeXDate, latestMaxDate])
    ),
  }
}


export const datasetColorSchemesMapping: Record<WastageVariant, DatasetsColorScheme> = {
  COMBINED: {
    efficientSpend: vodafonePalette.aubergine,
    wastedSpend: vodafonePalette.red,
    creativeQualityScore: lighten(vodafonePalette.freshOrange, 0.2),
    wastagePercentage: vodafonePalette.aquaBlue,
  },
  DIGITAL: {
    efficientSpend: vodafonePalette.turquoise,
    wastedSpend: vodafonePalette.red,
    creativeQualityScore: lighten(vodafonePalette.freshOrange, 0.2),
    wastagePercentage: lighten(vodafonePalette.redViolet, 0.6),
  },
  CREATIVE: {
    efficientSpend: vodafonePalette.slateGrey,
    wastedSpend: vodafonePalette.red,
    creativeQualityScore: lighten(vodafonePalette.freshOrange, 0.2),
    wastagePercentage: vodafonePalette.springGreen,
  },
}

/* 
NOTE - Due to the requirement for SVG export, we want to be able to
render all parts of the chart including legends within an SVG element.
To display legends in a single line, we have to know the width each one
will take up as of course SVG doesn't support anything like flex-box for
layouts. We therefore set a font size and some displayLabelWidth attributes
here, which are necessarily produced manually knowing the contents of the
display label and font size through trial and error.
*/

export const legendFontSize = 13

export const datasetConfigurations: Record<AllDatasets, DatasetConfiguration> = {
  creativeQualityScore: {
    displayLabel: 'Creative Quality Score',
    displayLabelWidth: 128,
    markerId: 'marker-dot-cqs',
    markerUrl: 'url(#marker-dot-cqs)',
  },
  wastagePercentage: {
    displayLabel: 'Wastage Percentage',
    displayLabelWidth: 112,
    markerId: 'marker-dot-wastage',
    markerUrl: 'url(#marker-dot-wastage)',
  },
  efficientSpend: {
    displayLabel: 'Efficient Spend',
    displayLabelWidth: 92,
  },
  wastedSpend: {
    displayLabel: 'Wasted Spend',
    displayLabelWidth: 86,
  }
}

export const pathDatasets: PathDataset[] = ['creativeQualityScore', 'wastagePercentage']


function extractChartData<T extends { segment_value: string }>(
  items: T[],
  getValue: (item: T) => number | null,
  color?: string
): ChartData {
  return items.map( d => ({
    label: d.segment_value,
    value: getValue(d),
    color,
  }))
}


const wastageDatasetValueCalculators = {
  COMBINED: {
    wastage: (d: CombinedWastageWithSegmentation): number | null => d.wastage_cost,
    efficient: (d: CombinedWastageWithSegmentation): number | null => ((d.total_cost || 0) - (d.wastage_cost || 0)),
    percentage: (d: CombinedWastageWithSegmentation): number | null => (
      d.wastage_ratio === null ? null : d.wastage_ratio * 100
    ),
  },
  DIGITAL: {
    wastage: (d: DigitalWastageWithSegmentation): number | null => d.wastage_cost,
    efficient: (d: DigitalWastageWithSegmentation): number | null => ((d.total_cost || 0) - (d.wastage_cost || 0)),
    percentage: (d: CombinedWastageWithSegmentation): number | null => (
      d.wastage_ratio === null ? null : d.wastage_ratio * 100
    ),
  },
  CREATIVE: {
    wastage: (d: CreativeWastageWithSegmentation): number | null => d.creative_wastage_converted_cost,
    efficient: (d: CreativeWastageWithSegmentation): number | null => (
      (d.total_converted_cost || 0) - (d.creative_wastage_converted_cost || 0)
    ),
    percentage: (d: CreativeWastageWithSegmentation): number | null => (
      d.creative_wastage_percentage === null ? null : d.creative_wastage_percentage
    ),
  }
}


export const providerOrder: WastageProvider[] = [
  'GOOGLE_ADS',
  'FACEBOOK',
  'DV360',
  'ADFORM',
  'TIKTOK',
  'AMAZON_ADS'
]

const wastageSegmentationSortMethods: Partial<
  Record<WastageSegmentation, (d: SVGDatumType) => number | null>
> = {
  PROVIDER: d => providerOrder.indexOf(d.label as WastageProvider),
  ORG_UNIT: d => vodafoneMarkets.findIndex(m => m.id === d.label as string),
}


function getWastageDatasets<T extends { segment_value: string }>({
  items,
  colorScheme,
  wastageSpendCalculator,
  efficientSpendCalculator,
  dataSortMethod,
}: {
  items: T[]
  wastageSpendCalculator: (item: T) => number | null
  efficientSpendCalculator: (item: T) => number | null
  colorScheme: DatasetsColorScheme
  dataSortMethod?: (item: SVGDatumType) => number | null
}): MultiDataset[] {
  const configs: {
    key: AllDatasets,
    getItemValue: (item: T) => number | null
  }[] = [
    {key: 'efficientSpend', getItemValue: efficientSpendCalculator},
    {key: 'wastedSpend', getItemValue: wastageSpendCalculator},
  ]
  return configs.map( ({ key, getItemValue }) => {
    const { displayLabel } = datasetConfigurations[key]
    const color = colorScheme[key]
    return {
      key,
      label: displayLabel,
      color,
      data: sortBy(
        extractChartData(items, getItemValue, color),
        dataSortMethod || 'label',
      ),
    }
  })
}


export function getSpendDatasets({
  variant,
  segmentation,
  combinedWastage,
  digitalWastage,
  creativeWastage,
}: {
  variant: WastageVariant
  segmentation: WastageSegmentation
  combinedWastage: CombinedWastageWithSegmentation[] | undefined
  digitalWastage: DigitalWastageWithSegmentation[] | undefined
  creativeWastage: CreativeWastageWithSegmentation[] | undefined
}): MultiDataset[] {
  let spendDatasets: MultiDataset[] = []
  const dataSortMethod = wastageSegmentationSortMethods[segmentation]
  const colorScheme = datasetColorSchemesMapping[variant]
  switch(variant){
    case 'COMBINED': {
      if( combinedWastage ){
        spendDatasets = getWastageDatasets({
          items: combinedWastage,
          colorScheme,
          wastageSpendCalculator: wastageDatasetValueCalculators.COMBINED.wastage,
          efficientSpendCalculator: wastageDatasetValueCalculators.COMBINED.efficient,
          dataSortMethod,
        })
      }
      break
    }
    case 'CREATIVE': {
      if( creativeWastage ){
        spendDatasets = getWastageDatasets({
          items: creativeWastage,
          colorScheme,
          wastageSpendCalculator: wastageDatasetValueCalculators.CREATIVE.wastage,
          efficientSpendCalculator: wastageDatasetValueCalculators.CREATIVE.efficient,
          dataSortMethod,
        })
      }
      break
    }
    case 'DIGITAL': {
      if( digitalWastage ){
        spendDatasets = getWastageDatasets({
          items: digitalWastage,
          colorScheme,
          wastageSpendCalculator: wastageDatasetValueCalculators.DIGITAL.wastage,
          efficientSpendCalculator: wastageDatasetValueCalculators.DIGITAL.efficient,
          dataSortMethod,
        })
      }
      break
    }
    default: break
  }

  return spendDatasets
}


export const getCreativeQualityScoreDataset = (
  creativeWastage: CreativeWastageWithSegmentation[] | undefined,
  segmentation: WastageSegmentation,
): ChartData | null => {
  if( !creativeWastage ){
    return null
  }
  const chartData: ChartData = creativeWastage.map( d => ({
    label: d.segment_value,
    value: (
      d.creative_quality_score === null ?
        null : d.creative_quality_score * 100
    ),
  }))
  return sortBy(
    chartData,
    wastageSegmentationSortMethods[segmentation] || 'label',
  )
}

export const getWastagePercentageDataset = ({
  variant,
  segmentation,
  combinedWastage,
  digitalWastage,
  creativeWastage,
}: {
  variant: WastageVariant
  segmentation: WastageSegmentation
  combinedWastage: CombinedWastageWithSegmentation[] | undefined
  digitalWastage: DigitalWastageWithSegmentation[] | undefined
  creativeWastage: CreativeWastageWithSegmentation[] | undefined
}): ChartData | null => {
  let dataset: ChartData | null = null
  switch(variant){
    case 'COMBINED': {
      if( combinedWastage ){
        dataset = extractChartData(
          combinedWastage,
          wastageDatasetValueCalculators.COMBINED.percentage,
        )
      }
      break
    }
    case 'DIGITAL': {
      if( digitalWastage ){
        dataset = extractChartData(
          digitalWastage,
          wastageDatasetValueCalculators.DIGITAL.percentage,
        )
      }
      break
    }
    case 'CREATIVE': {
      if( creativeWastage ){
        dataset = extractChartData(
          creativeWastage,
          wastageDatasetValueCalculators.CREATIVE.percentage,
        )
      }
      break
    }
  }
  return dataset && sortBy(
    dataset,
    wastageSegmentationSortMethods[segmentation] || 'label',
  )
}


export const getDerivedTotalDataset = ({
  efficientSpendDataset,
  wastedSpendDataset,
  variant,
}: {
  efficientSpendDataset: ChartData | null
  wastedSpendDataset: ChartData | null
  variant: WastageVariant
}): ChartData | null => {
  if( !(efficientSpendDataset && wastedSpendDataset) ){
    return null
  }
  const totalValues: Record<SpendDataset, number> = {
    efficientSpend: getDatasetTotal(efficientSpendDataset),
    wastedSpend: getDatasetTotal(wastedSpendDataset),
  }
  const spendDatasets: SpendDataset[] = ['efficientSpend', 'wastedSpend']
  const colorScheme = datasetColorSchemesMapping[variant]
  return spendDatasets.map( spendDataset => ({
    label: datasetConfigurations[spendDataset].displayLabel,
    value: totalValues[spendDataset],
    color: colorScheme[spendDataset],
  }))
}

export const formatMonth = (date: Date): string => (
  format(date, 'MMM-yy')
)

const formatCalendarYear = (date: Date): string => (
  format(date, 'yyyy')
)

const formatCalendarQuarter = (date: Date): string => (
  format(date, 'QQQ yyyy')
)

const formatFinancialQuarter = (date: Date): string => {
  const financialQuarter = format(
    subQuarters(startOfQuarter(date), 1),
    'QQQ'
  )
  return `${financialQuarter} ${financialYearFormatter(date)}`
}

export const vodafoneMarketsById = produceKeyedMapping(vodafoneMarkets, 'id')

export const wastageSegmentationTickFormatters: Record<WastageSegmentation, TickFormatter<string>> = {
  PROVIDER: v => providerLabelMap[v as WastageProvider],
  ORG_UNIT: v => vodafoneMarketsById[v].name,
  QUARTER: v => formatCalendarQuarter(new Date(v)),
  MONTH: v => formatMonth(new Date(v)),
  CALENDAR_YEAR: v => formatCalendarYear(new Date(v)),
  FINANCIAL_YEAR: v => financialYearFormatter(new Date(v)),
}


export const supportedComparisonTypeMapping: Partial<Record<WastageSegmentation, WastageComparisonType[]>> = {
  MONTH: ['MONTH', 'YEAR'],
  QUARTER: ['QUARTER', 'YEAR'],
  CALENDAR_YEAR: ['YEAR'],
  FINANCIAL_YEAR: ['YEAR'],
}


export const deriveRequiredComparisonPeriod = (
  dateRange: [Date | null, Date | null],
  comparisonType: WastageComparisonType | null,
  segmentation: WastageSegmentation,
): [Date | null, Date | null] => {
  const [start, end] = dateRange
  
  if( !(start && end && comparisonType) ){
    return [null, null]
  }

  const supportedSegmentations = supportedComparisonTypeMapping[segmentation]

  if( !supportedSegmentations || !supportedSegmentations.includes(comparisonType) ){
    return [null, null]
  }

  switch(comparisonType){
    case 'MONTH': {
      if( segmentation === 'MONTH' ){
        return [subMonths(start, 1), endOfMonth(subMonths(end, 1))]
      }
      if( segmentation === 'CALENDAR_YEAR' || segmentation === 'FINANCIAL_YEAR' ){
        return [subYears(start, 1), endOfMonth(subYears(end, 1))]
      }
      return [null, null]
    }
    case 'QUARTER': {
      if( segmentation === 'QUARTER' ){
        return [subQuarters(start, 1), endOfMonth(subQuarters(end, 1))]
      }
      if( segmentation === 'CALENDAR_YEAR' || segmentation === 'FINANCIAL_YEAR' ){
        return [subYears(start, 1), endOfMonth(subYears(end, 1))]
      }
      return [null, null]
    }
    case 'YEAR': {
      if( segmentation === 'MONTH' || segmentation === 'CALENDAR_YEAR' || segmentation === 'FINANCIAL_YEAR'  ){
        return [subYears(start, 1), endOfMonth(subYears(end, 1))]
      }
      return [null, null]
    }
    default:
      return [null, null] 
  }
}


const dateSubtractors: Record<WastageComparisonType, (date: Date, amount: number) => Date> = {
  MONTH: subMonths,
  QUARTER: subQuarters,
  YEAR: subYears,
}

export type DatumWithBaseline = SVGDatumType & { baselineValue: number | null }

export type ChartDataWithBaseline = ChartData<DatumWithBaseline>

const deriveComparisonDataset = ({
  baseline,
  comparison,
  comparisonMethod,
  comparisonType,
}: Record<'baseline' | 'comparison', ChartData> & {
  comparisonMethod: ComparisonMethod
  comparisonType: WastageComparisonType
}): ChartDataWithBaseline => {
  const comparisonFunction = getComparisonFunction(comparisonMethod)
  const baselineMapping = produceKeyedMapping(baseline, 'label')
  const dateSubtractor = dateSubtractors[comparisonType]
  return comparison.map( d => {
    const baselineDate = dateSubtractor(new Date(d.label), 1)
    const matchedDatum = baselineMapping[isoDate(baselineDate)]
    const baselineValue = get(matchedDatum, 'value', null)
    return {
      label: d.label,
      baselineValue,
      value: comparisonFunction(d.value, baselineValue),
    }
  })
}

export const deriveComparisons = ({
  datasets,
  segmentation,
  comparisonMethod,
  comparisonType,
}: {
  datasets: Record<AllDatasets, Record<'latest' | 'earliest', ChartData | null>>
  segmentation: WastageSegmentation
  comparisonMethod: ComparisonMethod
  comparisonType: WastageComparisonType
}): Record<AllDatasets, ChartDataWithBaseline | null> | null => {

  const supportedComparisons = supportedComparisonTypeMapping[segmentation]
  if( !supportedComparisons ){
    return null
  }

  return mapValues(
    datasets,
    ({ latest, earliest }) => {
      if( !(latest && earliest) ){
        return null
      }
      return deriveComparisonDataset({
        baseline: earliest,
        comparison: latest,
        comparisonMethod,
        comparisonType,
      })
    }
  )
}
