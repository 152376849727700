import React, { useContext } from 'react'

import { GlobalUserCalculationView, PrefillSubmissionForm } from './estimateComponents'

import { MarketingCPMsForm } from './MarketingCPMsForm'

import { SubApplicationTabContext, SubApplicationTabs } from 'components/SubApplicationTabs'
import { SponsorshipsStateContext } from './contexts'
import { SponsorshipsView } from './typings'


export const GlobalView = (): JSX.Element => {
  const [state, setState] = useContext(SponsorshipsStateContext)

  const setActiveTabValue = (tabValue: SponsorshipsView): void => {
    setState({ view: tabValue as SponsorshipsView, estimateId: null })
  }

  return (
    <SubApplicationTabContext.Provider
      value={{
        activeTabValue: state.view,
        setActiveTabValue: setActiveTabValue as ((value: string) => void),
      }}>
      <SubApplicationTabs
        tabs={[
          {
            label: 'View Calculations',
            value: 'calculations',
            content: <GlobalUserCalculationView />,
          },
          {
            label: 'Submit Prefill Data',
            value: 'submit-prefills',
            content: <PrefillSubmissionForm onSubmit={(): void => setActiveTabValue('calculations')} />,
          },
          {
            label: 'Submit Marketing CPMs',
            value: 'submit-cpms',
            content: <MarketingCPMsForm onSubmit={(): void => setActiveTabValue('calculations')} />,
          }
        ]} />
    </SubApplicationTabContext.Provider>
  )
}
