import React, { useMemo, useState } from 'react'

import {
  Box,
  BoxProps,
  Chip,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core'

import { RoundedPlainTextButton } from '../Buttons'

import { ButtonDialog, ButtonDialogProps } from '../Dialogs'

import { FilterList } from '../../icons'

import { makeAppStyles } from '../../themes'

import { get, isEqual, xor } from 'lodash-es'


type NullableValues<T extends object> = {
  [K in keyof T]: T[K] | null 
}


export type FilterPanelRenderProps<T extends object> = {
  values: T
  updateValues: (partialUpdate: Partial<T>) => void
}

export type FilterPanelProps<T extends object> = {
  values: T
  name?: string
  emptyMessage?: React.ReactNode | null
  // NOTE - `displayConfig` is partial, to enable consuming code
  // to place arbitrary state in values or model related value state
  // which doesn't require it's own display configuration and may be
  // combined with th display of other values.
  displayConfig: Partial<{
    [Property in keyof T]: {
      label: React.ReactNode
      nullable?: boolean
      render: (
        value: (
          T[Property] extends any[] ?
            NonNullable<T[Property][number]> :
            NonNullable<T[Property]>
        ),
        index?: number
      ) => React.ReactNode | null
    }
  }>
  validate?: (values: T) => boolean
  valueOrder?: (keyof T)[]
  onConfirm: (values: T) => void
  children: (props: FilterPanelRenderProps<T>) => JSX.Element
  DialogProps?: Partial<DialogProps>
  ButtonDialogProps?: Partial<
    Omit<ButtonDialogProps, 'children' | 'onClose' | 'onOpen'>
  >
} & Omit<BoxProps, 'children'>


const useStyles = makeAppStyles( theme => ({
  editButton: {
    marginLeft: 'auto',
    alignSelf: 'flex-start',
    minWidth: 'fit-content',
  },
  dialogContentDividers: {
    padding: theme.spacing(3),
  },
  filterContainer: {
    display: 'flex',
    marginRight: theme.spacing(3),
  },
  filterDisplay: {
    alignSelf: 'flex-start',
    marginRight: theme.spacing(2),
  },
  filterLabel: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0.5),
  },
  filterContent: {
    lineHeight: '32px',
  },
  filterChip: {
    marginRight: theme.spacing(1),
    '&:last-of-type': {
      marginRight: 0,
    }
  },
}))


function areFiltersEqual<T extends object>(a: T, b: T): boolean {
  const keys = Object.keys(a) as (keyof T)[]
  for( const key of keys ){
    if( Array.isArray(a[key]) ){
      if( b[key] && isEqual(a[key].sort(), (b[key] as any[]).sort()) ){
        continue
      }
    }
    if( isEqual(a[key], b[key]) ){
      continue
    }
    return false
  }
  return true
}


export function FilterPanel<T extends object>({
  values,
  validate,
  displayConfig,
  valueOrder,
  onConfirm,
  children,
  name = 'Filters',
  DialogProps,
  ButtonDialogProps,
  emptyMessage,
  ...props
}: FilterPanelProps<T>): JSX.Element {

  const [localValues, setLocalValues] = useState<T>(values)

  const updateValues = (partialValues: Partial<T>): void => {
    setLocalValues( prev => ({...prev, ...partialValues}))
  }

  const classes = useStyles()

  const allFiltersApplied = useMemo(() => {
    return areFiltersEqual(values, localValues)
  }, [values, localValues])

  const disabled = allFiltersApplied || (!!validate && !validate(localValues))

  valueOrder = valueOrder || Object.keys(values) as (keyof T)[]

  const elements = valueOrder.reduce( (acc, key) => {
    const display = displayConfig[key]
    if( !display ){
      return acc
    }
    const value = values[key]
    if( !value ){
      return acc
    }
    let content: JSX.Element | null = null
    const nullable = !!get(displayConfig, [key, 'nullable'], true)
    if( Array.isArray(value) ){
      if( !value.length ){
        return acc
      }
      content = <>
          { value.map( (v, i) => (
            <Chip
              key={`${key as string}-value-${i}`}
              className={classes.filterChip}
              size='small'
              label={display.render(v, i)}
              onDelete={
                !nullable ? 
                  undefined :
                  (
                    (): void => {
                      const update = {
                        [key]: xor((values[key] || []) as [], [v])
                      }
                      updateValues(update as Partial<T>)
                      onConfirm({
                        ...values,
                        ...update,
                      })
                    }
                  )
              } />
          )) }
        </>
    }else{
      content = (
        <Chip
          key={`${key as string}-value`}
          className={classes.filterChip}
          size='small'
          label={display.render(value as any)}
          onDelete={
            !nullable ?
              undefined :
              (
                (): void => {
                  updateValues({
                    [key]: null
                  } as Partial<T>)
                  onConfirm({
                    ...values,
                    [key]: null,
                  })
                }
              )
          } />
      )
    }
    acc.push(
      <div key={key as string} className={classes.filterDisplay}>
        <Typography className={classes.filterLabel} variant='subtitle1'>{ display.label }</Typography>
        <span className={classes.filterContent}>
          { content }
        </span>
      </div>
    )
    return acc
  }, [] as JSX.Element[])


  return (
    <Box display='flex' flexWrap='nowrap' alignItems='center' {...props}>

      { !elements.length && (
        emptyMessage || <Typography color='textSecondary'>No {name} Applied</Typography>
      )}

      <div className={classes.filterContainer}>
        { elements }
      </div>

      <ButtonDialog
        ButtonComponent={RoundedPlainTextButton}
        className={classes.editButton}
        startIcon={<FilterList />}
        variant='contained'
        size='small'
        buttonContent={`Edit ${name}`}
        color='secondary'
        onOpen={(): void => setLocalValues(values)}
        DialogProps={{
          scroll: 'paper',
          maxWidth: false,
          ...DialogProps
        }}
        {...ButtonDialogProps}>
        { ({ onClose }): JSX.Element => (
          <>
            <DialogTitle>{ name }</DialogTitle>
            <DialogContent
              classes={{
                dividers: classes.dialogContentDividers,
              }}
              dividers>
              { children({ values: localValues, updateValues })}
            </DialogContent>
            <DialogActions>
              <RoundedPlainTextButton
                variant='contained'
                onClick={onClose}>
                Cancel
              </RoundedPlainTextButton>
              <Box ml={2}>
                <RoundedPlainTextButton
                  variant='contained'
                  color='primary'
                  disabled={disabled}
                  onClick={(): void => {
                    onConfirm(localValues)
                    onClose()
                  }}>
                  { allFiltersApplied ? `${name} Applied` : `Apply ${name}` }
                </RoundedPlainTextButton>
              </Box>
            </DialogActions>
          </>
        )}
      </ButtonDialog>

    </Box>
  )
}
