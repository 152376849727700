
import { actionCreator, typedActionCreator } from '@percept/redux'

import { push } from 'connected-react-router'

import { AnyPerformanceDimension, InsightsReportViewType, PerformanceComparisonRange, ReduxAction, ReferenceDateBehaviour, TipLabel } from '@percept/types'

import { AppTheme } from '@percept/mui'
import { DashboardQueryStringKey } from './types'


/* Types */

export const REHYDRATE = 'REHYDRATE'
export const PERSIST = 'PERSIST'
export const TRACK = 'TRACK'

export const LOAD_LAYOUT = 'LOAD_LAYOUT'

export const LOAD_METRIC_FILTER = 'LOAD_METRIC_FILTER'

export const TOGGLE_SETTINGS = 'TOGGLE_SETTINGS'
export const TOGGLE_CHART_SUMMARY = 'TOGGLE_CHART_SUMMARY'
export const TOGGLE_CHART_DETAIL = 'TOGGLE_CHART_DETAIL'
export const TOGGLE_TIPS = 'TOGGLE_TIPS'
export const TOGGLE_TIP = 'TOGGLE_TIP'
export const DISABLE_ALL_TIPS = 'DISABLE_ALL_TIPS'
export const ENABLE_ALL_TIPS = 'ENABLE_ALL_TIPS'
export const DISMISS_TIP_TOAST = 'DISMISS_TIP_TOAST'

export const PERFORMANCE_REPORT_ADD_SERIES = 'PERFORMANCE_REPORT_ADD_SERIES'
export const PERFORMANCE_REPORT_REMOVE_SERIES = 'PERFORMANCE_REPORT_REMOVE_SERIES'
export const PERFORMANCE_REPORT_CLEAR_SERIES = 'PERFORMANCE_REPORT_CLEAR_SERIES'

export const REPORT_ERROR = 'REPORT_ERROR'


/* General */

export const rehydrate = actionCreator(REHYDRATE)
export const persist = actionCreator(PERSIST)
export const track = actionCreator(TRACK)

export const navigate = push


/* App State */

export const SET_TARGET_CURRENCY = 'SET_TARGET_CURRENCY'

export const setTargetCurrency = (payload: string): ReduxAction => ({
  type: SET_TARGET_CURRENCY,
  payload,
})


export const SET_APP_THEME = 'SET_APP_THEME'

export const setAppTheme = (payload: AppTheme): ReduxAction => ({
  type: SET_APP_THEME,
  payload,
})


export const SET_REFERENCE_DATE = 'SET_REFERENCE_DATE'

export const setReferenceDate = (payload: Date): ReduxAction => ({
  type: SET_REFERENCE_DATE,
  payload,
})


export const SET_REFERENCE_DATE_BEHAVIOUR = 'SET_REFERENCE_DATE_BEHAVIOUR'

export const setReferenceDateBehaviour = (payload: ReferenceDateBehaviour): ReduxAction => ({
  type: SET_REFERENCE_DATE_BEHAVIOUR,
  payload,
})


export const SET_PERFORMANCE_REPORTING_DIMENSION = 'SET_PERFORMANCE_REPORTING_DIMENSION'

export const setPerformanceReportingDimension = (payload: AnyPerformanceDimension): ReduxAction => ({
  type: SET_PERFORMANCE_REPORTING_DIMENSION,
  payload,
})


export const SET_PERFORMANCE_COMPARISON_RANGE = 'SET_PERFORMANCE_COMPARISON_RANGE'

export const setPerformanceComparisonRange = (payload: PerformanceComparisonRange): ReduxAction => ({
  type: SET_PERFORMANCE_COMPARISON_RANGE,
  payload,
})


export const SET_INSIGHTS_REPORT_VIEW_TYPE = 'SET_INSIGHTS_REPORT_VIEW_TYPE'

export const setInsightsReportViewType = (payload: InsightsReportViewType): ReduxAction => ({
  type: SET_INSIGHTS_REPORT_VIEW_TYPE,
  payload,
})


export const SET_SAVED_QUERY_STRINGS = 'SET_SAVED_QUERY_STRINGS'

export const setSavedQueryStrings = (payload: Partial<Record<DashboardQueryStringKey, string>>): ReduxAction => ({
  type: SET_SAVED_QUERY_STRINGS,
  payload,
})


/* Settings */

export const toggleSettings = actionCreator(TOGGLE_SETTINGS)

export const toggleChartSummary = actionCreator(TOGGLE_CHART_SUMMARY)

export const toggleChartDetail = actionCreator(TOGGLE_CHART_DETAIL)

export const toggleTips = actionCreator(TOGGLE_TIPS)

export const toggleTip = typedActionCreator<TipLabel>(TOGGLE_TIP)

export const disableAllTips = actionCreator(DISABLE_ALL_TIPS)

export const enableAllTips = actionCreator(ENABLE_ALL_TIPS)

export const dismissTipToast = actionCreator(DISMISS_TIP_TOAST)


/* Error Reporting */

export const reportError = actionCreator(REPORT_ERROR)
