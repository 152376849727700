import { ReduxAction } from '@percept/types'
import { DashboardType, DerivedCompetitiveFilterPayload, FiltersList } from './reducers/filtersReducer'
import { CurrencyType } from 'components/ReportingDashboard/types'

export const APPLY_FILTERS = 'APPLY_FILTERS'

export const applyFilters = (payload: {
  list: FiltersList
  dashboardType: DashboardType
}): ReduxAction => ({
  type: APPLY_FILTERS,
  payload,
})

export const APPLY_UPDATED_FILTERS = 'APPLY_UPDATED_FILTERS'



export const APPLY_DERIVED_COMPETITIVE_FILTER = 'APPLY_DERIVED_COMPETITIVE_FILTER'

export const applyDerivedCompetitiveFilter = (payload: DerivedCompetitiveFilterPayload): ReduxAction => ({
  type: APPLY_DERIVED_COMPETITIVE_FILTER,
  payload,
})


export const SET_MEDIA_INVESTMENT_CURRENCY_TYPE = 'SET_MEDIA_INVESTMENT_CURRENCY_TYPE'

export const setMediaInvestmentCurrencyType = (payload: CurrencyType): ReduxAction => ({
  type: SET_MEDIA_INVESTMENT_CURRENCY_TYPE,
  payload,
})
